import React, { FC, useState, useEffect } from "react";

// Mui imports
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import RefreshIcon from "@mui/icons-material/Refresh";
import RestoreIcon from "@mui/icons-material/Restore";
import { Alert, Button, CircularProgress, Collapse, Divider, IconButton, LinearProgress, Stack } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowModel,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid";

// Component imports
import ConfirmationDialog, { ConfirmationDialogProps } from "../Dialogs/ConfirmationDialog/ConfirmationDialog";
import CreateUploadSourceDialog from "../Dialogs/NewEntryDialogs/CreateUploadSourceDialog";
import KnowledgeSourceEditDialog from "./KnowledgeSourceEditDialog";

// Data Imports
import { createFile, deleteFile } from "../../API/FileStorageInteraction";
import { KnowledgeSourceWebAppsConfig } from "../../authConfig";

// Context imports
import { KnowledgeSource, NotSyncedMsg, SynchronizeStatus } from "../../models/KnowledgeSource";

// Helper functions imports
import CustomToolTip from "../CustomToolTip";
import { dateTimeRepresentation, SyncError } from "./helpers";
import { checkLock, checkStatus, synchronize } from "../../API/SyncInteraction";
import {
  createKnowledgeSource,
  deleteKnowledgeSource,
  getKnowledgeSources,
  updateKnowledgeSource,
} from "../../API/KnowledgeSourcesInteraction";

// TODO: change this component to support uploading to staging first, production second (future)
// TODO: change knowledge sources to have a unique identifier

/**
 * KnowledgeSources Component
 * @returns A React component representing the KnowledgeSources to display
 */
const KnowledgeSources: FC = () => {
  // State
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isSynchronizationOngoing, setSynchronizationOngoing] = useState<boolean>(false);
  const [isAlertOpen, setAlertOpen] = useState<boolean>(false);

  // State variables for json file
  const [knowledgeSources, setKnowledgeSources] = useState<Array<KnowledgeSource>>([]);

  // Table manipulation
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "name", sort: "asc" }]);

  // Confirmation dialog states
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [confirmProps, setConfirmProps] = useState<ConfirmationDialogProps>();

  // Columns for the DataGrid component
  const columns: Array<GridColDef> = [
    {
      field: "name",
      headerName: "Kennisbron naam",
      type: "text",
      hideable: false,
      flex: 1,
      minWidth: 150,
    },

    {
      field: "status",
      headerName: "Status",
      width: 180,

      /** Set Icon based on synchronization status */
      renderCell: ({ row }: GridRowModel) => {
        switch (row.status) {
          case SynchronizeStatus.New:
            return <CustomToolTip title="Nieuw, Synchronisatie Vereist" child={<NoteAddIcon color="warning" />} />;
          case SynchronizeStatus.Edit:
            return <CustomToolTip title="Gewijzigd, Synchronisatie Vereist" child={<RefreshIcon color="warning" />} />;
          case SynchronizeStatus.Sync:
            return <CustomToolTip title="Synchronisatie Succesvol" child={<CheckCircleIcon color="success" />} />;
          case SynchronizeStatus.Fail:
            return <CustomToolTip title="Synchronisatie Mislukt" child={<ErrorIcon color="error" />} />;
          case SynchronizeStatus.Delete:
            return <CustomToolTip title="Verwijderd, Synchronisatie Vereist" child={<DeleteIcon color="warning" />} />;
        }
      },
    },
    {
      field: "lastModified",
      headerName: "Laatst gewijzigd",
      type: "text",
      width: 200,

      /** Set date as representable format */
      renderCell: ({ row }: GridRowModel) => dateTimeRepresentation(row.lastModified),
    },
    {
      field: "lastSyncRequest",
      headerName: "Laatste synchronisatie request",
      type: "text",
      width: 220,

      /** Set date as representable format */
      renderCell: ({ row }: GridRowModel) =>
        row.lastSyncRequest === NotSyncedMsg ? NotSyncedMsg : dateTimeRepresentation(row.lastSyncRequest),
    },
    {
      field: "actions",
      headerName: "Acties",
      type: "actions",
      hideable: false,
      width: 180,

      /** Set actions Open and Delete */
      getActions: ({ row }: GridRowModel) => {
        const editAction = (
          <KnowledgeSourceEditDialog
            source={row}
            allKnowledgeSources={knowledgeSources}
            handleEditSource={handleEditSource}
            uploadFiles={handleUploadFiles}
            deleteFile={handleDeleteFile}
          />
        );

        const deleteAction = (
          <GridActionsCellItem
            icon={<DeleteIcon color="error" />}
            label="Delete"
            onClick={() => {
              handleDeleteSource(row);
            }}
          />
        );

        const restoreAction = (
          <GridActionsCellItem
            icon={<RestoreIcon color={isSynchronizationOngoing ? "disabled" : "primary"} />}
            label="Restore"
            disabled={isSynchronizationOngoing}
            onClick={() => {
              handleRestoreSource(row);
            }}
          />
        );

        return row.status === SynchronizeStatus.Delete ? [restoreAction] : [editAction, deleteAction];
      },
    },
  ];

  /** On render and context changes, reload the data and check if synchronization is locked */
  useEffect(() => {
    setLoading(true);
    void checkSyncLock();

    retrieveKnowledgeSources();
  }, []);

  /** Check every 20 seconds if synchronization is in progress and whether sources status has changed */
  useEffect(() => {
    // Interval between calls in miliseconds - Environment variable is in seconds
    let intervalTime: number = 20000;
    if (KnowledgeSourceWebAppsConfig.knowledgeSourceWebAppCallTimeInterval !== undefined) {
      intervalTime = 1000 * parseInt(KnowledgeSourceWebAppsConfig.knowledgeSourceWebAppCallTimeInterval);
    }

    // TODO 5441: use server-side rendering instead of this live update disaster implementation!
    const interval = setInterval(() => {
      void checkSyncLock();
      checkStatus();
      retrieveKnowledgeSources();
    }, intervalTime);

    return () => {
      clearInterval(interval);
    };
  });

  /** Retrieves the knowledge sources from the context. */
  const retrieveKnowledgeSources = (): void => {
    void getKnowledgeSources().then((data) => {
      setKnowledgeSources(data);
      setLoading(false);
    });
  };

  /** Uploads the files using Backend API
   * @param files - the files to upload
   * @param folderId - the folder to upload them to
   */
  const handleUploadFiles = (files: Array<File>, folderId: string): void => {
    // Make the file names source-dependent
    const sourceFiles = files.map((file) => new File([file], `${folderId}/${file.name}`));

    const formData = new FormData();
    for (const file of sourceFiles) {
      // Parameter name matches input in backend!
      const files = "files";
      formData.append(files, file);
    }

    createFile(formData);
  };

  /** Remove file from storage
   * @param folderId - the folder to delete file from
   * @param filename - the filename to delete
   */
  const handleDeleteFile = (folderId: string, filename: string): void => {
    deleteFile(`${folderId}/${filename}`);
  };

  /** Handles synchronizing of all sources */
  const syncSources = async (): Promise<void> => {
    setSynchronizationOngoing(true);
    await synchronize().catch((error) => {
      if (error instanceof SyncError) {
        setSynchronizationOngoing(true);
        setAlertOpen(true);
      } else {
        throw error;
      }
    });
  };

  /** Handles checking if synchronization lock exists */
  const checkSyncLock = async (): Promise<void> => {
    await checkLock()
      .then(() => {
        setSynchronizationOngoing(false);
      })
      .catch((error) => {
        if (error instanceof SyncError) {
          setSynchronizationOngoing(true);
        } else {
          throw error;
        }
      });
  };

  /** Handle uploading a new source */
  const handleSourceUpload = (newSource: KnowledgeSource): void => {
    createKnowledgeSource(newSource);
    setKnowledgeSources([...knowledgeSources, newSource]);
  };

  /** Handle edit of a source */
  const handleEditSource = (editSource: KnowledgeSource): void => {
    const updatedKnowledgeSources = knowledgeSources.map((source) => {
      if (source.key === editSource.key) {
        editSource.lastModified = new Date().toISOString();
        if (source.status !== SynchronizeStatus.New) {
          editSource.status = SynchronizeStatus.Edit;
        }
        updateKnowledgeSource(editSource);

        return editSource;
      }

      return source;
    });

    setKnowledgeSources(updatedKnowledgeSources);
  };

  /** Handle deletion of a source */
  const handleDeleteSource = (deleteSource: KnowledgeSource): void => {
    /** Callback for the confirmation dialog.  */
    const confirmDeletion = (): void => {
      const updatedKnowledgeSources = knowledgeSources
        .map((source) => {
          if (source.key === deleteSource.key) {
            deleteSource.status === SynchronizeStatus.New
              ? deleteKnowledgeSource(deleteSource.key)
              : (deleteSource.status = SynchronizeStatus.Delete);
            updateKnowledgeSource(deleteSource);

            return deleteSource;
          }

          return source;
        })
        .filter((source) => !(source.key === deleteSource.key && source.status === SynchronizeStatus.New));

      setKnowledgeSources(updatedKnowledgeSources);
    };

    const confirmProps: ConfirmationDialogProps = {
      open: true,
      title: `Bevestig verwijdering van "${deleteSource.name}"`,
      button1Text: "Akkoord",
      button2Text: "Annuleer",
      description: `Weet je zeker dat je kennisbron "${deleteSource.name}" wilt verwijderen?`,

      /** Executable if confirmed */
      executable: () => {
        confirmDeletion();
      },

      /** Handle closing confirm dialog */
      handleClose: () => {
        setConfirmOpen(false);
      },
    };
    setConfirmProps(confirmProps);
    setConfirmOpen(true);
  };

  /** Handle restoring a source that is marked for permanent deletion */
  const handleRestoreSource = (restoreSource: KnowledgeSource): void => {
    const updatedKnowledgeSources = knowledgeSources.map((source) => {
      if (source.name === restoreSource.name) {
        // If modification happened last, set status to edit, else to whether last sync was successful
        if (new Date(restoreSource.lastSyncRequest) < new Date(restoreSource.lastModified)) {
          restoreSource.status = SynchronizeStatus.Edit;
        } else if (
          new Date(restoreSource.lastSyncRequest).getTime() === new Date(restoreSource.lastSyncSuccess).getTime()
        ) {
          restoreSource.status = SynchronizeStatus.Sync;
        } else {
          restoreSource.status = SynchronizeStatus.Fail;
        }
        updateKnowledgeSource(restoreSource);

        return restoreSource;
      }

      return source;
    });

    setKnowledgeSources(updatedKnowledgeSources);
  };

  return (
    <>
      {confirmProps != null ? <ConfirmationDialog {...confirmProps} open={confirmOpen} /> : null}
      <Stack width={"100%"} height={"100%"}>
        <Stack
          sx={{ width: "100%", height: "50px", marginBottom: "10px", marginRight: "10px" }}
          direction={"row"}
          display={"flex"}
          spacing={"5px"}
        >
          <CreateUploadSourceDialog
            allKnowledgeSources={knowledgeSources}
            handleSourceUpload={handleSourceUpload}
            uploadFiles={handleUploadFiles}
            disabled={isLoading}
          />
          <Divider orientation="vertical" />
          <Button
            variant="outlined"
            sx={{ height: 50 }}
            color={"success"}
            disabled={isSynchronizationOngoing || isLoading}
            endIcon={isSynchronizationOngoing ? <CircularProgress color="inherit" size={20} /> : null}
            style={{
              color: isSynchronizationOngoing ? "grey" : "",
              borderColor: isSynchronizationOngoing ? "grey" : "",
            }}
            onClick={() => {
              void syncSources();
            }}
          >
            {isSynchronizationOngoing ? "Bezig met synchroniseren" : "Synchroniseer kennisbronnen"}
          </Button>
          <Collapse in={isAlertOpen}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlertOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              severity="info"
              sx={{ height: 50 }}
            >
              Synchronisatie al actief, probeer later opnieuw!
            </Alert>
          </Collapse>
        </Stack>
        {/* Think we can use Custom Data grid here now but not Certain yet - Dirk */}
        <DataGrid
          rows={knowledgeSources}
          getRowId={(row: GridValidRowModel) => row.key}
          columns={columns}
          loading={isLoading}
          slots={{
            loadingOverlay: LinearProgress,

            /** Overlay when no rows are present */
            noRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Geen bronnen om weer te geven
              </Stack>
            ),
          }}
          keepNonExistentRowsSelected
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          sortModel={sortModel}
          onSortModelChange={(model: GridSortModel) => {
            setSortModel(model);
          }}
        />
      </Stack>
    </>
  );
};
export default KnowledgeSources;

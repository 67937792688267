import { BlockBlobClient, ContainerClient } from "@azure/storage-blob";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
// import { deepCopy } from "../helpers/deepCopy";

// Auth Imports
import { webApiConfig } from "./ApiConfiguration";
import { getToken } from "./Auth/Auth";

// Data Imports
// import { ChangeBucket, ChangeType, TrackedChange, changeBucketTemplateArray } from "../models/ChangeTracking";
// import { ApiCompatibleContainerNames } from "../models/models";

// Context Imports
// import { externalStorageContextAttributes } from "../contexts/ChangeTracking/ExternalStorageContext";
// import { stagingContextAttributes } from "../contexts/StagingContext";
// import { localStorageContextAttributes } from "../contexts/ChangeTracking/LocalStorageContext";
// import { productionContextAttributes } from "../contexts/ProductionContext";

export interface BlobContainerData {
  key: string;
  blobsInContainer: Array<BlobData>;
  containerClient: ContainerClient;
}

export interface BlobData {
  key: string;
  value: any;
  edited: boolean;
  blobClient: BlockBlobClient;
  lastModified?: string;
  location?: string;
  type?: string;
}

export interface ApiBlobResponse {
  key: string;
  blob: BlobFromApi;
}

export interface BlobFromApi {
  value: any | object | Array<any>;
}

export enum StorageEnvironment {
  Staging = 0,
  Live = 1,
}

// #region Basic API requests.

/**
 * Get all the containers from the backend.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @returns An array with the containers from the blob storage that can be put directly into the context.
 */
export const getContainers = async (
  storageEnvironment: StorageEnvironment,
  containerNames: Array<string>,
): Promise<Array<ApiBlobResponse>> => {
  // Instantiate an array to store the blob containers using our custom interface.
  const blobs: Array<ApiBlobResponse> = [];

  // For every container that we know, get its contents.
  for (const containerName of containerNames) {
    try {
      // Build the request url and config
      const url: string = `${webApiConfig.endpointUrl}/${containerName}`;
      const token = await getToken();
      const requestConfig: AxiosRequestConfig = {
        params: {
          environment: storageEnvironment,
        },
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
        },
        signal: new AbortController().signal,
      };

      // Perform the GET request.
      const response = await axios.get<BlobFromApi>(url, requestConfig);

      // store the fetched container.
      blobs.push({
        key: containerName.toLowerCase(),
        blob: { value: response.data satisfies Array<any> | object },
      } satisfies ApiBlobResponse);
    } catch (error) {
      // check whether the error is an axios error or a stock error.
      if (axios.isAxiosError(error)) {
        throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
      } else {
        throw new Error(`API call stock malfunction, reason: ${error}`);
      }
    }
  }

  return blobs;
};

/**
 * Get all the containers from the backend.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @returns An array with the containers from the blob storage that can be put directly into the context.
 */
export const getContainer = async (
  storageEnvironment: StorageEnvironment,
  containerName: string,
): Promise<ApiBlobResponse> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/${containerName}`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the GET request.
    const response = await axios.get<BlobFromApi>(url, requestConfig);

    // store the fetched container.
    return {
      key: containerName.toLowerCase(),
      blob: { value: response.data },
    } satisfies ApiBlobResponse;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Post Changes to the backend.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @returns An array with the containers from the blob storage that can be put directly into the context.
 */
export const postContainer = async (
  storageEnvironment: StorageEnvironment,
  transferObject: any,
  ApiContainerName: string,
): Promise<void> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/${ApiContainerName}`;
    const token = await getToken();
    const data = transferObject;
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };
    // Perform the POST request.
    await axios.post<object>(url, data, requestConfig);
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      // throw new Error(`API call axios malfunction full: ${JSON.stringify(error.toJSON())}`);
      throw new Error(`API call axios malfunction code: ${error.code}: ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Put's Changes to the backend.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @returns An array with the containers from the blob storage that can be put directly into the context.
 */
export const putContainer = async (
  storageEnvironment: StorageEnvironment,
  transferObject: any,
  APIContainerName: string,
): Promise<void> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/${APIContainerName}`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };
    // Perform the PUT request.
    await axios.put<object>(url, transferObject, requestConfig);
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code}\n: ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Get an item from a specified container that matches the item key supplied.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @param containerName - the containerName where the item will be put.
 * @param item - the item that will be put
 * @returns an axios response with the response data.
 */
export const getItem = async <T>(
  storageEnvironment: StorageEnvironment,
  containerName: string,
  itemKey: string,
): Promise<AxiosResponse> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/${containerName}/${itemKey}`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the GET request.
    const response = await axios.get<T>(url, requestConfig);

    return response;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code}\ncause: ${error.cause}\nstack: ${error.stack}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * create an item in a specified container that matches the item supplied.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @param containerName - the containerName where the item will be put.
 * @param item - the item that will be put
 * @returns an axios response with the response data.
 */
export const createItem = async <T>(
  storageEnvironment: StorageEnvironment,
  containerName: string,
  item: T,
): Promise<AxiosResponse> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/${containerName}`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the PUT request.
    const response = await axios.put<T>(url, item, requestConfig);

    return response;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code}\ncause: ${error.cause}\nstack: ${error.stack}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * delete an item in a specified container that matches the item key supplied.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @param containerName - the containerName where the item will be put.
 * @param item - the item that will be put
 * @returns an axios response with the response data.
 */
export const deleteItem = async <T>(
  storageEnvironment: StorageEnvironment,
  containerName: string,
  itemKey: string,
): Promise<AxiosResponse> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/${containerName}/${itemKey}`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the DELETE request.
    const response = await axios.delete<T>(url, requestConfig);

    return response;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code}\ncause: ${error.cause}\nstack: ${error.stack}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * update an item in a specified container that matches the item key supplied.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @param containerName - the containerName where the item will be put.
 * @param item - the item that will be put
 * @returns an axios response with the response data.
 */
export const updateItem = async <T>(
  storageEnvironment: StorageEnvironment,
  containerName: string,
  itemKey: string,
  item: any,
  putCall: boolean = false,
): Promise<AxiosResponse> => {
  try {
    // Build the request url and config
    const url: string = `${webApiConfig.endpointUrl}/${containerName}/${itemKey}`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the PATCH request.
    if (putCall) {
      const response = await axios.put<T>(url, item, requestConfig);

      return response;
    } else {
      const response = await axios.patch<T>(url, item, requestConfig);

      return response;
    }
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code}\n: ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

// #endregion

import React, { FC, Fragment, useEffect, useState } from "react";

// Component Imports
import { Box, Divider, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Stack } from "@mui/material";
import UtteranceListItem from "./UtteranceListItem";
import AddIcon from "@mui/icons-material/Add";
import UtteranceLoadingIndicator from "./UtteranceLoadingIndicator";
import EnterTextDialog from "../../../Dialogs/ConfirmationDialog/EnterTextDialog";

// Data Imports
import { NluUtterance } from "../../../../models/NLU";

// Context Imports
import { useNLUContext } from "../../../../contexts/NLU/NLUContext";

// CSS
import "./UtteranceList.css";

/**
 * Utterences View Component
 * @param intentSpecificUtterences - the list of utterences for the intent
 */
const UtteranceList: FC = () => {
  // Context
  const CLUContext = useNLUContext();
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [intentSpecificUtterances, setIntentSpecificUtterances] = useState<Array<NluUtterance> | undefined>([]);

  // When the selected intent changes, automatically match the correct utterances to display.
  useEffect(() => {
    if (CLUContext === null || CLUContext.selectedIntent === null) return;
    setIntentSpecificUtterances(CLUContext.getIntentSpecificUtterances!(CLUContext.selectedIntent.name));
  }, [CLUContext?.selectedIntent, CLUContext?.selectedProject]);

  /**
   * handle the open state of the add dialog.
   */
  const handleOpenAddDialog = (): void => {
    setOpenAddDialog(true);
  };

  /**
   * handle the close state of the add dialog.
   */
  const handleCloseAddDialog = (): void => {
    setOpenAddDialog(false);
  };

  /**
   * handle the adding of an utterance
   */
  const handleAddUtterance = (utterance: string): void => {
    if (CLUContext === null) return;
    if (CLUContext.selectedIntent === null) return;

    void CLUContext.createOrUpdateUtterance!(utterance, CLUContext.selectedIntent.name);
  };

  if (CLUContext === null) return null;
  if (intentSpecificUtterances === undefined || CLUContext.selectedIntent === undefined) {
    return (
      <>
        <UtteranceLoadingIndicator />
      </>
    );
  } else {
    return (
      <>
        <EnterTextDialog
          isOpen={openAddDialog}
          title="Nieuwe utterance toevoegen"
          description="Geef een zin op die de chatbot moet herkennen, die bij de intent past"
          handleClose={handleCloseAddDialog}
          executable={(value: string) => {
            handleAddUtterance(value);
          }}
          button1Text="Toevoegen"
          button2Text="Annuleren"
          placeholder="Nieuwe utterance"
        />
        <Stack direction="column" spacing={2} width={"100%"} height={"100%"}>
          <Box key={`utterance-display`} width={"100%"} height={"100%"}>
            <List
              className="UtteranceList"
              key={`utterance-list`}
              sx={{ width: "100%", height: "100%", overflow: "auto" }}
              subheader={
                <ListSubheader key={`add-utterance`}>
                  <ListItemButton onClick={handleOpenAddDialog}>
                    <ListItemIcon>
                      <AddIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText>Voorbeeldzin Toevoegen</ListItemText>
                  </ListItemButton>
                  <Divider />
                </ListSubheader>
              }
            >
              {intentSpecificUtterances.map((utterance, index) => (
                <Fragment key={`fragment-${index}`}>
                  <UtteranceListItem key={`item-${index}`} utterance={utterance} index={index} />
                  {index + 1 <= intentSpecificUtterances.length - 1 ? (
                    <Divider key={`divider-${index}`} variant={"middle"} />
                  ) : null}
                </Fragment>
              ))}
            </List>
          </Box>
        </Stack>
      </>
    );
  }
};

export default UtteranceList;

import { KnowledgeSource } from "../models/KnowledgeSource";
import { createItem, deleteItem, getContainer, StorageEnvironment, updateItem } from "./StorageInteraction";

const KnowledgeSourcesDataKey = "KnowledgeSources";

/** Retrieves the knowledge sources from the context. */
export const getKnowledgeSources = async (): Promise<Array<KnowledgeSource>> =>
  await getContainer(StorageEnvironment.Live, KnowledgeSourcesDataKey)
    .then((data) => data.blob.value)
    .catch((error: Error) => {
      throw new Error(`Failed to retrieve data: ${error}`);
    });

/** Updates the local and external data */
export const createKnowledgeSource = (newKnowledgeSources: KnowledgeSource): void => {
  void createItem(StorageEnvironment.Live, KnowledgeSourcesDataKey, newKnowledgeSources).catch((error: Error) => {
    throw new Error(`Failed to create source: ${error}`);
  });
};

/** Updates the external data */
export const updateKnowledgeSource = (updatedKnowledgeSource: KnowledgeSource): void => {
  void updateItem(
    StorageEnvironment.Live,
    KnowledgeSourcesDataKey,
    updatedKnowledgeSource.key,
    updatedKnowledgeSource,
  ).catch((error: Error) => {
    throw new Error(`Failed to update source: ${error}`);
  });
};

/** Updates the local and external data */
export const deleteKnowledgeSource = (key: string): void => {
  void deleteItem(StorageEnvironment.Live, KnowledgeSourcesDataKey, key).catch((error: Error) => {
    throw new Error(`Failed to delete source: ${error}`);
  });
};

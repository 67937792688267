import React, { useState, useEffect, FC } from "react";
import { v4 as uuidv4 } from "uuid";

// Component Imports
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Stack, Tab, TextField, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

// Component Imports
import ConfirmationDialog, { ConfirmationDialogProps } from "../../Dialogs/ConfirmationDialog/ConfirmationDialog";
import SkeletonLoader from "../../SkeletonLoader/SkeletonLoader";
import CreateNewDialog from "../../Dialogs/NewEntryDialogs/DialogCreator/CreateNewDialog";
import VisualisationDialog from "./VisualisationDialog";

// Data Imports
import { TopDeskApiData, loadTopDeskData } from "../../../API/TopDeskInteraction";
import { BlobFromApi, ApiBlobResponse, StorageEnvironment, putContainer } from "../../../API/StorageInteraction";
import { Dialog } from "../../../models/Dialogs";
import { ChangeType, TrackedChange } from "../../../models/ChangeTracking";
import moment from "moment";

// Context Imports
import { useStagingContext } from "../../../contexts/StagingContext";
import { useLocalStorageContext } from "../../../contexts/ChangeTracking/LocalStorageContext";
import { useNLUContext } from "../../../contexts/NLU/NLUContext";
import { deepCopy } from "../../../helpers/deepCopy";
import { StepTypes } from "./StepTypes";
import { useExternalStorageContext } from "../../../contexts/ChangeTracking/ExternalStorageContext";

// Style
interface DialogEditorProps {
  containerName: string;
}

/**
 * DialogEditor Component
 * @param containerName - The name of the container to edit.
 * @returns A React component representing the DialogEditor to display and edit the dialogs.
 */
const DialogEditor: FC<DialogEditorProps> = ({ containerName }: DialogEditorProps) => {
  // Context
  const stagingContext = useStagingContext();
  const localStorageContext = useLocalStorageContext();
  const externalStorageContext = useExternalStorageContext();
  const CLUContext = useNLUContext();

  // State
  const [blobsData, setBlobsData] = useState<BlobFromApi>();
  const [currentDialog, setCurrentDialog] = useState<string>("");
  const [confirmProps, setConfirmProps] = useState<ConfirmationDialogProps>();
  const [topDeskApiData, setTopDeskApiData] = useState<TopDeskApiData>();
  const [isTopDeskDataLoaded, setIsTopDeskDataLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    try {
      if (stagingContext === null) return;
      const container = stagingContext.containers.find((blob) => blob.key === containerName);

      if (container !== undefined) {
        setBlobsData(deepCopy(container.blob));
      }
    } catch (error) {
      throw new Error(`(Dialog Editor) Error fetching data: ${error}`);
    }
    setIsLoading(false);
  }, [stagingContext, containerName]);

  useEffect(() => {
    if (blobsData === undefined) return;

    // Select the first dialog in the list while there is no currently selected dialog,
    // set the current dialog in the list.
    if (blobsData.value?.length > 0 && currentDialog === "") {
      setCurrentDialog(blobsData.value[0].key);
    }
  }, [blobsData]);

  /**
   * Handles the update of the dialog.
   * @param update - The updated dialog.
   * @returns void
   */
  const onUpdate = (update: Dialog): void => {
    if (stagingContext === null || blobsData === undefined) return;

    // Map the existing dialogs, but replace the matching one with the update.
    const dialogs = [...blobsData.value];
    const updatedDialogs = dialogs.map((dialog) => {
      if (dialog.key === update.key) {
        return update;
      } else {
        return dialog;
      }
    });

    const updatedContainer: ApiBlobResponse = {
      key: containerName,
      blob: { value: updatedDialogs },
    };

    stagingContext.updateContextWithContainer!(updatedContainer);
  };

  /**
   * Creates a new dialog.
   * @returns void
   */
  const createNewDialog = async (dialog: Dialog): Promise<void> => {
    if (stagingContext === null || blobsData === undefined) return;

    // Create a new dialog.
    const newDialog: Dialog = {
      key: uuidv4(),
      name: dialog.name,
      intent: dialog.intent,
      versions: [
        {
          version: "0.1",
          active: true,
          steps: [
            {
              id: "start",
              name: "start",
              type: StepTypes.Start,
              nextStep: "",
              options: null,
            },
            {
              id: "finish",
              name: "finish",
              type: StepTypes.Finish,
              options: null,
            },
          ],
        },
      ],
    };

    // Construct the update data
    const updatedBlobsData = [...blobsData.value];
    updatedBlobsData.push(newDialog);

    await putContainer(StorageEnvironment.Staging, newDialog, containerName);

    // Construct a sendable blob.
    const blob: ApiBlobResponse = {
      key: containerName,
      blob: { value: updatedBlobsData },
    };

    // Update blobsData first
    setBlobsData(blob.blob.value);

    // Now you can safely set currentDialog
    setCurrentDialog(newDialog.key);

    stagingContext.updateContextWithContainer!({
      key: containerName,
      blob: { value: updatedBlobsData },
    });

    // Create a new tracked change for the new dialog
    if (externalStorageContext !== null) {
      // Add a sort order based on the item's keys.
      const keys: Array<string> = [];
      for (const key of Object.keys(newDialog.key)) {
        keys.push(key);
      }

      const newTrackedChange: TrackedChange = {
        key: `change-${containerName}-${newDialog.key}`,
        origin: {
          containerName,
          fileName: `${newDialog.key}.json`,
        },
        newValue: deepCopy(newDialog),
        changeType: ChangeType.New,
        lastModified: moment().toDate(),
        live: false,
        displayOrder: keys,
      };

      externalStorageContext.trackChange!(newTrackedChange);
      // const changeBucketToPersist: Array<ChangeBucket> = externalStorageContext.changeBuckets.filter(
      //   (bucket) => bucket.key === containerName,
      // )!;

      // externalStorageContext.persistChanges!(changeBucketToPersist, true).catch((error) => {
      //   throw new Error(`Custom Datagrid Item Add error: ${error}`);
      // });
    }
  };

  /**
   * Opens the delete dialog window.
   * @param dialogName - The name of the dialog to delete.
   * @returns void
   */
  const openDeleteDialogWindow = (dialog: Dialog): void => {
    const confirmProps: ConfirmationDialogProps = {
      open: true,
      handleClose: handleConfirmClose,
      title: `'${dialog.name}' verwijderen`,
      button1Text: "Verwijderen",
      button2Text: "Annuleren",
      description: `Weet je zeker dat je "${dialog.name}" wilt verwijderen?`,

      /**
       * Deletes the dialog.
       */
      executable: () => {
        deleteDialog(dialog);
      },
    };

    setConfirmProps(confirmProps);
  };

  /**
   * Closes the delete dialog window.
   * @returns void
   */
  const handleConfirmClose = (): void => {
    const confirmProps: ConfirmationDialogProps = {
      open: false,

      /**
       * Closes the delete dialog window.
       */
      handleClose: () => {},
      title: "",
      button1Text: "",
      button2Text: "",
      description: "",

      /**
       * Deletes the dialog.
       */
      executable: () => {},
    };

    setConfirmProps(confirmProps);
  };

  /**
   * Deletes the dialog.
   * @param dialogName - The name of the dialog to delete.
   * @returns void
   */
  const deleteDialog = (dialog: Dialog): void => {
    if (stagingContext === null || blobsData === undefined) return;

    // Find the dialog to delete
    const update = [...blobsData.value];
    const blobToDelete = update.find((x) => x.key === dialog.key)!;
    const updatedBlobsData = update.filter((x) => x.key !== dialog.key);
    const thisBlob = update.find((x) => x.key === currentDialog);
    const currIntent = CLUContext?.selectedProject?.assets.intents.find((intent) => intent.name === thisBlob?.intent);

    if (currIntent !== undefined) {
      CLUContext?.updateUsedIntents!(currIntent, true);
    }

    setBlobsData({ ...blobsData, value: update });

    // Create a new tracked change
    if (localStorageContext !== null) {
      // Add a sort order based on the item's keys.
      const keys: Array<string> = [];
      for (const key of Object.keys(blobToDelete)) {
        keys.push(key);
      }

      const newTrackedChange: TrackedChange = {
        key: `change-${containerName}-${blobToDelete.key}`,
        origin: {
          containerName,
          fileName: blobToDelete.key,
        },
        oldValue: deepCopy(blobToDelete),
        newValue: null,
        lastModified: moment().toDate(),
        changeType: ChangeType.Delete,
        live: false,
        displayOrder: keys,
      };

      // If the change exists, a newly made item is deleted,
      // otherwise a previously existing item is deleted.
      if (localStorageContext.trackedChangeExists!(newTrackedChange)) {
        localStorageContext.untrackChange!(newTrackedChange);
      } else {
        localStorageContext.trackChange!(newTrackedChange);
      }
    }

    stagingContext.updateContextWithContainer!({
      key: containerName,
      blob: { value: updatedBlobsData },
    });

    handleConfirmClose();

    setCurrentDialog(updatedBlobsData[0].key);
  };

  /**
   * Open rename dialog
   * @param rename - The dialog to rename
   * @returns void
   */
  const openRenameDialogWindow = (rename: Dialog): void => {
    if (blobsData === undefined) return;

    const dialog = blobsData.value.find((dialog: Dialog) => dialog.key === rename.key);
    const confirmProps: ConfirmationDialogProps = {
      open: true,
      handleClose: handleConfirmClose,
      title: `'${dialog.name}' wijzigen van naam`,
      button1Text: "Wijzigen",
      button2Text: "Annuleren",
      description: `Weet je zeker dat je "${dialog.name}" wilt wijzigen?`,
      bodyContent: (
        <TextField
          onChange={(e) => {
            dialog.name = e.target.value;
          }}
        />
      ),

      /**
       * Renames the dialog.
       */
      executable: () => {
        blobsData.value[blobsData.value.findIndex((dialog: Dialog) => dialog.key === rename.key)] = dialog;
        onUpdate(blobsData.value);
      },
    };

    setConfirmProps(confirmProps);
  };

  /**
   * Loads the TOPdesk data.
   * @returns Promise<boolean>
   */
  const loadTopDeskApiData = async (): Promise<boolean> => {
    await loadTopDeskData()
      .catch((error) => {
        throw new Error(`Error loading TOPdesk data: ${error}`);
      })
      .then((data) => {
        if (data == null) return false;

        setTopDeskApiData(data);
        setIsTopDeskDataLoaded(true);

        return true;
      });

    return false;
  };

  /**
   * This function handles the change of the tab.
   */
  const handleTabChange = (event: React.SyntheticEvent, newValue: string): void => {
    setCurrentDialog(newValue);
  };

  if (isLoading) {
    return <SkeletonLoader />;
  }

  if (blobsData === undefined || blobsData.value === undefined) {
    return <></>;
  } else {
    const hasDialogs: boolean = blobsData.value.some((dialog: Dialog) => dialog.versions !== undefined);

    return (
      <>
        {hasDialogs && currentDialog !== "" ? (
          <TabContext value={currentDialog}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange} sx={{ height: "55px" }}>
                {blobsData.value.map((dialog: Dialog) => {
                  if (dialog.versions !== undefined) {
                    return (
                      <Tab
                        disableRipple
                        key={`${dialog.key}_tab`}
                        value={dialog.key}
                        label={dialog.name}
                        icon={
                          <>
                            <EditIcon
                              color="primary"
                              onClick={() => {
                                openRenameDialogWindow(dialog);
                              }}
                            />
                            <DeleteIcon
                              color="error"
                              onClick={() => {
                                openDeleteDialogWindow(dialog);
                              }}
                            />
                          </>
                        }
                        iconPosition="end"
                      />
                    );
                  }

                  return null;
                })}
                <CreateNewDialog executable={createNewDialog} />
              </TabList>
            </Box>
            {blobsData.value.map((dialog: Dialog) => {
              if (dialog.versions !== undefined) {
                return (
                  <TabPanel value={dialog.key} key={`${dialog.key}_tabpanel`}>
                    <VisualisationDialog
                      dialog={dialog}
                      onUpdate={onUpdate}
                      selectedDialog={dialog.name}
                      topDeskApiData={topDeskApiData}
                      isTopDeskDataLoaded={isTopDeskDataLoaded}
                      loadTopDeskApiData={loadTopDeskApiData}
                      containerName={containerName}
                    />
                  </TabPanel>
                );
              }

              return null;
            })}
            <TabPanel value={"New_Dialog"} />
          </TabContext>
        ) : (
          <Stack alignItems={"center"}>
            <Typography variant="body1">Er zijn geen dialogen. Maak er nu een met de plus hieronder.</Typography>
            <CreateNewDialog executable={createNewDialog} />
          </Stack>
        )}
        {confirmProps != null ? <ConfirmationDialog {...confirmProps} /> : null}
      </>
    );
  }
};

export default DialogEditor;

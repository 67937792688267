import { topdeskConfig } from "./ApiConfiguration";
import { TopDeskItem, TopDeskSubcategory } from "../models/ItsmModels";

/**
 * Fetches the TOPdesk group incidents
 * @param token - The TOPdesk authentication token.
 * @returns The TOPdesk group incidents.
 */
export const getTopdeskGroupIncidents = async (): Promise<Array<any | never>> => {
  const incidentHeaders = new Headers();
  incidentHeaders.append("Authorization", topdeskConfig.authorization);
  incidentHeaders.append("Ocp-Apim-Subscription-Key", topdeskConfig.subscriptionKey);

  const incidentOptions = {
    method: "GET",
    headers: incidentHeaders,
  };
  try {
    const response = await fetch(
      `${topdeskConfig.baseUrl}/incidents?query=majorCall==true;closed!=true`,
      incidentOptions,
    );

    if (response.status !== 200) {
      if (response.status === 204) {
        return [];
      }

      throw new Error(`Request failed: ${response.status} - ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error(error);

    return [];
  }
};

export type TopDeskApiData = {
  callTypes: Array<TopDeskItem>;
  statuses: Array<TopDeskItem>;
  categories: Array<TopDeskItem>;
  entryTypes: Array<TopDeskItem>;
  impacts: Array<TopDeskItem>;
  priorities: Array<TopDeskItem>;
  subcategories: Array<TopDeskSubcategory>;
  urgencies: Array<TopDeskItem>;
};

/**
 * Loads the TOPdesk data
 * @returns The TOPdesk data.
 */
export const loadTopDeskData = async (): Promise<TopDeskApiData> => {
  const callTypes: Array<TopDeskItem> = await fetchTopDeskIncidentCallTypes();
  const statuses: Array<TopDeskItem> = await fetchTopDeskIncidentStatuses();
  const categories: Array<TopDeskItem> = await fetchTopDeskIncidentCategories();
  const entryTypes: Array<TopDeskItem> = await fetchTopDeskIncidentEntryTypes();
  const impacts: Array<TopDeskItem> = await fetchTopDeskIncidentImpacts();
  const priorities: Array<TopDeskItem> = await fetchTopDeskIncidentPriorities();
  const subcategories: Array<TopDeskSubcategory> = await fetchTopDeskIncidentSubcategories();
  const urgencies: Array<TopDeskItem> = await fetchTopDeskIncidentUrgencies();

  // Create placeholder objects and add them to the top of the lists
  const placeHolder = { id: "-1", name: "Selecteer een optie" };
  const placeHolderSubCategory = { id: "-1", name: "Selecteer eerst een categorie", category: placeHolder };

  callTypes.unshift(placeHolder);
  statuses.unshift(placeHolder);
  categories.unshift(placeHolder);
  entryTypes.unshift(placeHolder);
  impacts.unshift(placeHolder);
  priorities.unshift(placeHolder);
  subcategories.unshift(placeHolderSubCategory);
  urgencies.unshift(placeHolder);

  return {
    callTypes,
    statuses,
    categories,
    entryTypes,
    impacts,
    priorities,
    subcategories,
    urgencies,
  };
};

/**
 * Fetches the TOPdesk incident categories
 * @param token - The TOPdesk authentication token.
 * @returns The TOPdesk incident categories.
 */
const fetchTopDeskIncidentCategories = async (): Promise<Array<TopDeskItem>> =>
  await fetchTopDeskJsonArray(`${topdeskConfig.baseUrl}/incidents/categories`);

/**
 * Fetches the TOPdesk incident subcategories
 * @param token - The TOPdesk authentication token.
 */
const fetchTopDeskIncidentSubcategories = async (): Promise<Array<TopDeskSubcategory>> =>
  await fetchTopDeskJsonArray(`${topdeskConfig.baseUrl}/incidents/subcategories`);

/**
 * Fetches the TOPdesk call types
 * @param token - The TOPdesk authentication token.
 * @returns The TOPdesk call types.
 */
const fetchTopDeskIncidentCallTypes = async (): Promise<Array<TopDeskItem>> =>
  await fetchTopDeskJsonArray(`${topdeskConfig.baseUrl}/incidents/call_types`);

/**
 * Fetches the TOPdesk entry types
 * @param token - The TOPdesk authentication token.
 * @returns The TOPdesk entry types.
 */
const fetchTopDeskIncidentEntryTypes = async (): Promise<Array<TopDeskItem>> =>
  await fetchTopDeskJsonArray(`${topdeskConfig.baseUrl}/incidents/entry_types`);

/**
 * Fetches the TOPdesk impact types
 * @param token - The TOPdesk authentication token.
 */
const fetchTopDeskIncidentImpacts = async (): Promise<Array<TopDeskItem>> =>
  await fetchTopDeskJsonArray(`${topdeskConfig.baseUrl}/incidents/impacts`);

/**
 * Fetches the TOPdesk priority types
 * @param token - The TOPdesk authentication token.
 */
const fetchTopDeskIncidentPriorities = async (): Promise<Array<TopDeskItem>> =>
  await fetchTopDeskJsonArray(`${topdeskConfig.baseUrl}/incidents/priorities`);

/**
 * Fetches the TOPdesk status types
 * @param token - The TOPdesk authentication token.
 * @returns The TOPdesk status types.
 */
const fetchTopDeskIncidentStatuses = async (): Promise<Array<TopDeskItem>> =>
  await fetchTopDeskJsonArray(`${topdeskConfig.baseUrl}/incidents/statuses`);

/**
 * Fetches the TOPdesk urgency types
 * @param token - The TOPdesk authentication token.
 * @returns The TOPdesk urgency types.
 */
const fetchTopDeskIncidentUrgencies = async (): Promise<Array<TopDeskItem>> =>
  await fetchTopDeskJsonArray(`${topdeskConfig.baseUrl}/incidents/urgencies`);

/**
 * Fetches objects from TOPdesk and returns the JSON array
 * @param url - The URL to fetch the objects from.
 * @param token - The TOPdesk authentication token.
 * @returns The JSON array.
 */
const fetchTopDeskJsonArray = async (url: string): Promise<Array<any>> => {
  const options = {
    method: "GET" as string,
    headers: generateTopDeskHeaders(),
  };

  const response: Response = await fetch(url, options);

  if (response.status !== 200) {
    throw new Error(`Request failed: ${response.status} - ${response.statusText}`);
  }

  return await response.json();
};

/**
 * Generates the TOPdesk headers
 * @param token - The TOPdesk authentication token.
 * @returns The TOPdesk headers.
 */
const generateTopDeskHeaders = (): Headers => {
  const headers = new Headers();
  headers.append("Authorization", topdeskConfig.authorization);
  headers.append("Ocp-Apim-Subscription-Key", topdeskConfig.subscriptionKey);

  return headers;
};

import React, { FC, useEffect, useState } from "react";

// MUI Imports
import IntentGroupList from "./Intents/IntentGroupList";
import UtteranceList from "./Utterances/UtteranceList";
import { Box, Divider, Grid, Skeleton, Stack } from "@mui/material";

// Context Imports
import { useNLUContext } from "../../../contexts/NLU/NLUContext";

import styles from "./QuestionEditor.module.scss";

/**
 * Question Editor Component
 */
const QuestionEditor: FC = () => {
  // Context
  const CLUContext = useNLUContext();

  const [errorText, setErrorText] = useState<string>("");

  /** Check every 20 seconds if synchronization is in progress and whether sources status has changed */
  useEffect(() => {
    // Interval between calls in miliseconds - Environment variable is in seconds
    const intervalTime: number = 20000;

    const interval = setInterval(() => {
      if (CLUContext?.selectedProject === null) {
        setErrorText("Het duurt wat langer om het initiële project aan te maken en te trainen.");
      } else {
        setErrorText("");
      }
    }, intervalTime);

    return () => {
      clearInterval(interval);
    };
  });

  if (CLUContext === null || CLUContext.selectedProject === null) {
    return (
      <Grid container spacing={2}>
        {errorText !== "" ? <p className={styles.ErrorText}>{errorText}</p> : null}
        <Grid xs={12} item>
          <Box component="h1">
            <Skeleton width="100%" height="100%"></Skeleton>
          </Box>
        </Grid>
        <Grid xs={4} item>
          <Skeleton width="100%" height="37px"></Skeleton>
        </Grid>
        <Grid xs={8} item>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Skeleton width="100%"></Skeleton>
            </Grid>
            <Grid item>
              <Skeleton width="100%"></Skeleton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      width={"100%"}
      divider={<Divider orientation="vertical" variant="middle" flexItem />}
      sx={{ height: "100%", overflow: "hidden" }}
    >
      <Box sx={{ minWidth: "450px" }}>
        <IntentGroupList />
      </Box>
      <Box width={"100%"}>{CLUContext.selectedIntent !== null && <UtteranceList />}</Box>
    </Stack>
  );
};

export default QuestionEditor;

import React, { FC, useEffect, useRef, useState } from "react";
import { deepCopy } from "../../../helpers/deepCopy";

// Data Imports
import { BlobFromApi } from "../../../API/StorageInteraction";
import { Language, languages, LanguageText } from "../../../models/Language";
import { Answer } from "../../../models/Answers";
import { Highlight } from "../../../models/Highlights";
import { ChangeOrigin, ChangeType, TrackedChange } from "../../../models/ChangeTracking";
import { getEditorColumns } from "../../../models/columnDefinitions";
import { ContainerNames } from "../../../models/enums";

// Component Imports
import CustomDataGrid from "../../CustomDataGrid/CustomDataGrid";
import AddNewLanguageDialog from "./AddNewLanguageDialog";
import { Box, CircularProgress } from "@mui/material";
import SkeletonLoader from "../../SkeletonLoader/SkeletonLoader";
import { GridColDef, GridRowModel } from "@mui/x-data-grid";

// Context Imports
import { useStagingContext } from "../../../contexts/StagingContext";
import { useProductionContext } from "../../../contexts/ProductionContext";
import { useLocalStorageContext } from "../../../contexts/ChangeTracking/LocalStorageContext";
import moment from "moment";

/**
 * MultiEditorProps interface
 * @param containerName - The name of the container to use.
 * @param isDeletable - Whether the editor rows should be deletable.
 * @param isRevertable - Whether the editor rows should be revertable.
 */
interface ModularEditorProps {
  containerName: ContainerNames;
  isDeletable?: boolean;
  isRevertable?: boolean;
}

/**
 * GroupIncidentEditor Component
 * @returns A GroupIncidentEditor component
 */
const ModularEditor: FC<ModularEditorProps> = ({ containerName, isDeletable, isRevertable }) => {
  // Context
  const stagingContext = useStagingContext();
  const productionContext = useProductionContext();
  const localStorageContext = useLocalStorageContext();

  // State
  const columns = useRef<Array<GridColDef>>([]);
  const [stagingData, setStagingData] = useState<BlobFromApi>();
  const [productionBlobData, setProductionBlobData] = useState<BlobFromApi>();
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogLanguage, setDialogLanguage] = useState("");
  const [currentKey, setCurrentKey] = useState("");
  const [dialogValue, setDialogValue] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogLanguages, setDialogLanguages] = useState<Array<Language>>([]);
  const [dialogIsEdit, setDialogIsEdit] = useState(false);

  // Used to only set the column definitions once, otherwise the columns will be duplicated on every render
  const isInitialRender = useRef(true);

  // TODO: Change this to dynamic language based on the main language when we add support for multiple languages
  const mainLanguage: Language = languages.find(
    (lang: Language) => lang.key === process.env.REACT_APP_GLOBAL_LANGUAGE,
  )!;

  useEffect(() => {
    if (!isInitialRender.current) return;

    setIsLoading(true);

    switch (containerName) {
      case ContainerNames.GroupIncidents:
        columns.current = getEditorColumns(
          mainLanguage,
          languages,
          setIsDialogOpen,
          setCurrentKey,
          setDialogLanguage,
          setDialogValue,
          setDialogIsEdit,
          setDialogTitle,
          setDialogLanguages,
          ContainerNames.GroupIncidents,
        );
        break;
      case ContainerNames.Answers:
        columns.current = getEditorColumns(
          mainLanguage,
          languages,
          setIsDialogOpen,
          setCurrentKey,
          setDialogLanguage,
          setDialogValue,
          setDialogIsEdit,
          setDialogTitle,
          setDialogLanguages,
          ContainerNames.Answers,
        );
        break;
      case ContainerNames.Highlights:
        columns.current = getEditorColumns(
          mainLanguage,
          languages,
          setIsDialogOpen,
          setCurrentKey,
          setDialogLanguage,
          setDialogValue,
          setDialogIsEdit,
          setDialogTitle,
          setDialogLanguages,
          ContainerNames.Highlights,
        );
        break;
      default:
        columns.current = [];
        break;
    }

    isInitialRender.current = false;
  }, [containerName]);

  useEffect(() => {
    /**
     * Fetches the data from the context and sets the state
     */
    const fetchData = (): void => {
      try {
        if (isLoading && stagingContext !== null && productionContext !== null) {
          let stagingData: BlobFromApi | undefined;
          let productionData: BlobFromApi | undefined;

          if (stagingContext.containers.length > 0) {
            const container = stagingContext.containers.find((blob) => blob.key === containerName);

            if (container !== undefined) {
              stagingData = deepCopy(container.blob) satisfies BlobFromApi;
            }
          }

          if (productionContext.containers.length > 0) {
            const container = productionContext.containers.find((blob) => blob.key === containerName);

            if (container !== undefined) {
              productionData = deepCopy(container.blob) satisfies BlobFromApi;
            }
          }

          if (stagingData !== undefined && productionData !== undefined) {
            setStagingData(stagingData);
            setProductionBlobData(productionData);
            setIsLoading(false);
          }
        }
      } catch (error) {
        throw new Error(`Error fetching data: ${error}`);
      }
    };

    fetchData();
  }, [stagingContext, productionContext, isLoading, containerName]);

  useEffect(() => {
    isInitialRender.current = true;
  }, [containerName]);

  /**
   * Editor update function that creates a dispatch action with the correct information
   * @param update - the update generated by the CustomDataGrid component that will be
   * put into the context
   * @returns void
   */
  const onUpdate = (update: BlobFromApi): void => {
    if (stagingContext !== null) {
      stagingContext.updateContextWithContainer?.({
        key: containerName,
        blob: update,
      });
    }

    setStagingData(update);
  };

  /**
   * Adds a language to the incident
   * @param languageText - The language text to add to the incident
   * @returns void
   */
  const addLanguage = (languageText: LanguageText): void => {
    if (stagingData !== undefined && localStorageContext !== null) {
      // Create a new updatedBlob by copying stagingData
      const updatedBlob: BlobFromApi = { ...stagingData };

      // Find the key in the list of entries
      const oldEntry = updatedBlob.value.find((entry: any) => entry.key === currentKey);
      if (oldEntry !== undefined) {
        // Create a new currentEntry object to avoid mutating oldEntry
        const currentEntry = { ...oldEntry };

        // Ensure languages is a new array
        const languages = [...currentEntry.languages];

        // Update or add the language in the array immutably
        const languageExists = languages.some((language: LanguageText) => language.key === languageText.key);
        const updatedLanguages = languageExists
          ? languages.map((language: LanguageText) =>
              language.key === languageText.key ? { ...language, value: languageText.value } : language,
            )
          : [...languages, languageText];

        // Update the current entry's languages array
        currentEntry.languages = updatedLanguages;

        // Update the current entry in the updatedBlob's value array immutably
        const entryIndex: number = updatedBlob.value.findIndex((entry: any) => entry.key === currentKey);
        updatedBlob.value = [
          ...updatedBlob.value.slice(0, entryIndex),
          currentEntry,
          ...updatedBlob.value.slice(entryIndex + 1),
        ];

        // Track this new change
        if (localStorageContext !== null) {
          // Add a sort order based on the item's keys
          const keys: Array<string> = Object.keys(currentEntry);

          // Retrieve existing change or create a new change if it doesn't exist
          const changeKey: string = `change-${containerName}-${oldEntry.key}`;
          const changeOrigin: ChangeOrigin = {
            containerName,
            fileName: `${containerName}.json`,
          };

          const trackedChangeResult = localStorageContext.getChangeByIdInOrigin!(changeKey, changeOrigin);
          if (trackedChangeResult === undefined) {
            // Build a new Change
            const newTrackedChange: TrackedChange = {
              key: changeKey,
              origin: changeOrigin,
              oldValue: oldEntry,
              newValue: currentEntry,
              lastModified: moment().toDate(),
              changeType: ChangeType.Update,
              live: false,
              displayOrder: keys,
            };

            localStorageContext.trackChange!(newTrackedChange);
          } else {
            // Modify the change parameters
            const updateTrackedChange = {
              ...trackedChangeResult,
              newValue: currentEntry,
              lastModified: moment().toDate(),
              displayOrder: keys,
            };

            localStorageContext.updateChange!(updateTrackedChange);
          }
        }

        // Call onUpdate with the new updatedBlob
        onUpdate({ ...updatedBlob });
      }
    }
  };

  /**
   * Removes a language from the entry
   * @param languageKey - The language key to remove from the incident
   * @returns void
   */
  const removeLanguage = (languageKey: string): void => {
    if (stagingData !== undefined && localStorageContext !== null) {
      // Find the key in the list of texts, remove the language and update the context
      const updatedBlob: BlobFromApi = { ...stagingData };
      const entryList = updatedBlob?.value as Array<any>;

      const entryIndex = entryList.findIndex((incident: { key: string }) => incident.key === currentKey);
      const entry = entryList[entryIndex];
      const oldEntry = entry;
      const languages = entry?.languages ?? [];

      entry.languages = languages.filter((lang: { key: string }) => lang.key !== languageKey);
      entryList[entryIndex] = entry;
      updatedBlob.value = entryList;

      // Track this new change
      if (localStorageContext !== null) {
        // Add a sort order based on the item's keys.
        const keys: Array<string> = [];
        for (const key of Object.keys(entry)) {
          keys.push(key);
        }

        // Since we are updating, retrieve existing change.
        const changeKey: string = `change-${containerName}-${oldEntry.key}`;
        const changeOrigin: ChangeOrigin = {
          containerName,
          fileName: `${containerName}.json`,
        };

        // Retrieve a previous update for this item and modify it, or create a new change if did not exist.
        const trackedChangeResult = localStorageContext.getChangeByIdInOrigin!(changeKey, changeOrigin);
        if (trackedChangeResult === undefined) {
          // Build a new Change
          const newTrackedChange: TrackedChange = {
            key: changeKey,
            origin: changeOrigin,
            oldValue: oldEntry,
            newValue: entry,
            lastModified: moment().toDate(),
            changeType: ChangeType.Update,
            live: false,
            displayOrder: keys,
          };

          localStorageContext.trackChange!(newTrackedChange);
        } else {
          // Modify the change parameters.
          const updateTrackedChange = {
            ...trackedChangeResult,
            newValue: entry,
            lastModified: moment().toDate(),
            displayOrder: keys,
          };

          localStorageContext.updateChange!(updateTrackedChange);
        }
      }

      onUpdate(updatedBlob);
    }
  };

  /**
   * Updates the value of a language in the current dataset
   * @param updatedRow - The language text to update
   * @returns Updated GroupIncident
   */
  const customUpdateHandler = (updatedRow: GridRowModel): GridRowModel => {
    const updatedEntry: GridRowModel = { ...updatedRow };

    if (updatedRow.value === undefined || updatedRow.value === "" || updatedRow.value === null) {
      // If the value is empty, remove the language from the entry
      updatedEntry.languages = updatedEntry.languages.filter(
        (language: LanguageText) => language.key !== mainLanguage.key,
      );
    } else {
      // Replace the main language value with the updated value, or create it if it doesn't exist
      const languageIndex = updatedEntry.languages.findIndex(
        (language: LanguageText) => language.key === mainLanguage.key,
      );

      if (languageIndex !== -1) {
        updatedEntry.languages[languageIndex].value = updatedRow.value;
      } else {
        updatedEntry.languages.push({ key: mainLanguage.key, value: updatedRow.value });
      }
    }

    return updatedEntry;
  };

  /**
   * Creates a new row in the correct format
   * @param newRow - The new item to create
   * @returns New item in the correct format
   */
  const customCreateRowHandler = (newRow: GridRowModel): GridRowModel => {
    if (containerName === ContainerNames.Highlights) {
      const newHighlight: Highlight = {
        key: newRow.key,
        languages: [
          {
            key: mainLanguage.key,
            value: newRow.value,
          },
        ],
        timeStampFrom: newRow.timeStampFrom,
        timeStampTo: newRow.timeStampTo,
        repeat: newRow.repeat,
        timeSpan: newRow.timeSpan,
        enabled: newRow.enabled,
      };

      return newHighlight;
    }

    const newAnswer: Answer = {
      key: newRow.key,
      languages: newRow.languages,
      type: newRow.type,
    };

    return newAnswer;
  };

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <>
      {stagingData !== undefined && productionBlobData !== undefined && (
        <CustomDataGrid
          onUpdate={onUpdate}
          blobData={{ ...stagingData }}
          productionBlobData={{ ...productionBlobData }}
          columns={columns.current}
          containerName={containerName}
          language={mainLanguage.key}
          isDeletable={isDeletable}
          isRevertable={isRevertable}
          customUpdateHandler={customUpdateHandler}
          customCreateRowHandler={customCreateRowHandler}
        />
      )}
      {stagingData === undefined && productionBlobData === undefined && (
        <Box sx={{ display: "flex" }} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
      <AddNewLanguageDialog
        isOpen={isDialogOpen}
        handleClose={() => {
          setIsDialogOpen(false);
        }}
        languages={dialogLanguages}
        addLanguage={(languageText: LanguageText) => {
          addLanguage(languageText);
        }}
        removeLanguage={(languageKey: string) => {
          removeLanguage(languageKey);
        }}
        dialogLanguage={dialogLanguage}
        dialogValue={dialogValue}
        title={dialogTitle}
        isEdit={dialogIsEdit}
      />
    </>
  );
};

export default ModularEditor;

import { LogLevel, Configuration, RedirectRequest } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID!,
    authority: `${process.env.REACT_APP_AUTHORITY_URL}/${process.env.REACT_APP_IDENTITY_PROVIDER_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: process.env.REACT_APP_CACHE_LOCATION,
    storeAuthStateInCookie: process.env.REACT_APP_STORE_AUTH_STATE_IN_COOKIE as boolean | undefined,
  },
  system: {
    loggerOptions: {
      /**
       * Logger function
       */
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }

        switch (level) {
          case LogLevel.Error:
            console.error(message);

            return;
          case LogLevel.Info:
            // Uncomment this to show info level messages in console again
            // console.info(message);

            return;
          case LogLevel.Verbose:
            console.debug(message);

            return;
          case LogLevel.Warning:
            console.warn(message);
        }
      },
    },
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: [process.env.REACT_APP_LOGIN_SCOPE!],
};

export const LUISCredentials = {
  authoringKey: process.env.REACT_APP_LUIS_AUTHORING_KEY,
  authoringEndpoint: process.env.REACT_APP_LUIS_AUTHORING_ENDPOINT,
  predictionEndpoint: process.env.REACT_APP_LUIS_PREDICTION_ENDPOINT,
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: process.env.REACT_APP_GRAPHME_ENDPOINT as RequestInfo | URL,
};

export const KnowledgeSourceWebAppsConfig = {
  knowledgeSourceWebAppCallTimeInterval: process.env.REACT_APP_KNOWLEDGE_SOURCE_WEBAPP_CALL_TIME_INTERVAL,
  knowledgeSourceDocumentSizeLimit: process.env.REACT_APP_KNOWLEDGE_SOURCE_DOCUMENT_SIZE_LIMIT,
};

export const CLUCredentials = {
  endpointUrl: process.env.REACT_APP_CLU_ENDPOINTURL,
  authoringKey: process.env.REACT_APP_CLU_AUTHORINGKEY,
  modelName: process.env.REACT_APP_CLU_MODELNAME,
  deploymentName: process.env.REACT_APP_CLU_DEPLOYMENTNAME,
  endpointVersion: process.env.REACT_APP_CLU_ENDPOINTVERSION,
};

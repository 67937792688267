import React, { FC, useReducer, ReactNode, useContext, createContext, Dispatch, useEffect } from "react";

// Blob Imports
import { ApiCompatibleContainerNames } from "../models/models";
import { getContainers, StorageEnvironment, ApiBlobResponse } from "../API/StorageInteraction";

// #region production context

// Builds the context that provides the list of containers
const ProductionContainerContext = createContext<productionContextAttributes | null>(null);

// Builds the context that provides the function that lets components dispatch actions
const ProductionContainerDispatchContext = createContext<Dispatch<any> | null>(null);

// Initial production state provided to the reducer in the provider
const initialProductionContext: productionContextAttributes = {
  containers: [],
};

export enum ActionType {
  "set",
  "updateSingle",
  "updateAll",
}

interface productionContextProviderProps {
  children: ReactNode | Array<ReactNode> | null;
}

export type productionContextAttributes = {
  containers: Array<ApiBlobResponse>;
  setContainers?: () => Promise<void>;
  updateContextWithContainer?: (update: ApiBlobResponse) => void;
};

/**
 * Provides the containers context to the application
 * @param  children - The children of the provider
 * @returns  A provider element
 */
export const ProductionContextProvider: FC<productionContextProviderProps> = ({ children }) => {
  const [context, dispatch] = useReducer(productionReducer, initialProductionContext);

  useEffect(() => {
    void setContainers().catch((error) => {
      throw new Error(`Fetching live data failed: ${error}`);
    });
  }, []);

  /**
   * Set the staging containers
   * @param  containers - The containers to be set for the staging context
   */
  const setContainers = async (): Promise<void> => {
    try {
      const containers = await getContainers(StorageEnvironment.Live, ApiCompatibleContainerNames);

      const action: productionReducerAction = {
        type: ActionType.set,
        payload: containers,
      };

      dispatch(action);
    } catch (error) {
      throw new Error(`Error fetching data: ${error}`);
    }
  };

  /**
   * Context update function that creates a dispatch action with the correct information
   * @param update - Generated by a component to be put into context
   */
  const updateContextWithContainer = (update: ApiBlobResponse): void => {
    // Build a dispatch action
    const action: productionReducerAction = {
      type: ActionType.updateSingle,
      payload: update,
    };

    dispatch(action);
  };

  const _context: productionContextAttributes = {
    containers: context.containers,
    setContainers,
    updateContextWithContainer,
  };

  return (
    <ProductionContainerContext.Provider value={_context}>
      <ProductionContainerDispatchContext.Provider value={dispatch}>
        {children}
      </ProductionContainerDispatchContext.Provider>
    </ProductionContainerContext.Provider>
  );
};

/**
 * Provides the container context
 * @returns A context
 */
export const useProductionContext = (): productionContextAttributes | null => useContext(ProductionContainerContext);

/**
 * Provides the function that lets components dispatch actions
 * @returns A dispatch context
 */
export const useProductionDispatch = (): Dispatch<any> | null => useContext(ProductionContainerDispatchContext);

// #endregion

// #region production reducer

export type productionReducerAction = {
  type: ActionType;
  payload?: Array<ApiBlobResponse> | ApiBlobResponse;
};

/**
 *
 * @param context - The state to be updated
 * @param action - The action to be executed by the reducer
 * @returns Updated state
 */
export const productionReducer = (
  context: productionContextAttributes,
  action: productionReducerAction,
): productionContextAttributes => {
  switch (action.type) {
    case ActionType.set: {
      const newContainers = action.payload as Array<ApiBlobResponse>;

      return { ...context, containers: newContainers } satisfies productionContextAttributes;
    }
    case ActionType.updateSingle: {
      const newContainer = action.payload as ApiBlobResponse;
      const updatedContainers: Array<ApiBlobResponse> = context.containers.map((container: ApiBlobResponse) => {
        if (container.key === newContainer.key) {
          return newContainer;
        } else {
          return container;
        }
      });

      return { ...context, containers: updatedContainers } satisfies productionContextAttributes;
    }
    case ActionType.updateAll: {
      const updateContainers = action.payload as Array<ApiBlobResponse>;

      return { ...context, containers: updateContainers } satisfies productionContextAttributes;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

// #endregion

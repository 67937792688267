import React, { FC } from "react";

// Component Imports
import {
  Badge,
  Box,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
} from "@mui/material";
import { ConfirmationDialogProps } from "../../../../Dialogs/ConfirmationDialog/ConfirmationDialog";
import { useDrag } from "react-dnd";
import DeleteIcon from "@mui/icons-material/Delete";

// Data Imports
import { NluIntent } from "../../../../../models/NLU";
import { IntentGroup } from "../../../../../models/IntentGroups";

// Context Imports
import { useNLUContext } from "../../../../../contexts/NLU/NLUContext";
import { useIntentGroupContext } from "../../../../../contexts/NLU/IntentGroupContext";

interface IntentListItemProps {
  count: number;
  parentGroup: IntentGroup;
  intent: NluIntent;
  handleCloseConfirmationDialog: () => void;
  setConfirmationDialogProps: (confirmationDialogProps: ConfirmationDialogProps) => void;
}

/**
 * A list of intents.
 * @param IntentListProps - The properties that this component requires to function.
 */
const IntentListItem: FC<IntentListItemProps> = ({
  count,
  parentGroup,
  intent,
  handleCloseConfirmationDialog,
  setConfirmationDialogProps,
}: IntentListItemProps) => {
  // Contexts
  const CLUContext = useNLUContext();
  const IntentGroupContext = useIntentGroupContext();

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: "LISTITEM",
      item: { intent },

      /**
       * @param item - The item.
       * @param monitor - The monitor.
       */
      end(item: any, monitor: any) {
        if (IntentGroupContext === null) return;
        const newGroup = monitor.getDropResult();

        if (newGroup !== null && newGroup.id !== parentGroup) {
          setConfirmationDialogProps({
            /**
             * @returns void
             */
            executable: () => {
              void IntentGroupContext.moveIntentToGroup!(parentGroup.key, newGroup.key, intent.name);
            },
            title: "Slepen intent",
            button1Text: "Bevestigen",
            button2Text: "Annuleren",
            description: `Bevestig slepen van intent "${intent.name}" naar groep "${newGroup.name}`,
            open: true,

            /**
             * @returns void
             */
            handleClose: () => {
              handleCloseConfirmationDialog();
            },
          });
        }
      },

      /**
       * @param monitor - The monitor.
       */
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [IntentGroupContext?.intentGroups],
  );

  /**
   * handle the deletion of an intent
   */
  const handleDelete = (intent: NluIntent): void => {
    setConfirmationDialogProps({
      /**
       * @returns void
       */
      executable: () => {
        if (CLUContext !== null && IntentGroupContext !== null) {
          void CLUContext.removeIntent!(intent.name);
          void IntentGroupContext.removeIntentFromGroup!(parentGroup.name, intent.name);
        }
      },
      title: `Intent Verwijderen`,
      button1Text: "Bevestigen",
      button2Text: "Annuleren",
      description: `Weet je zeker dat je intent "${intent.name}" wilt verwijderen?`,
      open: true,

      /**
       * @returns void
       */
      handleClose: () => {
        handleCloseConfirmationDialog();
      },
    });
  };

  /**
   * handle the opening of an intent.
   */
  const handleEdit = (intent: NluIntent): void => {
    if (CLUContext !== null) {
      // void CLUContext.setIntentSpecificUtterences!(intent.category, true);
      CLUContext.setSelectedIntent!(intent);
    }
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }} ref={dragPreview}>
      <ListItemButton
        key={`intent-${intent.name}`}
        sx={{
          width: "100%",
          opacity: isDragging ? 0.5 : 1,
          backgroundColor: CLUContext?.selectedIntent === intent ? "rgba(0, 100, 255, 0.25)" : "white",
        }}
        onClick={() => {
          handleEdit(intent);
        }}
        ref={drag}
      >
        <ListItemAvatar>
          <Badge
            badgeContent={count}
            color={count > 5 && count < 31 ? "success" : count <= 5 ? "warning" : "secondary"}
            sx={{ marginLeft: "10px" }}
            showZero
          />
        </ListItemAvatar>
        <ListItemText>{intent.name}</ListItemText>

        <ListItemSecondaryAction>
          <Stack direction="row" spacing={0.5}>
            {CLUContext?.selectedIntent?.name === intent.name && (
              <IconButton
                size="small"
                onClick={() => {
                  handleDelete(intent);
                }}
              >
                <DeleteIcon color="primary" />
              </IconButton>
            )}
          </Stack>
        </ListItemSecondaryAction>
      </ListItemButton>
    </Box>
  );
};

export default IntentListItem;

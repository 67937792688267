import React, { FC } from "react";

// Route Imports
import { Route, Routes } from "react-router-dom";

// Page Imports
import DialogEditor from "../../components/Editors/DialogEditor/DialogEditor";
import QuestionEditor from "../../components/Editors/Questions/QuestionEditor";
import KnowledgeSources from "../KnowledgeSources/KnowledgeSources";
import ModularEditor from "../Editors/ModularEditor/ModularEditor";
import { ContainerNames } from "../../models/enums";
import FaqComponent from "../Faq/FaqComponent";

/**
 * Routing Component
 * @returns React component that handles navigation to the different pages and components
 */
const Routing: FC = () => {
  const NLUFeatureFlag: boolean = process.env.REACT_APP_ENABLE_NLU_QUESTION_ANSWERING === "true";
  const GroupIncidentsFeatureFlag: boolean = process.env.REACT_APP_ENABLE_GROUP_INCIDENTS === "true";

  return (
    <Routes>
      {/* <Route path="/" element={<Profile />} />
    <Route path="/QuestionEditor" element={<QuestionEditor />} /> */}
      {NLUFeatureFlag ? (
        <>
          <Route path="/" element={<QuestionEditor />} />
          <Route path="/DialogEditor" element={<DialogEditor containerName={ContainerNames.Dialogs} />} />
        </>
      ) : null}
      <Route
        path="/MessageEditor"
        element={<ModularEditor containerName={ContainerNames.Answers} isDeletable={true} isRevertable={true} />}
      />
      <Route
        path="/HighlightEditor"
        element={<ModularEditor containerName={ContainerNames.Highlights} isDeletable={true} isRevertable={true} />}
      />
      {GroupIncidentsFeatureFlag ? (
        <Route
          path="/GroupIncidentEditor"
          element={
            <ModularEditor containerName={ContainerNames.GroupIncidents} isDeletable={false} isRevertable={true} />
          }
        />
      ) : null}

      <Route path="/Faq" element={<FaqComponent />} />

      <Route path="/KnowledgeSources" element={<KnowledgeSources />} />
    </Routes>
  );
};

export default Routing;

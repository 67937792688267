import React, { ReactNode } from "react";

// Component Imports
import { Divider, Stack } from "@mui/material";
import AccordionContentHighlight from "./AccordionContentHighlight";
import AccordionContentAnswer from "./AccordionContentAnswer";
import AccordionContentDialog from "./AccordionContentDialog";

// Data Imports
import { ChangeOrigin, TrackedChange } from "../../../models/ChangeTracking";
import { ContainerNames } from "../../../models/enums";
import { CheckItemDifference } from "../../../helpers/ObjectDifferences";

/**
 * Initialize the factory that builds the accordion content.
 * @param change - The change that will be turned into displayable accordion content.
 */
export const AccordionContentFactoryStarter = (change: TrackedChange): ReactNode => {
  // Get the differences
  let differences = {};
  if (![null, undefined].includes(change.oldValue) && ![null, undefined].includes(change.newValue)) {
    differences = CheckItemDifference(change.oldValue, change.newValue);
  }

  return (
    <Stack
      width={1000}
      direction="row"
      spacing={0.5}
      divider={<Divider orientation="vertical" flexItem />}
      justifyContent="space-evenly"
    >
      {![null, undefined].includes(change.oldValue) ? AccordionContentFactory(change.oldValue, change.origin) : null}
      {![null, undefined].includes(change.newValue)
        ? AccordionContentFactory(change.newValue, change.origin, differences)
        : null}
    </Stack>
  );
};

/**
 * Builds the information for a single change.
 * @param record - The change whose information must be displayed.
 * @param order - the order in which the changes must be displayed, gained from a change's displayOrder property.
 * @param differences - An object representing the differences between the old change and new change
 * @returns An array of react nodes.
 */
const AccordionContentFactory = (
  record: Record<string, any>,
  origin: ChangeOrigin,
  differences?: Record<string, boolean | Record<string, boolean>>,
): ReactNode => {
  if (origin.containerName === ContainerNames.Answers) {
    return <AccordionContentAnswer record={record} differences={differences} />;
  }
  if (origin.containerName === ContainerNames.Dialogs) {
    return <AccordionContentDialog record={record} differences={differences} />;
  }
  if (origin.containerName === ContainerNames.GroupIncidents) {
    return null;
  }
  if (origin.containerName === ContainerNames.Highlights) {
    return <AccordionContentHighlight record={record} differences={differences} />;
  }
};

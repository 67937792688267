import React, { FC, useEffect, useState } from "react";

// MUI Imports
import { Divider, IconButton, Stack, TextField, Typography } from "@mui/material";

// Component Imports
import ITSMTicketInformation from "./ITSMTicketInformation";
import ShowAskWithButtonsOptions from "./ShowAskWithButtonsOptions";
import ShowIfStatementsOptions from "./ShowIfStatementsOptions";
import ShowOptions from "./ShowOptions";
import ShowOpenAIMessageOption from "./ShowOpenAIMessageOption";
import ShowRedirectOptions from "./ShowRedirectOptions";
import { NodeSelection } from "../VisualisationDialog";
import { StepTypes, StepTexts } from "../StepTypes";
import { TopDeskApiData } from "../../../../API/TopDeskInteraction";
import { ShowOptionsComponentType } from "../../../../models/enums";
import ErrorBoundary from "../../../ErrorBoundary/ErrorBoundary";
import { ChevronRight } from "@mui/icons-material";
import { DialogStep } from "../../../../models/Dialogs";

interface DetailsWindowProps {
  selectedNodeData: NodeSelection;
  setSelectedNodeData: React.Dispatch<React.SetStateAction<NodeSelection>>;
  topDeskApiData: TopDeskApiData | undefined;
  isTopDeskDataLoaded: boolean;
  loadTopDeskApiData: () => Promise<boolean>;
  containerName: string;
  selectedDialog: string;
}

/**
 * DetailsWindow Component
 * @param selectedNodeData - The selected node data.
 * @param setSelectedNodeData - The function to set the selected node data.
 * @param topDeskApiData - The topdesk api data.
 * @param isTopDeskDataLoaded - The boolean to check if the topdesk data is loaded.
 * @param loadTopDeskApiData - The function to load the topdesk api data.
 * @returns A DetailsWindow component.
 */
export const DetailsWindow: FC<DetailsWindowProps> = ({
  selectedNodeData,
  setSelectedNodeData,
  topDeskApiData,
  isTopDeskDataLoaded,
  loadTopDeskApiData,
  selectedDialog,
  containerName,
}) => {
  const [type, setType] = useState<string>();
  const [title, setTitle] = useState<string>();

  useEffect(() => {
    setType(selectedNodeData?.newNodeData?.type);

    switch (selectedNodeData?.newNodeData?.type) {
      case StepTypes.AskWithButtons: {
        setTitle(StepTexts.AskWithButtons);
        break;
      }
      case StepTypes.Conditional: {
        setTitle(StepTexts.Conditional);
        break;
      }
      case StepTypes.CreateTicketDialog: {
        setTitle(StepTexts.CreateTicket);
        break;
      }
      case StepTypes.Message: {
        setTitle(StepTexts.Message);
        break;
      }
      case StepTypes.OpenAI: {
        setTitle(StepTexts.OpenAI);
        break;
      }
      case StepTypes.Redirect: {
        setTitle(StepTexts.Redirect);
        break;
      }
      case StepTypes.TextPrompt: {
        setTitle(StepTexts.TextPrompt);
        break;
      }
      case StepTypes.YesNoDialog: {
        setTitle(StepTexts.YesNoDialog);
        break;
      }
    }
  }, [selectedNodeData.newNodeData.type]);

  /**
   * Handles the change of the name of the node.
   * @param event - The event that triggered the change.
   * @returns void
   */
  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const currentSelectedNode: NodeSelection | null = selectedNodeData !== null ? { ...selectedNodeData } : null;
    const updateNodeData: DialogStep | null =
      currentSelectedNode !== null ? { ...currentSelectedNode.newNodeData } : null;

    if (updateNodeData !== null) {
      updateNodeData.name = event.target.value;

      const newNodeSelection: NodeSelection = {
        oldNodeData: selectedNodeData?.oldNodeData,
        newNodeData: updateNodeData,
        changed: true,
      };

      setSelectedNodeData(newNodeSelection);
    }
  };

  /**
   * Closes drawer
   */
  const closeDrawer = (): void => {
    setSelectedNodeData({
      oldNodeData: {
        id: "",
        name: "",
        nextStep: "",
        options: undefined,
        type: "",
      },
      newNodeData: {
        id: "",
        name: "",
        nextStep: "",
        options: undefined,
        type: "",
      },
      changed: true,
    });
  };

  return (
    <Stack>
      <Stack direction={"row"} padding={2} justifyContent={"space-between"} alignItems={"center"}>
        <Typography variant="h5">{title}</Typography>
        <IconButton
          onClick={() => {
            closeDrawer();
          }}
        >
          <ChevronRight />
        </IconButton>
      </Stack>
      <Divider />
      <ErrorBoundary>
        <Stack padding={2} gap={2}>
          <TextField
            id="input-name"
            label="Naam"
            value={selectedNodeData?.newNodeData?.name}
            onChange={onNameChange}
            fullWidth={true}
          />
          {type === StepTypes.AskWithButtons ? (
            <ShowAskWithButtonsOptions selectedNodeData={selectedNodeData} setSelectedNodeData={setSelectedNodeData} />
          ) : null}
          {type === StepTypes.Conditional ? (
            <ShowIfStatementsOptions selectedNodeData={selectedNodeData} setSelectedNodeData={setSelectedNodeData} />
          ) : null}
          {type === StepTypes.Message ? (
            <ShowOptions
              componentType={ShowOptionsComponentType.Message}
              selectedNodeData={selectedNodeData}
              setSelectedNodeData={setSelectedNodeData}
            />
          ) : null}
          {type === "question" || type === StepTypes.YesNoDialog || type === StepTypes.TextPrompt ? (
            <ShowOptions
              componentType={ShowOptionsComponentType.Question}
              selectedNodeData={selectedNodeData}
              setSelectedNodeData={setSelectedNodeData}
            />
          ) : null}
          {type === StepTypes.CreateTicketDialog ? (
            <ITSMTicketInformation
              selectedNodeData={selectedNodeData}
              setSelectedNodeData={setSelectedNodeData}
              topDeskApiData={topDeskApiData}
              isTopDeskDataLoaded={isTopDeskDataLoaded}
              loadTopDeskApiData={loadTopDeskApiData}
            />
          ) : null}
          {type === StepTypes.Redirect ? (
            <ShowRedirectOptions
              selectedNodeData={selectedNodeData}
              setSelectednodeData={setSelectedNodeData}
              containerName={containerName}
              selectedDialog={selectedDialog}
            />
          ) : null}
          {type === StepTypes.OpenAI ? (
            <ShowOpenAIMessageOption selectedNodeData={selectedNodeData} setSelectedNodeData={setSelectedNodeData} />
          ) : null}
        </Stack>
      </ErrorBoundary>
    </Stack>
  );
};

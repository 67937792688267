// Authorization Imports
import { SilentRequest } from "@azure/msal-browser";

// #region WebApi section
export type WebApiConfig = {
  endpointUrl?: string;
  subscriptionKey?: string;
};

export const webApiConfig: WebApiConfig = {
  endpointUrl: process.env.REACT_APP_WEBAPI_ENDPOINT as string,
  subscriptionKey: process.env.REACT_APP_WEBAPI_GATEWAY_SUBSCRIPTION_KEY as string,
};

export const WebApiTokenRequest: SilentRequest = {
  scopes: [process.env.REACT_APP_WEBAPI_READ_SCOPE!],
};
// #endregion

// #region Topdesk section
export type TopdeskConfig = {
  authorization?: string;
  subscriptionKey?: string;
  baseUrl?: string;
};

export const topdeskConfig = {
  authorization: process.env.REACT_APP_TOPDESK_AUTHORIZATION as string,
  subscriptionKey: process.env.REACT_APP_TOPDESK_SUBSCRIPTION_KEY as string,
  baseUrl: process.env.REACT_APP_TOPDESK_BASEURL as string,
};
// #endregion

// #region NLU section
// DOES NOT CURRENTLY NEED A CONFIG
// #endregion

import React, { FC, useEffect, useState } from "react";
import ConfirmationDialog, { ConfirmationDialogProps } from "../Dialogs/ConfirmationDialog/ConfirmationDialog";
import { Stack } from "@mui/system";
import { LinearProgress } from "@mui/material";
import { FaqItem } from "../../models/Faqs";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowModel,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid";

// Mui imports
import DeleteIcon from "@mui/icons-material/Delete";
import { addFaqItem, deleteFaqItem, getFaqItems } from "../../API/FaqInteraction";
import CreateFaqDialog from "./CreateFaqDialog";

/**
 * FAQ Component
 * @returns A React component representing the FAQ
 */
const FAQComponent: FC = () => {
  // State
  const [isLoading, setLoading] = useState<boolean>(true);

  // State variables for json file
  const [Faq, setFaq] = useState<Array<FaqItem>>([]);

  // Table manipulation
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "name", sort: "asc" }]);

  // Confirmation dialog states
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [confirmProps, setConfirmProps] = useState<ConfirmationDialogProps>();

  // Columns for the DataGrid component
  const columns: Array<GridColDef> = [
    {
      field: "question",
      headerName: "Vraag",
      type: "text",
      hideable: false,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "answer",
      headerName: "Antwoord",
      type: "test",
      hideable: false,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "actions",
      headerName: "Acties",
      type: "actions",
      hideable: false,
      width: 150,

      /** Set actions Open and Delete */
      getActions: ({ row }: GridRowModel) => {
        const deleteAction = (
          <GridActionsCellItem
            icon={<DeleteIcon color="error" />}
            label="Delete"
            onClick={() => {
              handleDeleteFaq(row.question);
            }}
          />
        );

        return [deleteAction];
      },
    },
  ];

  /** On render and context changes, reload the data and check if synchronization is locked */
  useEffect(() => {
    setLoading(true);
    retrieveFaq();
  }, []);

  /** Retrieves the Faq data */
  const retrieveFaq = (): void => {
    void getFaqItems().then((data) => {
      setFaq(data);
      setLoading(false);
    });
  };

  /** Handle uploading a new Faq */
  const handleFaqUpload = (newFaq: FaqItem): void => {
    setFaq([...Faq, newFaq]);
    addFaqItem(newFaq);
  };

  /** Handle deletion of an Faq */
  const handleDeleteFaq = (question: string): void => {
    /** Callback for the confirmation dialog.  */
    const confirmDeletion = (): void => {
      const updatedFaq: Array<FaqItem> = Faq.filter((faq) => faq.question !== question);
      setFaq(updatedFaq);
      deleteFaqItem(question);
    };

    const confirmProps: ConfirmationDialogProps = {
      open: true,
      title: `Bevestig verwijdering van "${question}"`,
      button1Text: "Akkoord",
      button2Text: "Annuleer",
      description: `Weet je zeker dat je FAQ "${question}" wilt verwijderen?`,

      /** Executable if confirmed */
      executable: () => {
        confirmDeletion();
      },

      /** Handle closing confirm dialog */
      handleClose: () => {
        setConfirmOpen(false);
      },
    };
    setConfirmProps(confirmProps);
    setConfirmOpen(true);
  };

  return (
    <>
      {confirmProps != null ? <ConfirmationDialog {...confirmProps} open={confirmOpen} /> : null}
      <Stack width={"100%"} height={"100%"}>
        <Stack
          sx={{ width: "100%", height: "50px", marginBottom: "10px", marginRight: "10px" }}
          direction={"row"}
          display={"flex"}
          spacing={"5px"}
        >
          <CreateFaqDialog handleFaqUpload={handleFaqUpload} allFaq={Faq} disabled={isLoading} />
        </Stack>

        <DataGrid
          rows={Faq}
          getRowId={(row: GridValidRowModel) => row.question}
          columns={columns}
          columnVisibilityModel={{
            recursive: false,
            url: false,
          }}
          loading={isLoading}
          slots={{
            loadingOverlay: LinearProgress,

            /** Overlay when no rows are present */
            noRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                Geen FAQ om weer te geven
              </Stack>
            ),
          }}
          keepNonExistentRowsSelected
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          sortModel={sortModel}
          onSortModelChange={(model: GridSortModel) => {
            setSortModel(model);
          }}
        />
      </Stack>
    </>
  );
};

export default FAQComponent;

import React, { FC, useCallback, useEffect, useState } from "react";

// Component imports
import CustomSelect from "./CustomSelect";
import TopDeskTicketInformation from "./TopDeskTicketInformation";
import SalesforceCaseInformation from "./SalesforceCaseInformation";
import ShowOptions from "./ShowOptions";
import { NodeSelection } from "../VisualisationDialog";
import { DialogStep } from "../../../../models/Dialogs";

// Helper and Model imports
import { TopDeskApiData } from "../../../../API/TopDeskInteraction";
import {
  ITSMPlatform,
  SalesforceInformation,
  TOPDeskInformation,
  ITSMDescriptionQuesion,
  ITSMPlatformTexts,
} from "../../../../models/ItsmModels";
import { ShowOptionsComponentType } from "../../../../models/enums";

// Library Imports
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";

// MUI Imports
import { Button, Divider, Grid, IconButton, Stack } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

interface ITSMTicketInformationProps {
  selectedNodeData: NodeSelection;
  setSelectedNodeData: React.Dispatch<React.SetStateAction<NodeSelection>>;
  topDeskApiData: TopDeskApiData | undefined;
  isTopDeskDataLoaded: boolean;
  loadTopDeskApiData: () => Promise<boolean>;
}

/**
 * Checks if the provided object is of type TOPDeskInformation.
 * @param obj - The object to be checked.
 * @returns True if the object is of type TOPDeskInformation, false otherwise.
 */
function isTOPDeskInformation(obj: TOPDeskInformation | SalesforceInformation): obj is TOPDeskInformation {
  return typeof obj === "object" && obj !== null;
}

/**
 * Checks if the provided object is of type SalesforceInformation.
 * @param obj - The object to be checked.
 * @returns  True if the object is of type SalesforceInformation, false otherwise.
 */
function isSalesforceInformation(obj: TOPDeskInformation | SalesforceInformation): obj is TOPDeskInformation {
  return typeof obj === "object" && obj !== null;
}

/**
 * ITSMTicketInformation Component
 * This component allows users to display and edit ticket information for different ITSM platforms.
 * @param selectedNodeData - The selected node data.
 * @param setSelectedNodeData - The function to set the selected node data.
 * @param topDeskApiData - The TopDeskApiData.
 * @param isTopDeskDataLoaded - Whether the TopDeskApiData is loaded.
 * @param loadTopDeskApiData - The function to load the TopDeskApiData.
 * @returns  A React component representing the ITSMTicketInformation to display and edit ticket information.
 */
const ITSMTicketInformation: FC<ITSMTicketInformationProps> = ({
  selectedNodeData,
  setSelectedNodeData,
  topDeskApiData,
  isTopDeskDataLoaded,
  loadTopDeskApiData,
}: ITSMTicketInformationProps) => {
  const [newNodeData, setNewNodeData] = useState<DialogStep | null>(selectedNodeData?.newNodeData ?? null);
  const [selectedPlatform, setPlatform] = useState<ITSMPlatform>(
    selectedNodeData?.newNodeData.options.itsmService ?? ITSMPlatform.TOPdesk,
  );

  useEffect(() => {
    setNewNodeData(selectedNodeData?.newNodeData ?? null);

    setPlatform(selectedNodeData?.newNodeData.options.itsmService ?? ITSMPlatform.TOPdesk);
  }, [selectedNodeData]);

  const platforms: Array<ITSMPlatformTexts> = [ITSMPlatformTexts.TOPdesk, ITSMPlatformTexts.Salesforce];

  const handleUpdate = useCallback(
    /**
     * Handles the update of ticket information based on the selected ITSM platform.
     * @param  updatedTicketInfo - The updated ticket information.
     * @param  platform - The selected ITSM platform.
     * @returns void
     */
    (updatedTicketInfo: TOPDeskInformation | SalesforceInformation, platform: ITSMPlatform): void => {
      if (platform === ITSMPlatform.TOPdesk && isTOPDeskInformation(updatedTicketInfo)) {
        const updatedNewNodeData: DialogStep = cloneDeep(newNodeData!);
        updatedNewNodeData.options.itsmService = ITSMPlatform.TOPdesk;
        updatedNewNodeData.options.topDeskInfo = updatedTicketInfo;
        updatedNewNodeData.options.salesforceInfo = null;
        setNewNodeData(updatedNewNodeData);

        const updatedNodeSelection: NodeSelection = cloneDeep(selectedNodeData);
        updatedNodeSelection.changed = true;
        updatedNodeSelection.newNodeData = updatedNewNodeData;
        setSelectedNodeData(updatedNodeSelection);
      } else if (platform === ITSMPlatform.Salesforce && isSalesforceInformation(updatedTicketInfo)) {
        const updatedNewNodeData: DialogStep = cloneDeep(newNodeData!);
        updatedNewNodeData.options.itsmService = ITSMPlatform.Salesforce;
        updatedNewNodeData.options.salesforceInfo = updatedTicketInfo;
        updatedNewNodeData.options.topDeskInfo = null;

        const updatedNodeSelection: NodeSelection = cloneDeep(selectedNodeData);
        updatedNodeSelection.changed = true;
        updatedNodeSelection.newNodeData = updatedNewNodeData;
        setSelectedNodeData(updatedNodeSelection);
      }
    },
    [],
  );

  /**
   * Handles the change event when the platform selection is updated.
   * @param  value - The selected platform value.
   * @returns void
   */
  const handlePlatformChange = (value: string): void => {
    const updatedNewNodeData: DialogStep = cloneDeep(newNodeData!);
    const plat = value === ITSMPlatformTexts.TOPdesk ? ITSMPlatform.TOPdesk : ITSMPlatform.Salesforce;
    updatedNewNodeData.options.itsmService = plat;

    if (plat === ITSMPlatform.TOPdesk) {
      const info: TOPDeskInformation = {
        briefDescription: "",
        request: "",
        category: null,
        subcategory: null,
        callType: null,
        impact: null,
        urgency: null,
        priority: null,
        processingStatus: null,
        entryType: null,
      };
      updatedNewNodeData.options.topDeskInfo = info;
      updatedNewNodeData.options.salesforceInfo = null;
    } else if (plat === ITSMPlatform.Salesforce) {
      const info: SalesforceInformation = {
        type: "",
        status: "",
        origin: "",
        subject: "",
        priority: "",
        description: "",
      };
      updatedNewNodeData.options.salesforceInfo = info;
      updatedNewNodeData.options.topDeskInfo = null;
    }

    setNewNodeData(updatedNewNodeData);
    const updatedData: NodeSelection = cloneDeep(selectedNodeData);
    updatedData.changed = true;
    updatedData.newNodeData = updatedNewNodeData;
    setSelectedNodeData(updatedData);
  };

  /**
   * This function adds description questions to the ITSM Ticket. This is also considered as extra questions.
   */
  const onAddDescQuestion = (): void => {
    const updateNodeData: DialogStep = {
      ...selectedNodeData.newNodeData,
    };

    const newDescQuestionID = updateNodeData.options.DescQuestions.length > 0 ? uuidv4() : "start";

    // Assign prelim exit of last node added in array
    const newDescQuestion: ITSMDescriptionQuesion = {
      id: newDescQuestionID,
      questionHeader: "",
      question: "",
      answer: "",
      nextStep: "",
    };

    // Assignn nextStep of previous node to new node if there is a previous node
    if (updateNodeData.options.DescQuestions.length > 0) {
      const previousQuestion = updateNodeData.options.DescQuestions[updateNodeData.options.DescQuestions.length - 1];
      previousQuestion.nextStep = newDescQuestion.id;
    }

    updateNodeData.options.DescQuestions.push(newDescQuestion);

    const newNodeSelection: NodeSelection = {
      oldNodeData: selectedNodeData.oldNodeData,
      newNodeData: updateNodeData,
      changed: true,
    };

    setSelectedNodeData(newNodeSelection);
  };

  /**
   * This function removes description questions from the ITSM Ticket.
   */
  const removeDescQuestion = (id: string): void => {
    const updateNodeData: DialogStep = {
      ...selectedNodeData.newNodeData,
    };

    const index = updateNodeData.options.DescQuestions.findIndex(
      (question: ITSMDescriptionQuesion) => question.id === id,
    );

    if (index === -1) return;

    if (id === "start") {
      const nextQuestionID: string = updateNodeData.options.DescQuestions.find(
        (question: ITSMDescriptionQuesion) => question.id === "start",
      ).nextStep;
      if (nextQuestionID !== "") {
        updateNodeData.options.DescQuestions.find(
          (question: ITSMDescriptionQuesion) => question.id === nextQuestionID,
        ).id = "start";
      }
    }

    // Remove the question at the specified index
    const removedQuestion = updateNodeData.options.DescQuestions.splice(index, 1)[0];

    // Update previous question nextStep if previous question exists
    if (
      updateNodeData.options.DescQuestions.find(
        (question: ITSMDescriptionQuesion) => question.nextStep === removedQuestion.id,
      ) !== undefined
    ) {
      updateNodeData.options.DescQuestions.find(
        (question: ITSMDescriptionQuesion) => question.nextStep === removedQuestion.id,
      ).nextStep = removedQuestion.nextStep;
    }

    const newNodeSelection: NodeSelection = {
      oldNodeData: selectedNodeData.oldNodeData,
      newNodeData: updateNodeData,
      changed: true,
    };

    setSelectedNodeData(newNodeSelection);
  };

  return (
    <>
      <CustomSelect
        label="Platform"
        selectedValue={
          selectedPlatform === ITSMPlatform.TOPdesk ? ITSMPlatformTexts.TOPdesk : ITSMPlatformTexts.Salesforce
        }
        handleChange={(value) => {
          setPlatform(value === ITSMPlatformTexts.TOPdesk ? ITSMPlatform.TOPdesk : ITSMPlatform.Salesforce);
          handlePlatformChange(value);
        }}
        itemList={platforms.map((el) => ({ id: el, name: el }))}
        selectId="platform-select"
      />
      {selectedPlatform === ITSMPlatform.TOPdesk && (
        <TopDeskTicketInformation
          handleUpdate={handleUpdate}
          topDeskInformation={newNodeData?.options?.topDeskInfo ?? null}
          topDeskApiData={topDeskApiData}
          isTopDeskDataLoaded={isTopDeskDataLoaded}
          loadTopDeskApiData={loadTopDeskApiData}
        />
      )}
      {selectedPlatform === ITSMPlatform.Salesforce && (
        <SalesforceCaseInformation
          handleUpdate={handleUpdate}
          salesForceInformation={newNodeData?.options?.salesforceInfo ?? null}
          selectedNodeData={selectedNodeData}
        />
      )}
      <Divider>Extra Vragen</Divider>
      <Grid container paddingTop={1}>
        <Grid item xs={12}>
          <Stack spacing={2} divider={<Divider flexItem />}>
            {selectedNodeData?.newNodeData?.options?.DescQuestions?.map((question: ITSMDescriptionQuesion) => (
              <Grid container key={`${question.id}`} direction="column" gap={1}>
                <Grid item xs={12}>
                  <ShowOptions
                    componentType={ShowOptionsComponentType.DescQuestion}
                    selectedNodeData={selectedNodeData}
                    setSelectedNodeData={setSelectedNodeData}
                    optionsId={question.id}
                  ></ShowOptions>
                </Grid>
                <Grid container justifyContent="flex-end">
                  <IconButton
                    onClick={() => {
                      removeDescQuestion(question.id);
                    }}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} paddingTop={2}>
          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              onAddDescQuestion();
            }}
            variant="contained"
            color="success"
            fullWidth={true}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ITSMTicketInformation;

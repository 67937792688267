import React, { FC, useEffect, useState } from "react";

// Model Imports
import { DialogStep } from "../../../../models/Dialogs";
import { KnowledgeSource } from "../../../../models/KnowledgeSource";
import { ContainerNames } from "../../../../models/enums";
import { BlobFromApi } from "../../../../API/StorageInteraction";
import { NodeSelection } from "../VisualisationDialog";

// MUI Imports
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

// Context imports
import { useProductionContext } from "../../../../contexts/ProductionContext";

interface ShowOpenAIMessageOptionProps {
  selectedNodeData: { newNodeData: DialogStep; oldNodeData: DialogStep | null };
  setSelectedNodeData: (arg0: NodeSelection) => void;
}

/**
 * ShowOpenAIMessageOption Component
 * @param selectedNodeData - The selected node data.
 * @param setSelectedNodeData - The function to set the selected node data.
 * @returns A ShowOpenAIMessageOption component.
 */
const ShowOpenAIMessageOption: FC<ShowOpenAIMessageOptionProps> = ({
  selectedNodeData,
  setSelectedNodeData,
}): React.ReactElement => {
  const productionContext = useProductionContext();

  const [knowledgeSources, setKnowledgeSources] = useState<BlobFromApi | undefined>();

  useEffect(() => {
    if (productionContext !== undefined && productionContext !== null) {
      const container = productionContext.containers.find((blob) => blob.key === ContainerNames.KnowledgeSources);
      setKnowledgeSources(container?.blob);
    }
  }, [productionContext?.containers]);

  /**
   * Handles the change of the response of the node.
   * @param event - The event that triggered the change.
   * @returns void
   */
  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const updateNodeData: DialogStep = {
      ...selectedNodeData.newNodeData,
    };

    updateNodeData.options.llmStepModel.message = event.target.value;

    const newNodeSelection: NodeSelection = {
      oldNodeData: selectedNodeData.oldNodeData!,
      newNodeData: updateNodeData,
      changed: true,
    };

    setSelectedNodeData(newNodeSelection);
  };

  /**
   * Adds a source to the list of sources
   * @returns void
   */
  const addSource = (): void => {
    const updateNodeData: DialogStep = {
      ...selectedNodeData.newNodeData,
    };

    updateNodeData.options.llmStepModel.knowledgeSources.push("");

    const newNodeSelection: NodeSelection = {
      oldNodeData: selectedNodeData.oldNodeData!,
      newNodeData: updateNodeData,
      changed: true,
    };

    setSelectedNodeData(newNodeSelection);
  };

  /**
   * Removes a source based on the id
   */
  const removeSource = (boundSource: string): void => {
    const updateNodeData: DialogStep = {
      ...selectedNodeData.newNodeData,
    };

    const firstIndex = updateNodeData.options.llmStepModel.knowledgeSources.indexOf(boundSource);

    // Remove item at first index
    updateNodeData.options.llmStepModel.knowledgeSources.splice(firstIndex, 1);

    const newNodeSelection: NodeSelection = {
      oldNodeData: selectedNodeData.oldNodeData!,
      newNodeData: updateNodeData,
      changed: true,
    };

    setSelectedNodeData(newNodeSelection);
  };

  /**
   * On change function that changes the selected option
   * @param event - The event generated by changing the value of a select field.
   */
  const onChangeSelection = (sourceId: string, event: SelectChangeEvent): void => {
    const updateNodeData: DialogStep = {
      ...selectedNodeData.newNodeData,
    };
    const firstIndex = updateNodeData.options.llmStepModel.knowledgeSources.indexOf(sourceId);
    updateNodeData.options.llmStepModel.knowledgeSources[firstIndex] = event.target.value;

    const newNodeSelection: NodeSelection = {
      oldNodeData: selectedNodeData.oldNodeData!,
      newNodeData: updateNodeData,
      changed: true,
    };

    setSelectedNodeData(newNodeSelection);
  };

  /**
   * Handles the change of the else isHidden checkbox.
   * @param event - The event that triggered the change.
   * @returns void
   */
  const onChangeUseUserQuestion = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const updateNodeData: DialogStep = {
      ...selectedNodeData.newNodeData,
    };

    updateNodeData.options.useUserQuestion = event.target.checked;

    const newNodeSelection: NodeSelection = {
      oldNodeData: selectedNodeData.oldNodeData!,
      newNodeData: updateNodeData,
      changed: true,
    };

    setSelectedNodeData(newNodeSelection);
  };

  if (
    knowledgeSources === undefined ||
    selectedNodeData?.newNodeData?.options?.llmStepModel?.knowledgeSources === undefined
  ) {
    return <Skeleton variant="rectangular" width={"100%"} height={200} />;
  }

  return (
    <Stack key="openAI-message" width={"100%"} spacing={2}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              onChange={onChangeUseUserQuestion}
              checked={selectedNodeData.newNodeData.options.useUserQuestion}
              inputProps={{ name: "message" }}
            />
          }
          label="Gebruik de vraag vanuit de gebruiker"
        />
      </FormGroup>
      <TextField
        label={"LLM bericht"}
        value={selectedNodeData.newNodeData.options.llmStepModel.message}
        onChange={onChange}
        inputProps={{ name: "message" }}
        fullWidth={true}
      />
      <Divider>Kennisbronnen</Divider>
      {selectedNodeData?.newNodeData?.options?.llmStepModel?.knowledgeSources?.map((source: string) => (
        <FormControl key={`${source}_knowledgeOptions`} fullWidth error={source === ""}>
          <InputLabel>Kennisbron Selectie</InputLabel>
          <Box display="flex" alignItems="center">
            <Select
              label="Kennisbron Selectie"
              fullWidth
              error={source === ""}
              onChange={(event: SelectChangeEvent) => {
                onChangeSelection(source, event);
              }}
              value={source}
            >
              {knowledgeSources.value.map((knowledgeSource: KnowledgeSource) => (
                <MenuItem
                  key={`${knowledgeSource.key}_knowledgeSource`}
                  value={knowledgeSource.name}
                  disabled={selectedNodeData?.newNodeData?.options?.llmStepModel?.knowledgeSources.find(
                    (source: string) => source === knowledgeSource.name,
                  )}
                >
                  {knowledgeSource.name}
                </MenuItem>
              ))}
            </Select>
            <IconButton
              onClick={() => {
                removeSource(source);
              }}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </Box>
          <FormHelperText>{source === "" ? "Kies een kennisbron" : ""}</FormHelperText>
        </FormControl>
      ))}
      <Button
        onClick={() => {
          addSource();
        }}
        startIcon={<AddIcon />}
        variant="contained"
        color="success"
        fullWidth
        disabled={selectedNodeData?.newNodeData?.options?.llmStepModel?.knowledgeSources?.includes("")}
      />
    </Stack>
  );
};

export default ShowOpenAIMessageOption;

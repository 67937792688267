import React from "react";
import { Handle, NodeProps, Position } from "@xyflow/react";

import { DialogNode as DialogNodeType } from "./JsonToReactFlow";
import { StepTypes } from "../StepTypes";
import styles from "../visualisationDialog.module.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import { red } from "@mui/material/colors";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SportsScoreIcon from "@mui/icons-material/SportsScore";

/**
 * Component that renders the specific node
 * @param param0 - props
 * @returns node elements
 */
export const DialogNode = ({ data, selected }: NodeProps<DialogNodeType>): JSX.Element => (
  <div className={`${styles.node} ${getNodeSpecificClassName(data.stepType)}`}>
    {/* <NodeResizer color="#08b703" isVisible={(selected && data.selectable) ?? selected !== undefined} minWidth={100} minHeight={30} /> */}
    <div className="handles targets">
      {data.targetHandles.map((handle: { id: string; className: string }) => (
        <div className={handle.className} key={handle.id}>
          <Handle key={handle.id} id={handle.id} type="target" position={Position.Top} isConnectableStart={false} />
        </div>
      ))}
    </div>
    <div className="label">
      {data.stepType !== StepTypes.Finish && data.stepType !== StepTypes.Start ? (
        data.label
      ) : data.stepType === StepTypes.Start ? (
        <PlayArrowIcon sx={{ color: "black" }} />
      ) : (
        <SportsScoreIcon sx={{ color: "black" }} />
      )}
      {data.stepType !== StepTypes.Finish && data.stepType !== StepTypes.Start ? (
        <div className={styles.DeleteIconButton} hidden={!(selected ?? false)} onClick={() => data.onNodesDelete(data)}>
          <DeleteIcon sx={{ color: red[500] }} />
        </div>
      ) : null}
    </div>
    <div className="handles sources">
      {data.sourceHandles.map((handle: { id: string; className: string }) => (
        <div className={handle.className} key={handle.id}>
          <Handle key={handle.id} id={handle.id} type="source" position={Position.Bottom} />
        </div>
      ))}
    </div>
  </div>
);

/**
 * Get the correct class name for the node
 * @param type - the nodetype that needs to be checked
 * @returns the correct class name
 */
export const getNodeSpecificClassName = (type: string): string => {
  switch (type) {
    case StepTypes.Start:
      return styles.start;
    case StepTypes.Finish:
      return styles.finish;
    case StepTypes.Message:
      return styles.message;
    case StepTypes.Conditional:
      return styles.conditional;
    case StepTypes.AskWithButtons:
      return styles.askWithButtons;
    case StepTypes.YesNoDialog:
      return styles.yesNoDialog;
    case StepTypes.TextPrompt:
      return styles.textPrompt;
    case StepTypes.CreateTicketDialog:
      return styles.createTicketDialog;
    case StepTypes.Redirect:
      return styles.redirect;
    case StepTypes.OpenAI:
      return styles.openAI;
    default:
      return "node";
  }
};

export default DialogNode;

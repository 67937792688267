import React, { FC, useEffect, useState } from "react";

// Material Imports
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent, FormControl, TextField } from "@mui/material";
import { Language, LanguageText } from "../../../models/Language";
import LanguageSelect from "../../Selects/LanguageSelect";

export interface AddNewLanguageDialogProps {
  addLanguage: (value: LanguageText) => void;
  removeLanguage: (languageKey: string) => void;
  isOpen: boolean;
  isEdit: boolean;
  handleClose: () => void;
  languages: Array<Language>;
  dialogLanguage: string;
  dialogValue: string;
  title: string;
}

/**
 * A dialog that allows the user to add a new language to the current text.
 * @param addLanguage - The function to call when the user confirms the dialog
 * @param removeLanguage - The function to call when the user removes a language
 * @param isOpen - Whether the dialog is open or not
 * @param isEdit - Whether the dialog is in edit mode or not
 * @param handleClose - The function to call when the user closes the dialog
 * @param languages - The languages to show in the language select
 * @param dialogLanguage - The language to show in the language select
 * @param dialogValue - The value to show in the input
 * @param title - The title of the dialog
 * @returns A dialog that allows the user to add a new language to the current text
 */
const AddNewLanguageDialog: FC<AddNewLanguageDialogProps> = ({
  isOpen,
  handleClose,
  languages,
  dialogValue,
  dialogLanguage,
  isEdit,
  addLanguage,
  removeLanguage,
  title,
}) => {
  const [value, setValue] = useState<string>("");
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");

  useEffect(() => {
    if (dialogLanguage === null || dialogLanguage === undefined || dialogLanguage === "") {
      setSelectedLanguage(languages.length > 0 ? languages[0].key : "");
    } else {
      setSelectedLanguage(dialogLanguage);
    }
  }, [dialogLanguage, languages]);

  useEffect(() => {
    setValue(dialogValue);
  }, [dialogValue]);

  /**
   * Handle the confirm button click.
   * @returns A promise that resolves when the confirm button is clicked
   */
  const handleConfirm = async (): Promise<void> => {
    const languageText: LanguageText = {
      key: selectedLanguage,
      value,
    };

    addLanguage(languageText);
    handleClose();
  };

  /**
   * Handle the language change.
   * @param newLanguage - The new language
   * @returns void
   */
  const handleChangeLanguage = (newLanguage: string): void => {
    setSelectedLanguage(newLanguage);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      fullWidth={true}
      scroll="paper"
    >
      <DialogTitle data-testid="dialog-title">{title}</DialogTitle>
      <DialogContent dividers={true}>
        {!isEdit && (
          <LanguageSelect
            data-testid="language-select"
            handleChange={handleChangeLanguage}
            languages={languages}
            label="Taal"
            selectedValue={selectedLanguage}
          />
        )}
        <FormControl fullWidth>
          <TextField
            inputProps={{ "data-testid": "dialog-textfield" }}
            value={value}
            multiline={true}
            variant="outlined"
            onChange={(e) => {
              setValue(e.target.value);
            }}
            placeholder="Voeg tekst toe voor de geselecteerde taal"
            autoFocus
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="confirm-button"
          onClick={() => {
            handleConfirm().catch(() => {
              // Handle error
            });
          }}
          color="primary"
          variant="contained"
        >
          Akkoord
        </Button>
        {isEdit && (
          <Button
            data-testid="remove-button"
            onClick={() => {
              removeLanguage(dialogLanguage);
              handleClose();
            }}
            color="error"
            variant="contained"
          >
            Verwijderen
          </Button>
        )}
        <Button
          data-testid="cancel-button"
          onClick={() => {
            handleClose();
          }}
          color="warning"
          variant="contained"
        >
          Annuleer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewLanguageDialog;

// #region Change Tracking Models

// TODO: Strictly typed object creation support
// Highlights, Dialogs, etc.
/**
 * Type that allows us to keep track of individual changes in a container
 * Future addition may be User Data
 */
export type TrackedChange = {
  key: string;
  origin: ChangeOrigin;
  oldValue?: any; // If only oldvalue exists, item may be deleted
  newValue?: any; // If only newvalue exists, item may be new
  changeType: ChangeType;
  displayOrder: Array<string>;
  lastModified: Date;
  live: boolean;
};

/**
 * type that gives us more information regard the sort of change that was made
 */
export enum ChangeType {
  New = "New",
  Update = "Update",
  Delete = "Delete",
}

/**
 * type that gives us more information about the origin of a change
 */
export type ChangeOrigin = {
  containerName: string;
  fileName: string;
};

/**
 * Type that allows us to create a bucket to track local changes
 */
export type ChangeBucket = {
  key: string;
  text: string;
  changes: Array<TrackedChange>;
};

/**
 * Type that allows us to represent a drawer item
 */
export type DrawerItem = {
  key: string;
  text: string;
  path: string;
  index: number;
};

/**
 * Template array that allows us to easily create copies
 */
export const changeBucketTemplateArray: Array<ChangeBucket> = [
  {
    key: "answers",
    text: "Berichten",
    changes: [],
  },
  {
    key: "dialogs",
    text: "Dialogen",
    changes: [],
  },
  {
    key: "highlights",
    text: "Highlights",
    changes: [],
  },
  {
    key: "groupincidents",
    text: "Groepsincidenten",
    changes: [],
  },
  {
    key: "knowledgesources",
    text: "Kennisbronnen",
    changes: [],
  },
];

// Define tab names (text), url path and order (index)
export const drawerItems: Array<DrawerItem> = [
  {
    key: "answers",
    text: "Berichten",
    path: "/MessageEditor",
    index: 1,
  },

  {
    key: "highlights",
    text: "Highlights",
    path: "/HighlightEditor",
    index: 3,
  },
  {
    key: "faq",
    text: "Faq",
    path: "/Faq",
    index: 5,
  },
  {
    key: "knowledgesources",
    text: "Kennisbronnen",
    path: "/KnowledgeSources",
    index: 6,
  },
];

const NLUFeatureFlag: boolean = process.env.REACT_APP_ENABLE_NLU_QUESTION_ANSWERING === "true";

if (NLUFeatureFlag) {
  drawerItems.push({
    key: "vragen",
    text: "Vragen",
    path: "/",
    index: 0,
  });

  drawerItems.push({
    key: "dialogs",
    text: "Dialogen",
    path: "/DialogEditor",
    index: 2,
  });
}

const GroupIncidentsFeatureFlag: boolean = process.env.REACT_APP_ENABLE_GROUP_INCIDENTS === "true";

if (GroupIncidentsFeatureFlag) {
  drawerItems.push({
    key: "groupincidents",
    text: "Groepsincidenten",
    path: "/GroupIncidentEditor",
    index: 4,
  });
}

// #endregion

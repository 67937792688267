import { Box, Grid, TextField } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { ITSMPlatform, SalesforceInformation } from "../../../../models/ItsmModels";
import { NodeSelection } from "../VisualisationDialog";

interface SalesforceCaseInformationProps {
  handleUpdate: (updatedTicketInfo: SalesforceInformation, platform: ITSMPlatform) => void;
  salesForceInformation: SalesforceInformation | null;
  selectedNodeData: NodeSelection;
}

/**
 * SalesforceCaseInformation Component
 * @param handleUpdate - The function to handle the update of the ticket information.
 * @param salesForceInformation - The SalesforceInformation.
 * @returns A React component representing the SalesforceCaseInformation to display and edit ticket information.
 */
const SalesforceCaseInformation: FC<SalesforceCaseInformationProps> = ({
  handleUpdate,
  salesForceInformation,
  selectedNodeData,
}: SalesforceCaseInformationProps) => {
  const isMount = useRef<boolean>(true);
  const [subject, setSubject] = useState<string>(salesForceInformation?.subject ?? "");
  const [description, setDescription] = useState<string>(salesForceInformation?.description ?? "");
  const [status, setStatus] = useState<string>(salesForceInformation?.status ?? "");
  const [priority, setPriority] = useState<string>(salesForceInformation?.priority ?? "");
  const [type, setType] = useState<string>(salesForceInformation?.type ?? "");
  const [origin, setOrigin] = useState<string>(salesForceInformation?.origin ?? "");

  useEffect(() => {
    if (!isMount.current) {
      // Update TOPdesk values when the user changes them
      handleUpdate(
        {
          subject,
          description,
          status,
          priority,
          type,
          origin,
        },
        ITSMPlatform.Salesforce,
      );
    } else {
      isMount.current = false;
    }
  }, [subject, description, status, priority, type, origin, handleUpdate, isMount]);

  // useEffect(() => {
  //   if(salesForceInformation !== null) {
  //     setSubject(salesForceInformation.subject)
  //     setDescription(salesForceInformation.description)
  //     setStatus(salesForceInformation.status)
  //     setPriority(salesForceInformation.priority)
  //     setType(salesForceInformation.type)
  //     setOrigin(salesForceInformation.origin)
  //   }
  // }, [selectedNodeData, salesForceInformation])

  return (
    <>
      <Grid item>
        <h2>Vooringevulde antwoorden:</h2>
      </Grid>
      <Box sx={{ display: "flex" }} mb={2} mr={2}>
        <TextField
          id={`request-subject`}
          label="Verzoek onderwerp"
          placeholder="Voer een onderwerp voor het incident in"
          multiline
          fullWidth
          value={subject}
          onChange={(e) => {
            setSubject(e.target.value);
          }}
        />
      </Box>
      <Box sx={{ display: "flex" }} mb={2} mr={2}>
        <TextField
          id={`request-description`}
          label="Incident Beschrijving "
          placeholder="Voer een beschrijving voor het ticket in"
          multiline
          fullWidth
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </Box>
      <Box sx={{ display: "flex" }} mb={2} mr={2}>
        <TextField
          id={`request-status`}
          label="Incident Status"
          placeholder="Voer een status voor het ticket in"
          multiline
          fullWidth
          value={status}
          onChange={(e) => {
            setStatus(e.target.value);
          }}
        />
      </Box>
      <Box sx={{ display: "flex" }} mb={2} mr={2}>
        <TextField
          id={`request-priority`}
          label="Incident Prioriteit"
          placeholder="Voer een prioriteit voor het ticket in"
          multiline
          fullWidth
          value={priority}
          onChange={(e) => {
            setPriority(e.target.value);
          }}
        />
      </Box>
      <Box sx={{ display: "flex" }} mb={2} mr={2}>
        <TextField
          id={`request-type`}
          label="Incident Type"
          placeholder="Voer een type voor het ticket in"
          multiline
          fullWidth
          value={type}
          onChange={(e) => {
            setType(e.target.value);
          }}
        />
      </Box>
      <Box sx={{ display: "flex" }} mb={2} mr={2}>
        <TextField
          id={`request-origin`}
          label="Incident Origin"
          placeholder="Voer een origin voor het ticket in"
          multiline
          fullWidth
          value={origin}
          onChange={(e) => {
            setOrigin(e.target.value);
          }}
        />
      </Box>
    </>
  );
};

export default SalesforceCaseInformation;

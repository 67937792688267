export enum BlobValueType {
  ARRAY = "ARRAY",
  OBJECT = "OBJECT",
  VALUE = "VALUE",
  NEW = "NEW",
  DELETE = "DELETE",
}

export enum MessageType {
  MESSAGE = "message",
  BUTTONTEXT = "buttonText",
  QUESTION = "question",
}

export enum ContainerNames {
  Answers = "answers",
  Dialogs = "dialogs",
  Faqs = "faqs",
  GroupIncidents = "groupincidents",
  Highlights = "highlights",
  Intents = "intents",
  KnowledgeSources = "knowledgesources",
}

export enum ShowOptionsComponentType {
  Question = "question",
  Message = "message",
  DescQuestion = "descQuestion",
  Button = "buttonText",
}

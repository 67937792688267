import React, { FC, useState, useEffect } from "react";

// Material Imports
import { FormControl, InputLabel, Select, Skeleton, MenuItem, SelectChangeEvent } from "@mui/material";

// Css
import "./ProjectListDropdown.css";

// Context Imports
import { useNLUContext } from "../../../../contexts/NLU/NLUContext";
interface ProjectListProps {
  projects: Array<string> | null;
}

/**
 * AppListDropdown Component
 * @param projects - The list of projects.
 * @returns A React component representing the AppListDropdown to display and edit the apps.
 */
const ProjectListDropdown: FC<ProjectListProps> = ({ projects }) => {
  // Context.
  const CLUContext = useNLUContext();

  // State
  const [selectedProjectName, setSelectedProjectName] = useState<string | undefined>(
    projects === null ? undefined : projects[0],
  );

  useEffect(() => {
    // Set the project name, but prevent it from doing so if it's undefined
    if (CLUContext?.selectedProject?.name === undefined) return;
    setSelectedProjectName(CLUContext.selectedProject.name);
  }, [CLUContext?.selectedProject]);

  /**
   * Handles the change event when the project selection is changed.
   * @param event - The event that triggered this function.
   */
  const onProjectChange = (event: SelectChangeEvent): void => {
    // Find the project based on the selection that triggered the event
    if (projects === null) return;
    const project = projects.find((project) => project === event.target.value);

    // If the project exists, proceed with processing.
    if (project !== undefined) {
      setSelectedProjectName(project);

      if (CLUContext === null) return;
      void processProjectChange(project).catch((error) => {
        throw new Error(`Failed to process the change in selected project: ${error}`);
      });
    }
  };

  /**
   * Processes the changing of the app as triggered by the change event.
   */
  const processProjectChange = async (project: string): Promise<void> => {
    if (CLUContext == null) return;
    await CLUContext.setSelectedProject!(project).catch((error) => {
      throw new Error(`Failed to set the selected project: ${error}`);
    });

    // Move this functionality to the intent groups components instead
    // .then(async () => {
    //   if (CLUContext.selectedIntent === undefined || CLUContext.selectedIntent === null) return;
    //   await CLUContext.setIntentSpecificUtterences!(CLUContext.selectedIntent);
    // });
  };

  if (projects === null || projects.length <= 0) {
    return <Skeleton variant="rounded" height="56px" width="100%" />;
  }

  return (
    <>
      <FormControl fullWidth={true}>
        {selectedProjectName !== undefined ? (
          <>
            <InputLabel>Geselecteerde Project</InputLabel>
            <Select
              label="NLU Project selectie"
              value={selectedProjectName}
              onChange={onProjectChange}
              name="app"
              fullWidth={true}
            >
              {projects.map((project) => {
                if (project !== null) {
                  return (
                    <MenuItem key={`project-${project}`} value={project}>
                      {project}
                    </MenuItem>
                  );
                } else {
                  return null;
                }
              })}
            </Select>
          </>
        ) : (
          <Skeleton width="100%" height="100%"></Skeleton>
        )}
      </FormControl>
    </>
  );
};

export default ProjectListDropdown;

import React, { FC, useState } from "react";
import { keys } from "../../../enums/keys";

// Material Imports
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent, DialogContentText } from "@mui/material";
import { Input } from "@mui/joy";

export interface EnterTextDialogProps {
  executable: (value: string) => void;
  title: string;
  button1Text: string;
  button2Text: string;
  placeholder: string;
  description: string;
  isOpen: boolean;
  handleClose: () => void;
}

/**
 * A dialog component that allows users to enter text and confirm or cancel the input.
 * @param isOpen - The dialog's open state.
 * @param handleClose - Function to handle the closing operation of the dialog.
 * @param title - The dialog title.
 * @param button1Text - The text for the first action button.
 * @param button2Text - The text for the second action button.
 * @param description - A description displayed in the dialog.
 * @param executable - A callback function to execute when confirming the input. It receives the entered value as a parameter.
 * @param placeholder - The placeholder text for the input field.
 * @returns The EnterTextDialog component.
 */
const EnterTextDialog: FC<EnterTextDialogProps> = ({
  isOpen,
  handleClose,
  title,
  button1Text,
  button2Text,
  description,
  executable,
  placeholder,
}) => {
  const [value, setValue] = useState<string>("");

  /**
   * Handles the confirmation action, calls the `executable` callback with the entered value, closes the dialog, and resets the value.
   */
  const handleConfirm = async (): Promise<void> => {
    executable(value); // Call the callback function when confirmed and send the value
    handleClose(); // Close the dialog
    setValue(""); // Reset the value (so it's empty when the dialog is opened again)
  };

  /**
   * Handles the keydown event for the input field. If the Enter key is pressed, it invokes the `handleConfirm` function.
   * @param event - The keydown event object.
   */
  const handleKeyDown = (event: { key: string }): void => {
    if (event.key === keys.Enter) {
      handleConfirm().catch(() => {
        // Handle error
      });
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} scroll="paper">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText id="dialog-description">{description}</DialogContentText>
        <Input
          size="md"
          value={value}
          variant="outlined"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleConfirm().catch(() => {
              // Handle error
            });
          }}
          disabled={value.length <= 0}
        >
          {button1Text}
        </Button>
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          {button2Text}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EnterTextDialog;

import React, { createContext, useContext, FC, Dispatch, useReducer, ReactNode, useEffect } from "react";

// Data Imports
import { NluProject, NluIntent, NluUtterance, NluTrainingState } from "../../models/NLU";
import { ApiBlobResponse, StorageEnvironment, getContainer, postContainer } from "../../API/StorageInteraction";
import delay from "../../helpers/delay";
import { ContainerNames } from "../../models/enums";
import {
  createOrUpdateIntentAsync,
  createOrUpdateUtteranceAsync,
  deployProjectAsync,
  exportProjectAsync,
  getProjectsAsync,
  getTrainingJobsAsync,
  removeIntentAsync,
  removeUtteranceAsync,
  trainProjectAsync,
} from "../../API/NluInteraction";
import { useStagingContext } from "../StagingContext";
import { deepCopy } from "../../helpers/deepCopy";

// Create a context for your NLUApp-related functions and state
const NLUContext = createContext<NLUContextAttributes | null>(null);

const NLUDispatchContext = createContext<Dispatch<any> | null>(null);

const initialNLUContext: NLUContextAttributes = {
  projects: null,
  selectedProject: null,
  selectedIntent: null,
  usedIntents: null,
};

export enum NLUActionType {
  "createOrUpdateIntent",
  "createOrUpdateUtterance",
  "removeIntent",
  "removeUtterance",
  "setProjects",
  "setSelectedProject",
  "setSelectedIntent",
  "setUsedIntents",
  "updateUsedIntents",
}

interface NLUContextProviderProps {
  children: ReactNode | Array<ReactNode> | null;
}

/**
 * Define the attributes that need to be stored in the context here.
 */
type NLUContextAttributes = {
  // NLU related attributes
  projects: Array<string> | null;
  selectedProject: NluProject | null;
  selectedIntent: NluIntent | null;
  usedIntents: Array<NluIntent> | null;
  addIntents?: (newName: string, returnIntent?: boolean) => Promise<NluIntent | undefined>;
  createOrUpdateIntent?: (name: string) => Promise<void>;
  createOrUpdateUtterance?: (text: string, parent: string, previousText?: string) => Promise<void>;
  deploySelectedProject?: () => Promise<void>;
  getIntentSpecificUtterances?: (category: string) => Array<NluUtterance>;
  getTrainingStatus?: (deploy?: boolean) => Promise<boolean>;
  getTrainingStatusOnCallInterval?: (deploy?: boolean) => Promise<boolean>;
  removeIntent?: (category: string) => Promise<void>;
  removeUtterance?: (text: string) => Promise<void>;
  setSelectedProject?: (project: string) => Promise<void>;
  setSelectedIntent?: (intent: NluIntent) => void;
  setUsedIntents?: () => Promise<void>;
  trainSelectedProject?: () => Promise<void>;
  updateUsedIntents?: (intent: NluIntent, remove: boolean) => void;
};

/**
 * Create a provider for your NLUApp context
 * @param children - the children of the provider
 * @returns a provider for your NLUApp context
 */
export const NLUContextProvider: FC<NLUContextProviderProps> = ({ children }) => {
  // Context
  const [context, dispatch] = useReducer(NLUReducer, initialNLUContext);
  const stagingContext = useStagingContext();

  const NLUFeatureFlag: boolean = process.env.REACT_APP_ENABLE_NLU_QUESTION_ANSWERING === "true";

  useEffect(() => {
    if (!NLUFeatureFlag) return;
    // Upon application load, set the basic project information
    setProjects().catch((error) => {
      throw new Error(`Failed to get the project information: ${error}`);
    });

    // Set the used intents.
    setUsedIntents().catch((error) => {
      throw new Error(`Failed to set the used intents: ${error}`);
    });
  }, []);

  useEffect(() => {
    // Set the selected project to be the first project in the list.
    if (context.projects === null) return;
    setSelectedProject(context.projects[0]).catch((error) => {
      throw new Error(`Setting initially selected projected failed: ${error}`);
    });
  }, [context.projects]);

  /**
   * Sets the NLU projects with basic information in the context
   */
  const setProjects = async (): Promise<void> => {
    // Get the projects via the API
    const projects: Array<string> = await getProjectsAsync();

    const action: NLUReducerAction = {
      type: NLUActionType.setProjects,
      payload: projects,
    };

    dispatch(action);
  };

  /**
   * Sets the current NLU App in the context
   * @param event - the event that triggered the function
   */
  const setSelectedProject = async (projectName: string): Promise<void> => {
    if (context.projects === null || stagingContext === null) return;

    // get the information of the basic project
    const exists: boolean = context.projects.some((name) => name === projectName);

    // if that information exists, get the project
    if (!exists) return;
    const project: NluProject = await exportProjectAsync(projectName);

    const action: NLUReducerAction = {
      type: NLUActionType.setSelectedProject,
      payload: project,
    };
    dispatch(action);
  };

  /**
   * Set the currently selected intent.
   * @param intent - The intent to set.
   */
  const setSelectedIntent = (intent: NluIntent): void => {
    const action: NLUReducerAction = {
      type: NLUActionType.setSelectedIntent,
      payload: intent,
    };

    dispatch(action);
  };

  /**
   * Add utterance to NLU App
   * @param newIntentName - the new intent name to add
   */
  const createOrUpdateIntent = async (name: string): Promise<void> => {
    if (context.selectedProject === null) {
      throw new Error("Unexpected error: no project selected");
    }

    // Create a new intent
    const intent: NluIntent = { name };

    // update the project
    const project = deepCopy(context.selectedProject);
    project.assets.intents.push(intent);

    // Send it to the API
    await createOrUpdateIntentAsync(project.name, intent);

    // TODO Recheck payloads
    const action: NLUReducerAction = {
      type: NLUActionType.createOrUpdateIntent,
      payload: project,
    };

    dispatch(action);
  };

  /**
   * Adds an utterance to the NLU App
   * @param text - The utterance text
   * @param parent - The parent intent's category
   * @param previousText - The previous text of the utterance, in case of an update
   */
  const createOrUpdateUtterance = async (text: string, parent: string, previousText?: string): Promise<void> => {
    if (context.selectedProject === null) {
      throw new Error("Unexpected error: No selected project");
    }

    // Make a copy for updating.
    const project = deepCopy(context.selectedProject);

    // check if an utterance with this text already exists for the parent intent
    const existingUtterance = context.selectedProject.assets.utterances.find(
      (utterance) => utterance.text === previousText && utterance.intent === parent,
    );

    /// if there is an existing utterance, update it.
    if (existingUtterance !== undefined) {
      const utterance: NluUtterance = { ...existingUtterance, text };

      project.assets.utterances = project.assets.utterances.map((target) => {
        if (target.text === previousText) {
          return utterance;
        } else {
          return target;
        }
      });

      // Send it to the API
      await createOrUpdateUtteranceAsync(project.name, utterance, previousText);
    } else {
      // Create a new utterance
      const utterance: NluUtterance = {
        intent: parent,
        text,
        entities: [],
      };

      project.assets.utterances.push(utterance);

      // Send it to the API
      await createOrUpdateUtteranceAsync(project.name, utterance, previousText);
    }

    const action: NLUReducerAction = {
      type: NLUActionType.createOrUpdateUtterance,
      payload: project,
    };

    dispatch(action);
  };

  /**
   * deploy the currently selected project.
   */
  const deploySelectedProject = async (): Promise<void> => {
    if (context.selectedProject === null) {
      throw new Error("Unexpected error: Missing name");
    }

    await deployProjectAsync(context.selectedProject.name);
  };

  /**
   * Get the utterances linked to the specified intent
   * @param name - The name of the intent
   */
  const getIntentSpecificUtterances = (name: string): Array<NluUtterance> => {
    if (context.selectedProject === null) return [];

    // Set up an array for the matching utterances.
    const utterances: Array<NluUtterance> = [];

    // Find the matching utterances and add them to the array.
    for (const utterance of context.selectedProject.assets.utterances) {
      if (utterance.intent === name) utterances.push(utterance);
    }

    return utterances;
  };

  /**
   * Train the currently selected project.
   */
  const trainSelectedProject = async (): Promise<void> => {
    if (context.selectedProject === null) {
      throw new Error("Unexpected error: No selected project");
    }

    await trainProjectAsync(context.selectedProject.name);
  };

  /**
   * Check NLU App training status
   * @returns true if training is complete, false if not
   */
  const getTrainingStatus = async (deploy: boolean = true): Promise<boolean> => {
    if (context.selectedProject === null) {
      throw new Error("Unexpected error: No selected project");
    }

    const result: Array<NluTrainingState> = await getTrainingJobsAsync(context.selectedProject.name);

    const succeed: boolean = result.find((x) => x.status !== "Succeeded") === undefined;

    if (succeed) {
      if (deploy) {
        await deploySelectedProject();
      }

      return false;
    } else {
      await delay(1000);

      return await getTrainingStatus();
    }
  };

  /**
   * Check NLU App training status
   * @returns true if training is complete, false if not
   */
  const getTrainingStatusOnCallInterval = async (deploy: boolean = true): Promise<boolean> => {
    if (context.selectedProject === null) {
      throw new Error("Unexpected error: No selected project");
    }

    const result: Array<NluTrainingState> = await getTrainingJobsAsync(context.selectedProject.name);

    const succeed: boolean = result.find((x) => x.status !== "Succeeded") === undefined;

    if (succeed) {
      if (deploy) {
        await deploySelectedProject();
      }

      return false;
    } else {
      return true;
    }
  };

  // TODO: not necessary to restore yet because we are still not supporting intent reservation
  // /**
  //  * Add intent to all NLU Apps.
  //  * @param intentName - the new intent name to add
  //  */
  // const addIntents = async (intentName: string, returnIntent: boolean = false): Promise<CLUIntent | undefined> => {
  //   if (context.client === null) return;

  //   for (const app of context.extractedApps!) {
  //     // Check whether the intent exists.
  //     const exists = app.intentsList!.some((intent) => intent.category.toLowerCase() === intentName.toLowerCase());

  //     // Add the intent if it doesn't exist or continue.
  //     if (!exists) {
  //       const projectName: string = app.appName!;
  //       await addIntentFunc(projectName, intentName);
  //     } else continue;
  //   }

  //   if (context.currentApp?.appName == null) {
  //     throw new Error("Unexpected error: Missing name");
  //   }
  //   const projectName: string = context.currentApp?.appName;
  //   const intentsResponse: CLUData = await getCLUExportFunc(projectName);

  //   const action: CLUReducerAction = {
  //     type: CLUActionType.updateIntentList,
  //     payload: intentsResponse.assets.intents,
  //   };

  //   dispatch(action);

  //   if (returnIntent) {
  //     const intentToReturn = intentsResponse.assets.intents.find((intent) => intent.category === intentName);
  //     if (intentToReturn !== undefined) {
  //       return intentToReturn;
  //     }
  //   }
  // };

  /**
   * Remove intent from NLU App
   * @param intentId - the intent id to remove
   */
  const removeIntent = async (name: string): Promise<void> => {
    if (context.selectedProject === null) {
      throw new Error("Unexpected error: No selected project");
    }

    // Update the project
    const project = deepCopy(context.selectedProject);
    project.assets.intents = project.assets.intents.filter((intent) => intent.name !== name);

    // Remove the intent
    await removeIntentAsync(project.name, name);

    const action: NLUReducerAction = {
      type: NLUActionType.removeIntent,
      payload: project,
    };

    dispatch(action);
  };

  /**
   * Removes an utterance from the NLU App
   * @param utterenceId - the utterence id to remove
   * @param intentName - the intent name to remove the utterence from
   */
  const removeUtterance = async (text: string): Promise<void> => {
    if (context.selectedProject === null) {
      throw new Error("Unexpected error: No selected project");
    }

    // Update the project
    const project = deepCopy(context.selectedProject);
    project.assets.utterances.filter((utterance) => utterance.text !== text);

    // Remove the utterance
    await removeUtteranceAsync(project.name, text);

    const action: NLUReducerAction = {
      type: NLUActionType.removeUtterance,
      payload: project,
    };

    dispatch(action);
  };

  /**
   * Sets the used intents for the NLU Context.
   * @param usedIntents - the used intents that we want to prevent being linked again
   */
  const setUsedIntents = async (): Promise<void> => {
    // get the used intents from blob storage
    const usedIntents = await getContainer(StorageEnvironment.Staging, "Intents");

    const action: NLUReducerAction = {
      type: NLUActionType.setUsedIntents,
      payload: usedIntents.blob.value,
    };

    dispatch(action);
  };

  /**
   * This function removes an intent and adds an intent to the Used Intent list in this context.
   * This function also updates the staging and production containers locally to automatically prep for live brengen.
   * @param intent - the intent specified for this update
   * @param remove - whether to remove or not, default "false"
   * Complete list of intents that are being used by a function.
   * Used by a dialog. Prevent from using one intent from multiple purposes
   */
  const updateUsedIntents = (intent: NluIntent, remove: boolean = false): void => {
    if (context.usedIntents === null || stagingContext === null) return;

    // Initialize an update
    let update: Array<NluIntent> = context.usedIntents;

    if (remove) {
      update = update.filter((used) => used.name !== intent.name);
    } else {
      update.push(intent);
    }

    // Update the used intents in the blob storage
    const usedIntentContainer: ApiBlobResponse = stagingContext.containers.find(
      (container) => container.key === ContainerNames.Intents,
    )!;

    if (usedIntentContainer === undefined) return;

    const usedIntentsBlob: ApiBlobResponse = {
      key: usedIntentContainer.key,
      blob: { value: update },
    };

    void postContainer(StorageEnvironment.Staging, usedIntentsBlob.blob.value, usedIntentsBlob.key);
    stagingContext?.updateContextWithContainer!(usedIntentsBlob);

    const action: NLUReducerAction = {
      type: NLUActionType.updateUsedIntents,
      payload: update,
    };

    dispatch(action);
  };

  const _context: NLUContextAttributes = {
    projects: context.projects,
    selectedProject: context.selectedProject,
    selectedIntent: context.selectedIntent,
    usedIntents: context.usedIntents,
    createOrUpdateIntent,
    createOrUpdateUtterance,
    deploySelectedProject,
    getTrainingStatus,
    getTrainingStatusOnCallInterval,
    removeIntent,
    removeUtterance,
    getIntentSpecificUtterances,
    setSelectedIntent,
    setSelectedProject,
    setUsedIntents,
    trainSelectedProject,
    updateUsedIntents,
  };

  return (
    <NLUContext.Provider value={_context}>
      <NLUDispatchContext.Provider value={dispatch}>{children}</NLUDispatchContext.Provider>
    </NLUContext.Provider>
  );
};

/**
 * Returns the current context
 */
export const useNLUContext = (): NLUContextAttributes | null => useContext(NLUContext);

/**
 * Returns the current dispatch
 */
export const useNLUDispatch = (): Dispatch<any> | null => useContext(NLUDispatchContext);

export type NLUReducerAction = {
  type: NLUActionType;
  payload?: Array<string> | Array<NluIntent> | NluProject | NluIntent | NluUtterance | string;
};

/**
 * Reducer for the NLU App Context
 * @param context - the current context
 * @param action - the action to perform
 * @returns the new context
 */
export const NLUReducer = (context: NLUContextAttributes, action: NLUReducerAction): NLUContextAttributes => {
  switch (action.type) {
    case NLUActionType.createOrUpdateIntent:
    case NLUActionType.createOrUpdateUtterance:
    case NLUActionType.removeIntent:
    case NLUActionType.removeUtterance:
    case NLUActionType.setSelectedProject:
      return {
        ...context,
        selectedProject: action.payload as NluProject,
      } satisfies NLUContextAttributes;
    case NLUActionType.setProjects:
      return {
        ...context,
        projects: action.payload as Array<string>,
      } satisfies NLUContextAttributes;
    case NLUActionType.setSelectedIntent:
      return {
        ...context,
        selectedIntent: action.payload as NluIntent,
      } satisfies NLUContextAttributes;
    case NLUActionType.setUsedIntents:
    case NLUActionType.updateUsedIntents:
      return {
        ...context,
        usedIntents: action.payload as Array<NluIntent>,
      } satisfies NLUContextAttributes;
    default:
      return context satisfies NLUContextAttributes;
  }
};

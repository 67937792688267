/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

// Auth Imports
import { webApiConfig } from "./ApiConfiguration";
import { getToken } from "./Auth/Auth";
import { SyncError } from "../components/KnowledgeSources/helpers";

enum SyncEndpoint {
  CheckLock = "/CheckLock",
  CheckStatus = "/CheckStatus",
}

/**
 * Generic function to setup an API call
 * @param endpoint - The endpoint to send the request to
 * @returns The response from the API
 */
export const genericSyncCall = async (endpoint: string = ""): Promise<AxiosResponse> => {
  try {
    // set the base endpoint for file uploads
    const url = `${webApiConfig.endpointUrl}/Synchronize${endpoint}`;
    const token = await getToken();

    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
      "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
    };

    const requestConfig: AxiosRequestConfig = {
      headers,
      signal: new AbortController().signal,
    };

    return await axios.post(url, {}, requestConfig);
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 409) {
        throw new SyncError("Synchronization is locked");
      }
      throw new Error(
        `API call failed, error message: ${error.message}, request url: ${error.config?.url}, info: ${error.response?.data.title}`,
      );
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Start a synchronization operation to update data
 */
export const synchronize = async (): Promise<void> => {
  await genericSyncCall();
};

/**
 * Start a synchronization operation to update data
 */
export const checkLock = async (): Promise<void> => {
  await genericSyncCall(SyncEndpoint.CheckLock);
};

/**
 * Start a synchronization operation to update data
 */
export const checkStatus = (): void => {
  void genericSyncCall(SyncEndpoint.CheckStatus);
};

import { useEffect, FC } from "react";

interface ExternalScriptProps {
  src: string;
}

/**
 * Component that loads an external script into the DOM
 * @param src - The source URL of the script to load
 */
const ExternalScript: FC<ExternalScriptProps> = ({ src }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = src;
    script.id = "webchat-script"
    script.async = true;
    document.head.appendChild(script);

    const inlineScript = document.createElement("script");
    inlineScript.innerHTML = `
      window.chatStyle = {
        floatLeft: "true",
      };
    `;
    document.head.appendChild(inlineScript);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(inlineScript);
    };
  }, [src]);

  return null;
};

export default ExternalScript;

import React, { FC, memo, useState, useEffect } from "react";

// Package Imports
import moment from "moment";
import { deepCopy } from "../../helpers/deepCopy";

// Component Imports
import ConfirmationDialog, { ConfirmationDialogProps } from "../Dialogs/ConfirmationDialog/ConfirmationDialog";
import CreateHighlightDialog from "../Dialogs/NewEntryDialogs/CreateHighlightDialog";
import CreateAnswerDialog from "../Dialogs/NewEntryDialogs/CreateAnswerDialog";

// MUI Imports
import HistoryIcon from "@mui/icons-material/History";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { Box, Grid } from "@mui/material";

// Data Imports
import { BlobFromApi, createItem, StorageEnvironment, updateItem } from "../../API/StorageInteraction";
import { ChangeOrigin, ChangeType, TrackedChange } from "../../models/ChangeTracking";
import { NluIntent } from "../../models/NLU";
import { ContainerNames } from "../../models/enums";

// Context Imports
import {
  localStorageContextAttributes,
  useLocalStorageContext,
} from "../../contexts/ChangeTracking/LocalStorageContext";
import {
  externalStorageContextAttributes,
  useExternalStorageContext,
} from "../../contexts/ChangeTracking/ExternalStorageContext";
import { useNLUContext } from "../../contexts/NLU/NLUContext";

// Style Imports
import "../../styles/PageLayout.css";
import { GridRowSaveOption } from "../../models/models";

declare module "@mui/x-data-grid" {
  interface FooterPropsOverrides {
    executable: (update: any) => void;
  }
}

interface CustomDataGridProps {
  onUpdate: (update: BlobFromApi) => void;
  blobData: BlobFromApi;
  productionBlobData: BlobFromApi;
  columns: Array<GridColDef>;
  containerName: ContainerNames;
  language: string;
  isDeletable?: boolean;
  isRevertable?: boolean;
  customUpdateHandler?: (updatedRow: GridValidRowModel) => GridValidRowModel;
  customCreateRowHandler?: (newRow: GridValidRowModel) => GridValidRowModel;
}

/**
 * Modular Datagrid component
 * @param onUpdate - The function to be called when the data is updated.
 * @param blobData - The data to be displayed in the grid.
 * @param productionBlobData - The production data to be displayed in the grid.
 * @param columns - The columns to be displayed in the grid.
 * @param containerName - The name of the container to be displayed in the grid.
 * @param language - The language of the container to be displayed in the grid.
 * @param isDeletable - Whether the row item is deletable.
 * @param isRevertable - Whether the row item is revertable.
 * @param customUpdateHandler - A custom handler for updating the data.
 * @param customCreateRowHandler - A custom handler for creating a new row.
 */
const CustomDataGrid: FC<CustomDataGridProps> = ({
  onUpdate,
  blobData,
  productionBlobData,
  columns,
  containerName,
  language,
  isDeletable,
  isRevertable,
  customCreateRowHandler,
  customUpdateHandler,
}) => {
  const [data, setData] = useState<Array<GridRowModel>>(blobData.value);
  const [displayData, setDisplayData] = useState<Array<GridRowModel>>([]);
  const [confirmProps, setConfirmProps] = useState<ConfirmationDialogProps>();

  // Data Grid states
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rowSaveOption, setRowSaveOption] = useState<GridRowSaveOption | null>();

  // Context
  const localStorageContext = useLocalStorageContext();
  const externalStorageContext = useExternalStorageContext();
  const CLUContext = useNLUContext();

  const columnsCopy = [...columns];

  // #region Grid Edit actions
  /**
   * Handles the row edit stop event.
   */
  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params): void => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      setRowSaveOption(GridRowSaveOption.LocalSave);
    }
  };

  /**
   * Handles cancel click on edit
   */
  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  /**
   * Handles the row model change event.
   */
  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel): void => {
    setRowModesModel(newRowModesModel);
  };

  /**
   * Handles the row edit click event.
   */
  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  /**
   * Handles the save click event during row edit.
   */
  const handleSaveClick = (id: GridRowId) => () => {
    setRowSaveOption(GridRowSaveOption.StagingSave);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  // #endregion

  columnsCopy.push({
    field: "actions",
    headerName: "Acties",
    type: "actions",
    width: 150,
    hideable: false,

    /**
     * Returns an array of React elements representing action items for a grid cell.
     * @param params - An object containing information about the row and other parameters.
     * @returns An array of React elements representing action items for the grid cell.
     */
    getActions: ({ id }) => {
      const actions: Array<JSX.Element> = [];

      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      if (isInEditMode) {
        actions.push(
          <GridActionsCellItem key={`${id}_save`} icon={<SaveIcon />} label="Opslaan" onClick={handleSaveClick(id)} />,
        );
        actions.push(
          <GridActionsCellItem
            key={`${id}_cancel`}
            icon={<CloseIcon />}
            label="Sluit"
            onClick={handleCancelClick(id)}
          />,
        );

        return actions;
      }

      actions.push(
        <GridActionsCellItem key={`${id}_edit`} icon={<EditIcon />} label="Bewerken" onClick={handleEditClick(id)} />,
      );

      if (isRevertable !== false) {
        actions.push(
          <GridActionsCellItem key={`${id}_revert`} icon={<HistoryIcon />} label="Terugdraai" onClick={onRevert(id)} />,
        );
      }

      if (isDeletable !== false) {
        actions.push(
          <GridActionsCellItem
            key={`${id}_delete`}
            icon={<DeleteIcon />}
            label="Verwijderen"
            onClick={onDeleteItem(id)}
          />,
        );
      }

      return actions;
    },
  });

  useEffect(() => {
    setData(blobData.value);
  }, [blobData]);

  useEffect(() => {
    formatText();
  }, [data, localStorageContext, externalStorageContext]);

  /**
   * Adds a new item to the data list and updates the component state and blob data with the new data.
   * @param update - The new item data to be added.
   */
  const onAddItem = (update: GridRowModel, intent?: NluIntent | null): void => {
    if (customCreateRowHandler != null) {
      // Process the new item using a custom create handler
      update = customCreateRowHandler(update);
    }

    createItem(StorageEnvironment.Staging, containerName, update).catch((error) => {
      throw new Error(`Custom Datagrid Item Add error: ${error}`);
    });

    // Create a new array by appending the new item data to the current data
    const nextData: Array<GridRowModel> = [...data, update];

    // Update the component state with the new data
    setData(nextData);

    // Create a new tracked change
    if (externalStorageContext !== null) {
      // Add a sort order based on the item's keys.
      const keys: Array<string> = [];
      for (const key of Object.keys(update)) {
        keys.push(key);
      }

      const newTrackedChange: TrackedChange = {
        key: `change-${containerName}-${update.key}`,
        origin: {
          containerName,
          fileName: `${containerName}.json`,
        },
        newValue: update,
        lastModified: moment().toDate(),
        changeType: ChangeType.New,
        live: false,
        displayOrder: keys,
      };

      externalStorageContext.trackChange!(newTrackedChange);
    }

    // Create a new BlobData object with the updated data and other relevant properties
    const newBlobData: BlobFromApi = {
      value: nextData,
    };

    if (intent !== undefined && intent !== null) {
      CLUContext?.updateUsedIntents!(intent, false);
    }

    // Call the 'onUpdate' function from props to update the parent component's data with the new blob data
    onUpdate(newBlobData);
  };

  /**
   * Deletes an item with the given key from the data list and updates the component state and blob data with the new data.
   * @param key - The unique key of the item to be deleted.
   */
  const onDeleteItem = (key: GridRowId) => () => {
    /**
     * Create a callback function for confirming the deletion
     */
    const confirmCallback = (): void => {
      // Create a copy of the current data
      const nextData: Array<GridRowModel> = [...data];

      // Find the index of the item with the given key
      const dataIndex = nextData.findIndex((item) => item.key === key);

      // Add a sort order based on the item's keys.
      const keys: Array<string> = [];
      for (const key of Object.keys(nextData[dataIndex])) {
        keys.push(key);
      }

      // Create a new tracked change
      if (localStorageContext !== null && externalStorageContext !== null) {
        const newTrackedChange: TrackedChange = {
          key: `change-${containerName}-${nextData[dataIndex].key}`,
          origin: {
            containerName,
            fileName: `${containerName}.json`,
          },
          oldValue: nextData[dataIndex],
          newValue: null,
          lastModified: moment().toDate(),
          changeType: ChangeType.Delete,
          live: false,
          displayOrder: keys,
        };

        // If the change exists, a newly made item is deleted,
        // otherwise a previously existing item is deleted.
        if (localStorageContext.trackedChangeExists!(newTrackedChange)) {
          localStorageContext.updateChange!(newTrackedChange);
        } else {
          localStorageContext.trackChange!(newTrackedChange);
        }
      }

      // If the item exists in the data list, remove it
      if (dataIndex > -1) {
        nextData.splice(dataIndex, 1);
      }

      setData(nextData);

      // Create a new BlobData object with the updated data and other relevant properties
      const newBlobData: BlobFromApi = {
        value: nextData,
      };

      // Call the 'onUpdate' function from props to update the parent component's data with the new blob data
      onUpdate(newBlobData);
    };

    // Create the props for the confirmation dialog to ask for user confirmation before deleting
    const confirmProps: ConfirmationDialogProps = {
      open: true,
      handleClose: handleConfirmClose,
      title: "Bevestiging Verwijderen",
      button1Text: "Akkoord",
      button2Text: "Annuleer",
      description: "Weet je zeker dat je dit wilt verwijderen?",
      executable: confirmCallback,
    };

    // Show the confirmation dialog by setting the props for it
    setConfirmProps(confirmProps);
  };

  /**
   * Updates the data list with a new row data and the component state and blob data with the updated data.
   * @param newRow - The new data for the row to be updated.
   * @param oldRow - The previous data for the row to be updated.
   * @returns The updated row data.
   */
  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel): GridValidRowModel => {
    if (newRow === oldRow) {
      return oldRow;
    }

    // Update the blob data
    let updatedBlobRow = deepCopy(newRow);

    if (customUpdateHandler != null) {
      // Process the updated row using a custom update handler
      updatedBlobRow = customUpdateHandler(updatedBlobRow);
    }

    // Create a new array by updating the row with the new data
    const nextData = data.map((member: GridRowModel) => (member.key === oldRow.key ? updatedBlobRow : member));

    // Update the component state with the updated data
    setData(nextData);

    // Create a new BlobData object with the updated data and other relevant properties
    const newBlobData: BlobFromApi = {
      value: nextData,
    };

    // Call the 'onUpdate' function from props to update the parent component's data with the new blob data
    onUpdate(newBlobData);

    const keys: Array<string> = [];
    for (const key of Object.keys(updatedBlobRow)) {
      keys.push(key);
    }

    // Since we are updating, retrieve existing change.
    const changeKey: string = `change-${containerName}-${oldRow.key}`;
    const changeOrigin: ChangeOrigin = {
      containerName,
      fileName: `${containerName}.json`,
    };

    switch (rowSaveOption) {
      case GridRowSaveOption.StagingSave: {
        if (externalStorageContext === null) break;

        updateItem(StorageEnvironment.Staging, containerName, oldRow.key, updatedBlobRow, true).catch((error) => {
          throw new Error(`Custom Datagrid Update error: ${error}`);
        });

        const storageContext = externalStorageContext;
        updateContextOnChange(storageContext, changeKey, changeOrigin, updatedBlobRow, keys, oldRow);

        // const changeBucketToPersist: Array<ChangeBucket> = externalStorageContext.changeBuckets.filter(
        //   (bucket) => bucket.key === containerName,
        // )!;

        // externalStorageContext.persistChanges!(changeBucketToPersist, true).catch((error) => {
        //   throw new Error(`Custom Datagrid Update error: ${error}`);
        // });
        break;
      }
      case GridRowSaveOption.LocalSave: {
        if (localStorageContext === null) break;

        const storageContext = localStorageContext;
        updateContextOnChange(storageContext, changeKey, changeOrigin, updatedBlobRow, keys, oldRow);
        break;
      }
      default:
        break;
    }

    // Return the updated row data (optional, in case it is needed elsewhere)
    return updatedBlobRow;
  };

  /**
   * Update change bucket
   */
  const updateContextOnChange = (
    context: localStorageContextAttributes | externalStorageContextAttributes,
    changeKey: string,
    changeOrigin: ChangeOrigin,
    updatedBlobRow: GridRowModel,
    keys: Array<string>,
    oldRow: GridRowModel,
  ): void => {
    // Retrieve a previous update for this item and modify it, or create a new change if did not exist.
    const trackedChangeResult = context.getChangeByIdInOrigin!(changeKey, changeOrigin);
    if (trackedChangeResult === undefined) {
      // Build a new change.
      const newTrackedChange: TrackedChange = {
        key: changeKey,
        origin: changeOrigin,
        oldValue: oldRow,
        newValue: updatedBlobRow,
        lastModified: moment().toDate(),
        changeType: ChangeType.Update,
        live: false,
        displayOrder: keys,
      };

      context.trackChange!(newTrackedChange);
    } else {
      // Modify the change parameters.
      const updateTrackedChange = {
        ...trackedChangeResult,
        newValue: updatedBlobRow,
        lastModified: moment().toDate(),
        displayOrder: keys,
      };

      context.updateChange!(updateTrackedChange);
    }
  };

  /**
   * Reverts all items to the previous state and updates the component state and blob data with the reverted data.
   * This function is typically used to revert all changes made to the data.
   */
  // const onRevertAll = () => () => {
  //   // Create a copy of the staging blob data (data in the staging area)
  //   const stagingData = { ...blobData };

  //   /**
  //    * Create a callback function for confirming the revert
  //    */
  //   const confirmCallback = (): void => {
  //     // Check if both production and staging data exist
  //     if (productionBlobData != null && stagingData !== null) {
  //       // Update the staging data with the production data
  //       stagingData.value = productionBlobData.value;

  //       /**
  //        * Fetches the data and updates the component state with the reverted data.
  //        */
  //       const fetchData = async (): Promise<void> => {
  //         const stagingDataToArray: Array<any> = stagingData.value as Array<any>;

  //         if (stagingDataToArray !== data) {
  //           setData(stagingDataToArray);
  //         }
  //       };

  //       // Fetch the data and handle any errors (if necessary)
  //       fetchData().catch((error) => {
  //         throw new Error(`Custom Datagrid Fetch error: ${error}`);
  //       });

  //       // Create a new BlobData object with the updated data and other relevant properties
  //       const newBlobData: BlobFromApi = {
  //         value: stagingData.value,
  //       };

  //       // Call the 'onUpdate' function from props to update the parent component's data with the new blob data
  //       onUpdate(newBlobData);
  //     }
  //   };

  //   // Create the props for the confirmation dialog to ask for user confirmation before reverting all changes
  //   const confirmProps: ConfirmationDialogProps = {
  //     open: true,
  //     handleClose: handleConfirmClose,
  //     title: "Bevestiging Terugdraaien",
  //     button1Text: "Akkoord",
  //     button2Text: "Annuleer",
  //     description: "Weet je zeker dat je al deze items wilt terugdraaien?",
  //     executable: confirmCallback,
  //   };

  //   // Show the confirmation dialog by setting the props for it
  //   setConfirmProps(confirmProps);
  // };

  /**
   * Reverts an individual item with the given key to the previous state.
   * This function is typically used to revert a single item to its original state.
   *
   * @param gridRowId - The unique key of the item to be reverted.
   * @returns A callback function to confirm the revert action.
   */
  const onRevert = (gridRowId: GridRowId) => () => {
    // Create a copy of the current data
    const nextData: Array<GridRowModel> = [...data];

    /**
     * Create a callback function for confirming the revert
     */
    const confirmCallback = (): void => {
      // Check if the production data exists
      if (productionBlobData != null) {
        // Find the entry to revert in the production data
        const productionDataToArray: Array<GridRowModel> = productionBlobData.value;
        const specificProductionEntry: GridRowModel | undefined = productionDataToArray.find(
          (item) => item.key === gridRowId,
        );
        const specificDataEntry: GridRowModel | undefined = data.find((item) => item.key === gridRowId);

        if (specificProductionEntry !== undefined && specificDataEntry !== undefined) {
          // Find the index of the entry to revert in the staging data
          const dataIndex = nextData.findIndex((item) => item.key === gridRowId);

          // Replace the entry in the staging data with a clone of the entry from the production data
          nextData[dataIndex] = JSON.parse(JSON.stringify(specificProductionEntry));

          // Update the component state with the updated data (item reverted)
          setData(nextData);
        }

        // Create a new BlobData object with the updated data and other relevant properties
        const newBlobData: BlobFromApi = {
          value: nextData,
        };

        // Call the 'onUpdate' function from props to update the parent component's data with the new blob data
        onUpdate(newBlobData);
      }
    };

    const confirmProps: ConfirmationDialogProps = {
      open: true,
      handleClose: handleConfirmClose,
      title: "Bevestiging Terugdraaien",
      button1Text: "Akkoord",
      button2Text: "Annuleer",
      description: "Weet je zeker dat je dit item wilt terugdraaien?",
      executable: confirmCallback,
    };

    // Show the confirmation dialog by setting the props for it
    setConfirmProps(confirmProps);
  };

  /**
   * Format text based on if it is a change or not
   */
  const formatText = (): void => {
    const nextDisplayData = deepCopy(data);

    /**
     * Reusable function to format based on the storage context.
     * @param storageContext - The storage context to apply changes from.
     */
    const applyChanges = (storageContext: localStorageContextAttributes | externalStorageContextAttributes): void => {
      if (storageContext !== null) {
        const changes = storageContext.changeBuckets.find((bucket) => bucket.key === containerName);
        for (const change of changes?.changes ?? []) {
          if (change.changeType !== ChangeType.Delete) {
            const changeKey = change.newValue.key;

            const dataIndex = data.findIndex((item) => item.key === changeKey);
            if (dataIndex > -1) {
              nextDisplayData[dataIndex].status = "edited";
            }
          }
        }
      }
    };
    if (localStorageContext !== null) {
      applyChanges(localStorageContext);
    }
    if (externalStorageContext !== null) {
      applyChanges(externalStorageContext);
    }

    setDisplayData(nextDisplayData);
  };

  /**
   * Returns a string representing the class name for a row.
   * @param params - An object containing information about the row and other parameters.
   * @returns returns row bold if element has been edited. Otherwise returns empty string.
   */
  const getRowClassName = (params: GridRowModel): string => (params.row.status === "edited" ? "bold-row" : "");

  /**
   * Closes the confirmation dialog by setting the 'open' prop to 'false' and resetting other props.
   */
  const handleConfirmClose = (): void => {
    const confirmProps: ConfirmationDialogProps = {
      open: false,

      /**
       * Handles the close event of the confirmation dialog.
       * @returns void
       */
      handleClose: () => {},
      title: "",
      button1Text: "",
      button2Text: "",
      description: "",

      /**
       * Executes the function passed as a prop to the confirmation dialog.
       * @returns void
       */
      executable: () => {},
    };

    setConfirmProps(confirmProps);
  };

  /**
   * Renders a custom dialog based on the 'containerName' prop.
   * This function is used to conditionally render different dialogs based on the 'containerName'.
   *
   * @returns A JSX element representing the custom dialog.
   */
  const RenderCustomDialog = (): JSX.Element => {
    switch (containerName) {
      case ContainerNames.Highlights:
        return <CreateHighlightDialog executable={onAddItem} language={language} />;
      case ContainerNames.Answers:
        return <CreateAnswerDialog executable={onAddItem} language={language} />;

      default:
        return <></>;
    }
  };

  return (
    <Box height={"100%"}>
      {confirmProps != null ? <ConfirmationDialog {...confirmProps} /> : null}
      <Grid container height={"100%"}>
        <Grid item xs={12}>
          <RenderCustomDialog />
        </Grid>
        <Grid item xs={12} height={"94%"}>
          <DataGrid
            columns={columnsCopy}
            rows={displayData}
            getRowClassName={getRowClassName}
            rowHeight={52}
            getRowId={(row: GridRowModel) => row.key}
            editMode="row"
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={(error) => {
              throw new Error(error);
            }}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            keepNonExistentRowsSelected
            initialState={{
              sorting: {
                sortModel: [{ field: "key", sort: "asc" }],
              },
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            localeText={{
              MuiTablePagination: {
                labelRowsPerPage: `Rijen per pagina:`,

                /**
                 * Display the pagination text as we wish.
                 */
                labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} van ${count}`,
              },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(CustomDataGrid);

import React, { FC } from "react";

/**
 * Component that renders the webchat iframe
 * @param webchatUrl - The URL of the webchat
 */
const Webchat: FC = () => {
  return <div id="webchat-container" />;
};

export default Webchat;

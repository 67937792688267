import React, { ReactElement } from "react";

// MUI Imports
import {
  GridActionsCellItem,
  GridColDef,
  GridRowModel,
  GridValueFormatterParams,
  renderBooleanCell,
  renderEditBooleanCell,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { DEFlagIcon, FRFlagIcon, GBFlagIcon, NLFlagIcon } from "../components/Icons/FlagIcons";

// Model Imports
import { messageTextType } from "./models";
import { Language, LanguageText } from "./Language";
import { ContainerNames } from "./enums";

// Component Imports
import CustomToolTip from "../components/CustomToolTip";

// Import Custom Data Grid Components
import {
  renderCustomDateCell,
  renderCustomDateEditCell,
} from "../components/CustomDataGrid/DataGridComponents/RenderDateTimePicker";
import { renderSelectMessageType } from "../components/CustomDataGrid/DataGridComponents/RenderSelectMesssageType";

/**
 * Gets the dynamic columns for the editor
 * @param mainLanguage - The main language of the editor
 * @param languages - The languages of the editor
 * @param setIsDialogOpen - The setIsDialogOpen function of the editor
 * @param setDialogNumber - The setDialogNumber function of the editor
 * @param setDialogLanguage - The setDialogLanguage function of the editor
 * @param setDialogValue - The setDialogValue function of the editor
 * @param setDialogIsEdit - The setDialogIsEdit function of the editor
 * @param setDialogTitle - The setDialogTitle function of the editor
 * @param setDialogLanguages - The setDialogLanguages function of the editor
 * @param editorType - The type of the editor (text answer or highlights)
 * @returns The columns for the editor
 */
export const getEditorColumns = (
  mainLanguage: Language,
  languages: Array<Language>,
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setDialogNumber: React.Dispatch<React.SetStateAction<string>>,
  setDialogLanguage: React.Dispatch<React.SetStateAction<string>>,
  setDialogValue: React.Dispatch<React.SetStateAction<string>>,
  setDialogIsEdit: React.Dispatch<React.SetStateAction<boolean>>,
  setDialogTitle: React.Dispatch<React.SetStateAction<string>>,
  setDialogLanguages: React.Dispatch<React.SetStateAction<Array<Language>>>,
  editorType: ContainerNames.Answers | ContainerNames.Highlights | ContainerNames.GroupIncidents,
): Array<GridColDef> => {
  const commonColumns: Array<GridColDef> = [
    {
      field: "key",
      headerName: "Onderwerp",
      type: "text",
      minWidth: 200,
      hideable: false,
      editable: false,
    },
    {
      field: "value",
      headerName: "Beschrijving",
      type: "text",
      flex: 1,
      minWidth: 400,
      hideable: false,
      editable: true,
      /**
       * Gets the value of the main language from the languages array
       * @param params - The row parameters
       * @returns The value of the main language
       */
      valueGetter: ({ row }: GridRowModel) => {
        if (row.languages.length > 0) {
          const languageIndex: number = row.languages.findIndex((lang: LanguageText) => lang.key === mainLanguage.key);
          if (languageIndex !== -1 && row.languages[languageIndex].value !== "") {
            return row.languages[languageIndex].value;
          }
        }

        return "";
      },
    },
    {
      flex: 1,
      maxWidth: 250,
      minWidth: 100,
      field: "andereTalen",
      headerName: "Andere Talen",
      type: "actions",
      /**
       * Gets the actions for the row
       * @param params - The row parameters
       * @returns The actions for the row
       */
      getActions: ({ row }: GridRowModel) => {
        const actions: Array<ReactElement> = [];

        /**
         * Function to create the action for the language specified
         * @param languageKey - The key of the language
         * @param title - The title of the language
         * @param icon - The icon of the language
         * @returns The actions flags for language
         */
        const createAction = (languageKey: string, title: string, icon: any): JSX.Element => (
          <GridActionsCellItem
            key={languageKey}
            icon={<CustomToolTip title={title} child={icon} />}
            label={`${languageKey}-text`}
            onClick={() => {
              setIsDialogOpen(true);
              setDialogNumber(row.key);
              setDialogLanguage(languageKey);
              setDialogIsEdit(true);

              const languageValue = row.languages.find((language: LanguageText) => language.key === languageKey)?.value;

              setDialogValue(languageValue);
              setDialogTitle(`Wijzig ${title} tekst`);
            }}
          />
        );

        if (
          row.languages !== null &&
          row.languages !== undefined &&
          languages.some((language) => row.languages.some((lang: LanguageText) => lang.key === language.key) === false)
        ) {
          actions.push(
            <GridActionsCellItem
              key={`${row.key}`}
              icon={<CustomToolTip title="Voeg een nieuwe taal toe" child={<AddIcon />} />}
              label="add-new-language"
              onClick={() => {
                setIsDialogOpen(true);
                setDialogNumber(row.key);
                setDialogLanguage("");
                setDialogValue("");
                setDialogIsEdit(false);
                setDialogTitle("Voeg een nieuwe taal toe");
                setDialogLanguages(
                  languages.filter(
                    (language) => row.languages.some((lang: LanguageText) => lang.key === language.key) === false,
                  ),
                );
              }}
            />,
          );
        }

        languages.forEach((language) => {
          if (
            language.key !== mainLanguage.key &&
            row.languages.some((lang: LanguageText) => lang.key === language.key) === true
          ) {
            actions.push(createAction(language.key, language.name, getFlagIcon(language.key)));
          }
        });

        return actions;
      },
    },
  ];

  /**
   * Helper function to get the flag icon based on the language key
   * @param languageKey - The key of the language
   * @returns The flag icon for the language
   */
  const getFlagIcon = (languageKey: string): ReactElement => {
    switch (languageKey) {
      case "NL":
        return <NLFlagIcon />;
      case "EN":
        return <GBFlagIcon />;
      case "DE":
        return <DEFlagIcon />;
      case "FR":
        return <FRFlagIcon />;
      default:
        return <></>;
    }
  };

  switch (editorType) {
    case ContainerNames.Answers:
      return [
        ...commonColumns,
        {
          field: "type",
          headerName: "Type",
          type: "text",
          width: 150,
          editable: true,
          renderEditCell: renderSelectMessageType,

          /**
           * Gets the correct display text for the message type
           */
          valueFormatter: (params: GridValueFormatterParams<any>): string | undefined => {
            const type = messageTextType.find((item) => item.key === params.value);

            return type?.value;
          },
        },
      ];
    case ContainerNames.Highlights:
      return [
        ...commonColumns,
        {
          field: "timeStampFrom",
          headerName: "Periode van",
          type: "text",
          width: 200,
          editable: true,
          filterable: false,
          renderCell: renderCustomDateCell,
          renderEditCell: renderCustomDateEditCell,
        },
        {
          field: "timeStampTo",
          headerName: "Periode tot",
          type: "text",
          width: 200,
          editable: true,
          filterable: false,
          renderCell: renderCustomDateCell,
          renderEditCell: renderCustomDateEditCell,
        },
        {
          field: "repeat",
          headerName: "Herhalen",
          type: "boolean",
          width: 125,
          editable: true,
          renderCell: renderBooleanCell,
          renderEditCell: renderEditBooleanCell,
        },
        {
          field: "timeSpan",
          headerName: "Interval",
          type: "number",
          width: 125,
          editable: true,
        },
        {
          field: "enabled",
          headerName: "Ingeschakeld",
          type: "boolean",
          width: 125,
          editable: true,
          renderCell: renderBooleanCell,
          renderEditCell: renderEditBooleanCell,
        },
      ];
    case ContainerNames.GroupIncidents:
      return [
        ...commonColumns,
        {
          field: "enabled",
          headerName: "Ingeschakeld",
          type: "boolean",
          width: 125,
          editable: true,
          renderCell: renderBooleanCell,
          renderEditCell: renderEditBooleanCell,
        },
      ];
    default:
      return commonColumns;
  }
};

import React, { FC } from "react";
import { Grid, Skeleton } from "@mui/material";

/**
 * SkeletonLoader Component
 * @returns A react component to indicate that the page is loading.
 */
const SkeletonLoader: FC = () => (
  <>
    <Grid container spacing={2} columnSpacing={2}>
      <Grid item xs={2}>
        <Skeleton variant="rounded" width={110} height={36} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rounded" height="50vh" />
      </Grid>
    </Grid>
  </>
);

export default SkeletonLoader;

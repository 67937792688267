import React, { FC, useState, useEffect, useCallback } from "react";

// Router
import Header from "../../components/Headers/Header";

// Component Imports
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import ChangesToSave from "../../components/Save/ChangesToSave";
import FlyoutDrawer from "../../components/FlyoutDrawer/FlyoutDrawer";
import { Box, Button, Stack } from "@mui/material";

// Context Imports
import { useStagingContext } from "../../contexts/StagingContext";
import { useProductionContext } from "../../contexts/ProductionContext";
import { useLocalStorageContext } from "../../contexts/ChangeTracking/LocalStorageContext";
import { useExternalStorageContext } from "../../contexts/ChangeTracking/ExternalStorageContext";

// Chatbot Window Import
// Import Webchat from "../../components/Webchat/Webchat";

// Css
import "./Home.css";

// Drawer Imports
import { styled } from "@mui/material/styles";
import Routing from "../../components/Routing/Routing";
import { DrawerHeader } from "../../components/FlyoutDrawer/DrawerHeaderSpacer";
import Webchat from "../../components/Webchat/Webchat";

const drawerWidth = 240;

/**
 * Renders the home page of the application
 * @returns A React Function Component
 */
const Home: FC = () => {
  // Contexts
  const stagingContext = useStagingContext();
  const productionContext = useProductionContext();
  const localStorageContext = useLocalStorageContext();
  const externalStorageContext = useExternalStorageContext();

  // State
  const [confirmLiveOpen, setConfirmLiveOpen] = useState<boolean>(false);
  const [confirmSaveOpen, setConfirmSaveOpen] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Const [chatBotOpen, setChatBotOpen] = useState<boolean>(true);

  useEffect(() => {
    if (stagingContext === null) return;
    if (stagingContext.containers !== undefined || stagingContext.containers !== null) {
      setIsLoading(false);
    }
  }, []);

  /**
   * This function gets the current live data and change tracking for live.
   */
  const getCurrentCurrentDataStates = async (): Promise<void> => {
    // Live data update
    productionContext?.setContainers!().catch((error) => {
      throw new Error(`Error getting changes from production: ${error}`);
    });

    // Staging data update
    stagingContext?.setContainers!().catch((error) => {
      throw new Error(`Error getting changes from staging: ${error}`);
    });

    externalStorageContext?.getChanges!().catch((error) => {
      throw new Error(`Error getting changes from external storage: ${error}`);
    });
  };

  /**
   * This function handles the opening of the drawer
   * @returns void
   */
  const handleDrawerOpen = useCallback(() => {
    setIsDrawerOpen((opened: boolean) => !opened);
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <FlyoutDrawer drawerWidth={drawerWidth} drawerOpen={isDrawerOpen} isLoading={isLoading} />
      <Header authenticated={true} isDrawerOpen={isDrawerOpen} handleDrawerOpen={handleDrawerOpen} />
      <FlexContent open={isDrawerOpen}>
        <DrawerHeader />
        <Stack height="90%">
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              onClick={() => {
                setConfirmSaveOpen(true);
                getCurrentCurrentDataStates().catch((error) => {
                  throw new Error(`Error getting changes: ${error}`);
                });
              }}
              variant="contained"
              color="primary"
            >
              Opslaan
            </Button>
            <Button
              onClick={() => {
                setConfirmLiveOpen(true);
                getCurrentCurrentDataStates().catch((error) => {
                  throw new Error(`Error getting changes: ${error}`);
                });
              }}
              variant="contained"
              color="secondary"
            >
              Live Brengen
            </Button>
          </Stack>
          <Routing />
        </Stack>
      </FlexContent>

      <ConfirmationDialog
        executable={() => {
          if (localStorageContext !== null) void localStorageContext.saveChanges!();
        }}
        title="Opslaan naar staging"
        bodyContent={<ChangesToSave key={"change-management-staging"} context={localStorageContext} />}
        button1Text="Opslaan"
        button2Text="Annuleren"
        fullWidth={true}
        handleClose={() => {
          setConfirmSaveOpen(false);
        }}
        open={confirmSaveOpen}
      />
      <ConfirmationDialog
        executable={() => {
          if (externalStorageContext !== null) void externalStorageContext.saveChanges!();
        }}
        title="Live brengen"
        bodyContent={<ChangesToSave key={"change-management-live"} context={externalStorageContext} />}
        button1Text="Live brengen"
        button2Text="Annuleren"
        fullWidth={true}
        handleClose={() => {
          setConfirmLiveOpen(false);
        }}
        open={confirmLiveOpen}
      />
    </Box>
  );
};

export default Home;

/**
 * FlexContent JSX style for page to respond to drawer open state
 */
const FlexContent = styled("div", {
  /**
   * is the prop it will forward
   */
  shouldForwardProp: (prop) => prop !== "open",
})<{ open?: boolean }>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - ${drawerWidth}px)`,
  marginLeft: `-${drawerWidth}px`,
  ...(open === true && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

import React, { FC, useEffect, useState } from "react";

// Component Imports
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Divider,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";

// Data Imports
import { NluUtterance } from "../../../../models/NLU";

// Context Imports
import { useNLUContext } from "../../../../contexts/NLU/NLUContext";

interface UtteranceListItemProps {
  utterance: NluUtterance;
  index: number;
}

/**
 * An utterance list item.
 * @param utterance - The utterance that will be displayed in this list item.
 */
const UtteranceListItem: FC<UtteranceListItemProps> = ({ utterance, index }: UtteranceListItemProps) => {
  // Context
  const CLUContext = useNLUContext();

  useEffect(() => {
    setEditing(false);
  }, [CLUContext?.selectedIntent]);

  // State
  const [update, setUpdate] = useState<string>(utterance.text);
  const [isEditing, setEditing] = useState<boolean>(false);

  /**
   * Handle the editing state
   */
  const handleEditing = (): void => {
    setUpdate(utterance.text);
    setEditing(!isEditing);
  };

  /**
   * Handle the user's action of editing the value.
   * @param event - The event that triggered the function.
   */
  const handleEditAction = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setUpdate(event.target.value);
  };

  /**
   * Handle the result of the user saving the edit.
   */
  const handleEditResult = (): void => {
    if (CLUContext === null) return;

    void CLUContext.createOrUpdateUtterance!(update, utterance.intent, utterance.text);

    setEditing(!isEditing);
  };

  /**
   * Handle the deleting of an utterance.
   */
  const handleDelete = (): void => {
    if (CLUContext === null) return;

    void CLUContext.removeUtterance!(utterance.text);
  };

  return (
    <ListItem key={`utterance-${index}`}>
      {isEditing ? (
        <>
          <TextField value={update} onChange={handleEditAction} />
          <ListItemSecondaryAction>
            <Stack direction={"row"} spacing={0.5} justifyContent={"center"}>
              <Button size={"small"} variant={"contained"} onClick={handleEditResult}>
                Opslaan
              </Button>
              <Button size={"small"} variant={"contained"} onClick={handleEditing}>
                Annuleer
              </Button>
            </Stack>
          </ListItemSecondaryAction>
        </>
      ) : (
        <>
          <ListItemText onDoubleClickCapture={handleEditing}>{utterance.text}</ListItemText>
          <ListItemSecondaryAction>
            <Stack
              direction={"row"}
              divider={<Divider orientation={"vertical"} variant={"middle"} flexItem />}
              spacing={0.5}
              justifyContent={"center"}
            >
              <IconButton size="small" onClick={handleEditing}>
                <EditIcon color="primary" />
              </IconButton>
              <IconButton size="small" onClick={handleDelete}>
                <DeleteIcon color="error" />
              </IconButton>
            </Stack>
          </ListItemSecondaryAction>
        </>
      )}
    </ListItem>
  );
};

export default UtteranceListItem;

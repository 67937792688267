import { Box, Grid, Skeleton, TextField } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import CustomSelect from "./CustomSelect";
import { TopDeskApiData } from "../../../../API/TopDeskInteraction";
import { ITSMPlatform, TOPDeskInformation, TopDeskItem, TopDeskSubcategory } from "../../../../models/ItsmModels";

interface TopDeskTicketInformationProps {
  handleUpdate: (updatedTicketInfo: TOPDeskInformation, platform: ITSMPlatform) => void;
  topDeskInformation: TOPDeskInformation;
  topDeskApiData: TopDeskApiData | undefined;
  isTopDeskDataLoaded: boolean;
  loadTopDeskApiData: () => Promise<boolean>;
}

/**
 * TopDeskTicketInformation Component
 * @param handleUpdate - The function to handle the update of ticket information.
 * @param topDeskInformation - The ticket information.
 * @param topDeskApiData - The TopDeskApiData.
 * @param isTopDeskDataLoaded - Whether the TopDeskApiData is loaded.
 * @param loadTopDeskApiData - The function to load the TopDeskApiData.
 * @returns A React component representing the TopDeskTicketInformation to display and edit ticket information.
 */
const TopDeskTicketInformation: FC<TopDeskTicketInformationProps> = ({
  handleUpdate,
  topDeskInformation,
  isTopDeskDataLoaded,
  topDeskApiData,
  loadTopDeskApiData,
}: TopDeskTicketInformationProps) => {
  const isMount = useRef<boolean>(true);

  /**
   * checks if variable is correctly declared
   */
  const checkIfCorrect = (type: any): string => {
    if (type === null || type === undefined || typeof type?.id === "object") {
      return "-1";
    }

    return type.id;
  };

  // TODO: do not use hardcoded "-1"
  const [title, setTitle] = useState<string>(topDeskInformation?.briefDescription ?? "");
  const [description, setDescription] = useState<string>(topDeskInformation?.request ?? "");
  const [selectedCategory, setSelectedCategory] = useState<string>(topDeskInformation?.category?.id ?? "-1");
  const [selectedSubcategory, setSelectedSubcategory] = useState<string>(topDeskInformation?.subcategory?.id ?? "-1");
  const [selectedImpact, setSelectedImpact] = useState<string>(topDeskInformation?.impact?.id ?? "-1");
  const [selectedUrgency, setSelectedUrgency] = useState<string>(topDeskInformation?.urgency?.id ?? "-1");
  const [selectedPriority, setSelectedPriority] = useState<string>(topDeskInformation?.priority?.id ?? "-1");
  const [selectedStatus, setSelectedStatus] = useState<string>(topDeskInformation?.processingStatus?.id ?? "-1");
  const [selectedEntryType, setSelectedEntryType] = useState<string>(checkIfCorrect(topDeskInformation?.entryType));
  const [selectedCallType, setSelectedCallType] = useState<string>(checkIfCorrect(topDeskInformation?.callType));

  useEffect(() => {
    /**
     * Loads the TOPdesk data.
     * @returns Promise<void>
     */
    const loadData = async (): Promise<void> => {
      if (!isTopDeskDataLoaded) {
        await loadTopDeskApiData();
      }
    };

    loadData().catch((err) => {
      throw new Error(err);
    });
  }, []);

  useEffect(() => {
    if (!isMount.current) {
      const updatedCategory = topDeskApiData?.categories?.find((el) => el.id === selectedCategory) ?? null;
      const updatedCallType = topDeskApiData?.callTypes?.find((el) => el.id === selectedCallType) ?? null;
      const updatedSubcategory = topDeskApiData?.subcategories?.find((el) => el.id === selectedSubcategory) ?? null;
      const updatedImpact = topDeskApiData?.impacts?.find((el) => el.id === selectedImpact) ?? null;
      const updatedUrgency = topDeskApiData?.urgencies?.find((el) => el.id === selectedUrgency) ?? null;
      const updatedPriority = topDeskApiData?.priorities?.find((el) => el.id === selectedPriority) ?? null;
      const updatedEntryType = topDeskApiData?.entryTypes?.find((el) => el.id === selectedEntryType) ?? null;
      const updatedStatus = topDeskApiData?.statuses?.find((el) => el.id === selectedStatus) ?? null;

      handleUpdate(
        {
          briefDescription: title,
          request: description,
          category: updatedCategory?.id === "-1" ? null : updatedCategory,
          callType: updatedCallType?.id === "-1" ? null : updatedCallType,
          subcategory: updatedSubcategory?.id === "-1" ? null : updatedSubcategory,
          impact: updatedImpact?.id === "-1" ? null : updatedImpact,
          urgency: updatedUrgency?.id === "-1" ? null : updatedUrgency,
          priority: updatedPriority?.id === "-1" ? null : updatedPriority,
          entryType: updatedEntryType?.id === "-1" ? null : updatedEntryType,
          processingStatus: updatedStatus?.id === "-1" ? null : updatedStatus,
        },
        ITSMPlatform.TOPdesk,
      );
    } else {
      isMount.current = false;
    }
  }, [
    title,
    description,
    selectedCallType,
    selectedCategory,
    selectedSubcategory,
    selectedImpact,
    selectedUrgency,
    selectedPriority,
    selectedEntryType,
    selectedStatus,
    topDeskApiData,
    handleUpdate,
    isMount,
  ]);

  const filteredSubcategories: Array<TopDeskSubcategory> | undefined =
    topDeskApiData?.subcategories !== undefined
      ? topDeskApiData?.subcategories?.filter(
          (subcategory) => subcategory.category.id === selectedCategory || subcategory.category.id === "-1",
        )
      : [];

  return (
    <>
      {!isTopDeskDataLoaded && (
        <Grid container justifyContent="center">
          <Grid item xs={12} height="60vh">
            <Skeleton variant="rounded" height="100%" />
          </Grid>
        </Grid>
      )}
      {isTopDeskDataLoaded && (
        <>
          <Grid item>
            <h2>Vooringevulde antwoorden:</h2>
          </Grid>
          <Box sx={{ display: "flex" }} mb={2} mr={2}>
            <TextField
              id={`request-title`}
              label="Verzoek Titel"
              placeholder="Voer een titel voor het incident in"
              multiline
              fullWidth
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </Box>
          <Box sx={{ display: "flex" }} mb={2} mr={2}>
            <TextField
              id={`request-description`}
              label="Incident Beschrijving "
              placeholder="Voer een beschrijving voor het ticket in"
              multiline
              fullWidth
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Box>
          <CustomSelect
            label="Type melding"
            selectId={`callTypeSelect`}
            itemList={topDeskApiData?.callTypes ?? []}
            selectedValue={selectedCallType}
            handleChange={(val) => {
              setSelectedCallType(val);
            }}
          />
          <CustomSelect
            label="Categorie"
            selectId={`categorySelect`}
            itemList={topDeskApiData?.categories ?? []}
            selectedValue={selectedCategory}
            handleChange={(val) => {
              setSelectedCategory(val);
              setSelectedSubcategory("-1");
            }}
          />
          <CustomSelect
            label="Subcategorie"
            selectId={`subcategorySelect`}
            itemList={filteredSubcategories ?? []}
            selectedValue={selectedSubcategory}
            handleChange={(val) => {
              setSelectedSubcategory(val);
            }}
          />
          <CustomSelect
            label="Entry Type"
            selectId={`entryTypeSelect`}
            itemList={topDeskApiData?.entryTypes ?? []}
            selectedValue={selectedEntryType}
            handleChange={(val) => {
              setSelectedEntryType(val);
            }}
          />
          <CustomSelect
            label="Impact"
            selectId={`impactSelect`}
            itemList={topDeskApiData?.impacts ?? []}
            selectedValue={selectedImpact}
            handleChange={(val) => {
              setSelectedImpact(val);
            }}
          />
          <CustomSelect
            label="Prioriteit"
            selectId={`prioritySelect`}
            itemList={topDeskApiData?.priorities ?? []}
            selectedValue={selectedPriority}
            handleChange={(val) => {
              setSelectedPriority(val);
            }}
          />
          <CustomSelect
            label="Status"
            selectId={`statusSelect`}
            itemList={topDeskApiData?.statuses ?? []}
            selectedValue={selectedStatus}
            handleChange={(val) => {
              setSelectedStatus(val);
            }}
          />
          <CustomSelect
            label="Urgentie"
            selectId={`urgencySelect`}
            itemList={topDeskApiData?.urgencies ?? []}
            selectedValue={selectedUrgency}
            handleChange={(val) => {
              setSelectedUrgency(val);
            }}
          />
        </>
      )}
    </>
  );
};

export default TopDeskTicketInformation;

import React, { FC, useReducer, ReactNode, useContext, createContext, Dispatch } from "react";

// Data Imports
import { TrackedChange, ChangeBucket, changeBucketTemplateArray, ChangeOrigin } from "../../models/ChangeTracking";
import { deepCopy } from "../../helpers/deepCopy";

import { useErrorContext } from "../ErrorContext";
import { StageChanges } from "../../API/ChangeManagementInteraction";

// #region local storage context

// Builds the context that provides the local storage information
const LocalStorageContext = createContext<localStorageContextAttributes | null>(null);

// Builds the context that provides the function that lets components dispatch actions
const LocalStorageDispatchContext = createContext<Dispatch<any> | null>(null);

// Initial local storage state provided to the reducer in the provider
const initialLocalStorageContext: localStorageContextAttributes = {
  changeBuckets: deepCopy(changeBucketTemplateArray),
};

/**
 * Enum that contains all possible actions
 */
export enum LocalStorageActionType {
  "set",
  "get",
  "trackChange",
  "updateChange",
  "updateChanges",
  "untrackChange",
  "clearChanges",
  "saveChanges",
}

/**
 * Interfaces the children property to the context provider
 */
interface LocalStorageContextProviderProps {
  children: ReactNode | Array<ReactNode> | null;
}

/**
 * Interfaces the context values and functions with the provider component
 */
export type localStorageContextAttributes = {
  changeBuckets: Array<ChangeBucket>;
  getChangeByIdInOrigin?: (changeId: string, changeOrigin: ChangeOrigin) => TrackedChange | undefined;
  setChanges?: (buckets: Array<ChangeBucket>) => void;
  clearChanges?: () => void;
  trackChange?: (update: TrackedChange) => void;
  updateChange?: (update: TrackedChange) => void;
  updateChanges?: (update: ChangeBucket) => void;
  untrackChange?: (update: TrackedChange) => void;
  trackedChangeExists?: (update: TrackedChange) => boolean;
  saveChanges?: () => Promise<void>;
};

/**
 * Provides the local storage context to the application
 * @param children - The children of a provider
 * @returns a provider element
 */
export const LocalStorageContextProvider: FC<LocalStorageContextProviderProps> = ({ children }) => {
  const [context, dispatch] = useReducer(localStorageReducer, initialLocalStorageContext);

  const errorContext = useErrorContext();

  /**
   * Context function that allows the changeBuckets to be replaced with a fresh copy.
   */
  const setChanges = (buckets: Array<ChangeBucket>): void => {
    try {
      const action: localStorageReducerAction = {
        type: LocalStorageActionType.set,
        payload: deepCopy(buckets),
      };

      dispatch(action);
    } catch (error) {
      const newError: Error = new Error(`LocalStorageContext Error | ${setChanges.name} - ERROR: ${error}`);
      errorContext?.createError!(newError);
    }
  };

  /**
   * Context function that allows an item (if it exists) to be retrieved from a change bucket
   */
  const getChangeByIdInOrigin = (changeKey: string, changeOrigin: ChangeOrigin): TrackedChange | undefined => {
    try {
      // Get the change bucket
      const bucket: ChangeBucket = context.changeBuckets.find(
        (bucket: ChangeBucket) => bucket.key === changeOrigin.containerName,
      )!;

      const change: TrackedChange = bucket.changes.find((change) => change.key === changeKey)!;

      if (change !== undefined) {
        return change;
      } else {
        return undefined;
      }
    } catch (error) {
      const newError: Error = new Error(`LocalStorageContext Error | ${getChangeByIdInOrigin.name} - ERROR: ${error}`);
      errorContext?.createError!(newError);
    }
  };

  /**
   * Context function that allows the changeBuckets to be replaced with a fresh copy.
   */
  const clearChanges = (): void => {
    try {
      const action: localStorageReducerAction = {
        type: LocalStorageActionType.clearChanges,
        payload: deepCopy(changeBucketTemplateArray),
      };

      dispatch(action);
    } catch (error) {
      const newError: Error = new Error(`LocalStorageContext Error | ${clearChanges.name} - ERROR: ${error}`);
      errorContext?.createError!(newError);
    }
  };

  /**
   * Context function that adds a tracked change to it's corresponding bucket
   * @param update - Generated by a component to be put into context
   */
  const trackChange = (update: TrackedChange): void => {
    try {
      // Get the change bucket
      const bucket: ChangeBucket = context.changeBuckets.find(
        (bucket: ChangeBucket) => bucket.key === update.origin.containerName,
      )!;

      if (bucket === undefined) {
        errorContext?.createError!(
          new Error(`LocalStorageContext Error | ${trackChange.name} - ERROR: Bucket not found`),
        );
      }

      // Add the change to the bucket
      bucket.changes.push(update);

      // Build a dispatch action
      const action: localStorageReducerAction = {
        type: LocalStorageActionType.trackChange,
        payload: bucket,
      };

      dispatch(action);
    } catch (error) {
      const newError: Error = new Error(`LocalStorageContext Error | ${trackChange.name} - ERROR: ${error}`);
      errorContext?.createError!(newError);
    }
  };

  /**
   * Context function that updates a tracked change inside it's corresponding bucket
   * (may not be intended behavior in it's current state)
   * @param update - Generated by a component to be put into context
   */
  const updateChange = (update: TrackedChange): void => {
    try {
      // Get the change bucket
      const bucket: ChangeBucket = context.changeBuckets.find(
        (bucket: ChangeBucket) => bucket.key === update.origin.containerName,
      )!;

      // find and replace the change
      const updatedChanges = bucket.changes.map((change) => {
        if (change.key === update.key) {
          return update;
        } else {
          return change;
        }
      });

      // apply the update
      bucket.changes = updatedChanges;

      const action: localStorageReducerAction = {
        type: LocalStorageActionType.updateChange,
        payload: bucket,
      };

      dispatch(action);
    } catch (error) {
      const newError: Error = new Error(`LocalStorageContext Error | ${updateChange.name} - ERROR: ${error}`);
      errorContext?.createError!(newError);
    }
  };

  /**
   * Context function that updates a corresponding bucket
   * (may not be intended behavior in it's current state)
   * @param update - Generated by a component to be put into context
   */
  const updateChanges = (update: ChangeBucket): void => {
    // Get the change bucket
    const updatedBucket: ChangeBucket = context.changeBuckets.find(
      (bucket: ChangeBucket) => bucket.key === update.key,
    )!;

    // find the change and apply the updated version
    updatedBucket.changes = update.changes.map((change) => ({
      ...change,
    }));

    // Build a dispatch action
    const action: localStorageReducerAction = {
      type: LocalStorageActionType.updateChanges,
      payload: updatedBucket,
    };

    dispatch(action);
  };

  /**
   * Context function that removes a tracked change from it's corresponding bucket
   * @param update - Generated by a component to be put into context
   */
  const untrackChange = (update: TrackedChange): void => {
    try {
      // Get the change bucket
      const bucket: ChangeBucket = context.changeBuckets.find(
        (bucket: ChangeBucket) => bucket.key === update.origin.containerName,
      )!;

      // Remove the change from the bucket
      const index = bucket.changes.findIndex((change) => change.key === update.key);
      if (index > -1) {
        bucket.changes.splice(index, 1);
      }

      // Build a dispatch action
      const action: localStorageReducerAction = {
        type: LocalStorageActionType.untrackChange,
        payload: bucket,
      };

      dispatch(action);
    } catch (error) {
      const newError: Error = new Error(`LocalStorageContext Error | ${untrackChange.name} - ERROR: ${error}`);
      errorContext?.createError!(newError);
    }
  };

  /**
   * Context function that allows for checking whether the change to track already exists.
   * Keeping in mind that every update has a unique id, we check by update's key.
   * @param key - the key of the item related to the change.
   * @param containerName - the related bucket to find by it's container name.
   */
  const trackedChangeExists = (update: TrackedChange): boolean => {
    try {
      // Get the change bucket
      const bucket: ChangeBucket = context.changeBuckets.find(
        (bucket: ChangeBucket) => bucket.key === update.origin.containerName,
      )!;

      const changeExists = bucket.changes.find((change) => change.key === update.key);

      if (changeExists !== undefined) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      const newError: Error = new Error(`LocalStorageContext Error | ${trackedChangeExists.name} - ERROR: ${error}`);
      errorContext?.createError!(newError);

      return false;
    }
  };

  /**
   * Initiate the process of saving changes to the staging in the backend.
   */
  const saveChanges = async (): Promise<void> => {
    try {
      // Save the changes with the buckets of the local context
      const response = await StageChanges(context.changeBuckets);

      // Build a dispatch action
      const action: localStorageReducerAction = {
        type: LocalStorageActionType.saveChanges,
        payload: response,
      };

      dispatch(action);
    } catch (error) {
      const newError: Error = new Error(`LocalStorageContext Error | ${saveChanges.name} - ERROR: ${error}`);
      errorContext?.createError!(newError);
    }
  };

  const _context: localStorageContextAttributes = {
    changeBuckets: context.changeBuckets,
    setChanges,
    getChangeByIdInOrigin,
    clearChanges,
    trackChange,
    updateChange,
    updateChanges,
    untrackChange,
    trackedChangeExists,
    saveChanges,
  };

  return (
    <LocalStorageContext.Provider value={_context}>
      <LocalStorageDispatchContext.Provider value={dispatch}>{children}</LocalStorageDispatchContext.Provider>
    </LocalStorageContext.Provider>
  );
};

/**
 * Provides the local storage context
 * @returns A context
 */
export const useLocalStorageContext = (): localStorageContextAttributes | null => useContext(LocalStorageContext);

/**
 * Provides the function that lets components dispatch actions
 * @returns A dispatch context
 */
export const useLocalStorageDispatch = (): Dispatch<any> | null => useContext(LocalStorageDispatchContext);

// endregion

// #region local storage reducer

/**
 * Custom type for the reducer action that works with local storage context
 */
export type localStorageReducerAction = {
  type: LocalStorageActionType;
  payload?: ChangeBucket | Array<ChangeBucket>;
};

/**
 * Set is used to set the containers, updateSingle is used to update a single container, updateAll
 * is used to update all the containers
 * @param context - The state to be updated
 * @param action - The action to be executed by the reducer
 * @returns Updated state
 */
export const localStorageReducer = (
  context: localStorageContextAttributes,
  action: localStorageReducerAction,
): localStorageContextAttributes => {
  switch (action.type) {
    case LocalStorageActionType.trackChange:
    case LocalStorageActionType.updateChange:
    case LocalStorageActionType.untrackChange:
    case LocalStorageActionType.updateChanges: {
      const updatedBucket = action.payload as ChangeBucket;
      const updatedBuckets: Array<ChangeBucket> = context.changeBuckets.map((bucket: ChangeBucket) => {
        if (bucket.key === updatedBucket.key) {
          return updatedBucket;
        } else {
          return bucket;
        }
      });

      return { ...context, changeBuckets: updatedBuckets } satisfies localStorageContextAttributes;
    }
    case LocalStorageActionType.set:
    case LocalStorageActionType.clearChanges:
    case LocalStorageActionType.saveChanges: {
      const updatedBuckets = [...(action.payload as Array<ChangeBucket>)];

      return { ...context, changeBuckets: updatedBuckets };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

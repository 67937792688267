import React, { FC } from "react";

// Material Imports
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import "./Header.css";
import Branding from "./Branding";
import SignInOut from "./SignInOut";

interface HeaderProps {
  authenticated: boolean;
  isDrawerOpen?: boolean;
  handleDrawerOpen?: () => void;
}

/**
 * Header Component
 * @param authenticated - Whether the user is authenticated.
 * @param isDrawerOpen - Whether the drawer is open.
 * @param handleDrawerOpen - The function to handle the opening of the drawer.
 * @returns A React component representing the Header to display and edit the header.
 */
const Header: FC<HeaderProps> = ({ authenticated, isDrawerOpen, handleDrawerOpen }) => {
  const theme = useTheme();

  return (
    <>
      {authenticated ? (
        <>
          <AppBar
            id="appbar"
            position="fixed"
            sx={{
              zIndex: theme.zIndex.drawer + 1,
            }}
          >
            <Toolbar>
              <IconButton edge="start" sx={{ mr: 2 }} onClick={handleDrawerOpen} aria-label="open drawer">
                {isDrawerOpen !== true ? (
                  <MenuIcon sx={{ color: "white" }} />
                ) : (
                  <ChevronLeftIcon sx={{ color: "white" }} />
                )}
              </IconButton>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Branding />
                </Grid>
                <Grid item>
                  <SignInOut />
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </>
      ) : (
        <AppBar id="appbar" position="static">
          <Toolbar>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Branding />
              </Grid>
              <Grid item>
                <SignInOut />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default Header;

import React, { FC } from "react";
import { NodeSelection } from "../VisualisationDialog";
import { useStagingContext } from "../../../../contexts/StagingContext";
import { ApiBlobResponse } from "../../../../API/StorageInteraction";
import { Grid, MenuItem, TextField } from "@mui/material";
import { Dialog, DialogStep } from "../../../../models/Dialogs";

interface ShowRedirectOptionsProps {
  selectedNodeData: { newNodeData: DialogStep; oldNodeData: DialogStep | null };
  setSelectednodeData: (arg0: NodeSelection) => void;
  containerName: string;
  selectedDialog: string;
}

/**
 * ShowRedirectOptions Component
 * @param props - The props object for the component.
 * @returns A ShowRedirectOptions component.
 */
const ShowRedirectOptions: FC<ShowRedirectOptionsProps> = ({
  selectedNodeData,
  setSelectednodeData,
  containerName,
  selectedDialog,
}): React.ReactElement => {
  // Context
  const stagingContext = useStagingContext();

  // Get the containers
  if (stagingContext === null) return <></>;
  const _dialogs: Array<Dialog> = stagingContext.containers.find((blob: ApiBlobResponse) => blob.key === containerName)!
    .blob.value;

  /**
   * Handles the change of the response of the node.
   * @param event - The event that triggered the change.
   * @returns void
   */
  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const updateNodeData: DialogStep = {
      ...selectedNodeData.newNodeData,
    };

    updateNodeData.options.redirect = event.target.value;

    const newNodeSelection: NodeSelection = {
      oldNodeData: selectedNodeData.oldNodeData!,
      newNodeData: updateNodeData,
      changed: true,
    };

    setSelectednodeData(newNodeSelection);
  };

  return (
    <Grid key="options-container " container spacing={1}>
      <Grid key={`redirect`} item xs={12}>
        <TextField
          select
          label={"dialoog"}
          value={selectedNodeData.newNodeData.options.redirect}
          onChange={onChange}
          inputProps={{ name: "redirect" }}
          fullWidth={true}
        >
          {_dialogs.map((dialog) => {
            if (dialog.name !== selectedDialog) {
              return (
                <MenuItem key={dialog.name} value={dialog.name}>
                  {dialog.name}
                </MenuItem>
              );
            } else {
              return null;
            }
          })}
        </TextField>
      </Grid>
    </Grid>
  );
};

export default ShowRedirectOptions;

import React, { FC, useState, DragEvent, useEffect, useRef, ReactElement } from "react";

// Mui imports
import { Button, IconButton, List, ListItem, ListItemText, Stack, Box } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

// Data Imports
import { KnowledgeSourceWebAppsConfig } from "../../authConfig";

// Context imports
import { allowedFileExtensions } from "../../models/KnowledgeSource";

// Helper functions imports
import { deepCopy } from "../../helpers/deepCopy";

interface FileUploadProps {
  setFiles: (files: Array<File>) => void;
  // Only given when editing source which already contains files
  filenames?: Array<string>;
  updateFilenames?: (filenames: Array<string>) => void;
}

/**
 * The functional component that handles retrieving files as File objects.
 * @param setFiles - Function that takes in the new File objects
 * @param filenames - The filenames used for avoiding duplicates
 * @param setFilenames - Function used to update filenames in original source
 * @returns JSX element representing the 'CreateUploadSourceDialog'.
 */
const FileUpload: FC<FileUploadProps> = ({ setFiles, filenames = [], updateFilenames = () => {} }) => {
  // New Files to be uploaded
  const [newFiles, setNewFiles] = useState<Array<File>>([]);

  // Filenames of existing files linked to source
  const [currentFilenames, setCurrentFilenames] = useState<Array<string>>(deepCopy(filenames));

  // State use for visualizing cursor drag over
  const [dragOver, setDragOver] = useState<boolean>(false);

  // Updates files to return to parent component and updates filenames
  useEffect(() => {
    setFiles(newFiles);
  }, [newFiles]);

  useEffect(() => {
    updateFilenames(currentFilenames);
  }, [currentFilenames]);

  /** Handle the deletion of a file from the drop selection */
  const handleDelete = (filenameToDelete: string): void => {
    if (newFiles.some((file) => file.name === filenameToDelete)) {
      setNewFiles(newFiles.filter((file) => file.name !== filenameToDelete));
    } else {
      setCurrentFilenames(currentFilenames.filter((filename) => filename !== filenameToDelete));
    }
  };

  /** Handles validating file type, file size and avoiding duplicates files (based on name) */
  const validateFiles = (droppedFiles: Array<File>): Array<File> => {
    // Document size limit fail-safe default is 2MB
    let documentSizeLimit = 2000000;
    if (KnowledgeSourceWebAppsConfig.knowledgeSourceDocumentSizeLimit !== undefined) {
      documentSizeLimit = parseInt(KnowledgeSourceWebAppsConfig.knowledgeSourceDocumentSizeLimit);
    }

    // Filter files based on extension, url-reserved characters, size and duplication
    const acceptedFiles = droppedFiles.filter(
      (file) =>
        allowedFileExtensions.includes(getExtension(file.name)) &&
        !file.name.includes("#") &&
        !file.name.includes("?") &&
        file.size < documentSizeLimit &&
        !currentFilenames.includes(file.name) &&
        !newFiles.map((newfile) => newfile.name).includes(file.name),
    );

    return acceptedFiles;
  };

  /** Get the extension from a file name */
  const getExtension = (filename: string): string => {
    const ext = filename.split(".").pop();
    if (ext === undefined) {
      throw new Error("Filename is missing extension");
    }

    return ext;
  };

  /** Handles dropping files
   * @param event - user input from HTML element
   */
  const handleFileDrop = (event: DragEvent<HTMLDivElement>): void => {
    event.preventDefault();

    const droppedFiles = Array.from(event.dataTransfer.files);
    const acceptedFiles: Array<File> = [...newFiles, ...validateFiles(droppedFiles)];

    setNewFiles(acceptedFiles);
    setDragOver(false);
  };

  /** Handles choosing file from file explorer
   * @param event - user input from HTML element
   */
  const handleFileChoice = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();

    const droppedFiles = Array.from(event.target.files ?? []);
    const acceptedFiles: Array<File> = [...newFiles, ...validateFiles(droppedFiles)];

    setNewFiles(acceptedFiles);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  /** Handles clicking file upload button */
  const handleButtonClick = (): void => {
    fileInputRef.current?.click();
  };

  /** The file upload button and drag-and-drop text
   * @param border - whether to set the border
   */
  const fileUploadButton = (border: boolean = false): ReactElement => (
    <Stack
      direction="row"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "5px",
        height: "50px",
        borderBottom: border ? "2px dashed black" : "",
      }}
    >
      <p> Sleep bestanden hierheen of </p>
      <Button variant="contained" color="info" onClick={handleButtonClick} sx={{ maxHeight: "25px" }}>
        Kies een bestand
      </Button>
      <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChoice} multiple />
    </Stack>
  );

  const allFileNames = [...currentFilenames, ...newFiles.map((file) => file.name)];

  return (
    <>
      <Box
        onDragOver={(event) => {
          event.preventDefault();
          setDragOver(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setDragOver(false);
        }}
        onDrop={handleFileDrop}
        sx={{
          display: "flex",
          justifyContent: "center",
          minHeight: "50px",
          maxHeight: "200px",
          width: "100%",
          border: "2px dashed",
          backgroundColor: dragOver ? "lightblue" : "white",
        }}
      >
        {allFileNames.length === 0 ? (
          <>{fileUploadButton()}</>
        ) : (
          <Stack sx={{ width: "100%" }}>
            {fileUploadButton(true)}

            <List
              style={{
                display: "flex",
                flexWrap: "wrap",
                height: "100%",
                width: "100%",
                paddingBottom: "0",
                paddingTop: "0",
                overflowY: "auto",
              }}
            >
              {allFileNames.map((filename, index) => (
                <ListItem
                  key={`file-${filename}`}
                  style={{
                    borderBottom: index < allFileNames.length - 1 ? "1px solid lightgrey" : "none",
                  }}
                  secondaryAction={
                    <IconButton
                      onClick={() => {
                        handleDelete(filename);
                      }}
                    >
                      <CancelIcon color="error" fontSize="small" />
                    </IconButton>
                  }
                >
                  <ListItemText primary={filename} />
                </ListItem>
              ))}
            </List>
          </Stack>
        )}
      </Box>
    </>
  );
};

export default FileUpload;

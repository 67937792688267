import React, { ChangeEvent, FC, Fragment, useEffect, useState } from "react";

// Component Imports
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { DEFlagIcon, FRFlagIcon, GBFlagIcon, NLFlagIcon } from "../../../Icons/FlagIcons";
import AddIcon from "@mui/icons-material/Add";
import CustomToolTip from "../../../CustomToolTip";
import AddNewLanguageDialog from "../../ModularEditor/AddNewLanguageDialog";
import { NodeSelection } from "../VisualisationDialog";

// import ShowNewAnswerButton from "./ShowNewAnswerButton";

// Context imports
import { useStagingContext } from "../../../../contexts/StagingContext";
import { useLocalStorageContext } from "../../../../contexts/ChangeTracking/LocalStorageContext";

// Data Imports
import { ApiBlobResponse } from "../../../../API/StorageInteraction";
import { Language, LanguageText } from "../../../../models/Language";
import { ContainerNames, ShowOptionsComponentType } from "../../../../models/enums";
import { Answer } from "../../../../models/Answers";
import { ButtonOption, DialogStep } from "../../../../models/Dialogs";
import { GroupIncident } from "../../../../models/GroupIncidents";
import { ChangeOrigin, ChangeType, TrackedChange } from "../../../../models/ChangeTracking";

// CSS Imports
import styles from "./detailsWindows.module.scss";
import { ITSMDescriptionQuesion } from "../../../../models/ItsmModels";
import moment from "moment";
import CreateAnswerDialog from "../../../Dialogs/NewEntryDialogs/CreateAnswerDialog";
import { useErrorContext } from "../../../../contexts/ErrorContext";

interface ShowOptionsProps {
  componentType: ShowOptionsComponentType;
  optionsId?: string;
  selectedNodeData: { newNodeData: DialogStep; oldNodeData: DialogStep };
  setSelectedNodeData: (arg0: NodeSelection) => void;
}

/**
 * ShowOptions Component
 * @param selectedNodeData - The selected node data.
 * @param setSelectedNodeData - The function to set the selected node data.
 * @returns A ShowOptions component.
 */
const ShowOptions: FC<ShowOptionsProps> = ({ selectedNodeData, setSelectedNodeData, componentType, optionsId }) => {
  // Context
  const stagingContext = useStagingContext();
  const localStorageContext = useLocalStorageContext();
  const errorContext = useErrorContext();

  const [blobData, setBlobData] = useState<ApiBlobResponse>();
  const [answers, setAnswers] = useState<Array<Answer>>();
  const [filteredAnswers, setFilteredAnswers] = useState<Array<Answer>>();
  const [options, setOptions] = useState<Array<Answer>>();
  const [selectedOption, setSelectedOption] = useState<Answer>();
  const [languageUsed, setLanguageUsed] = useState<string>(process.env.REACT_APP_GLOBAL_LANGUAGE!);
  const [languagesNotUsed, setLanguagesNotUsed] = useState<Array<Language>>([]);
  const [isNewLanguageDialogOpen, setIsNewLanguageDialogOpen] = useState<boolean>(false);
  const [selectedNodeOption, setSelectedNodeOption] = useState<string>();
  const [componentLabel, setComponentLabel] = useState<string>("");

  useEffect(() => {
    setSelectedNodeOption(getOptionsBasedOnComponentType(componentType));
  }, [
    selectedNodeData.newNodeData.options.selectedResponse,
    selectedNodeData.newNodeData.options.selectedQuestion,
    selectedNodeData.newNodeData.options.DescQuestions,
    selectedNodeData.newNodeData.options.buttons,
  ]);

  /**
   * This effect will set the blobdata, answers and filtered answers.
   */
  useEffect(() => {
    if (stagingContext !== null) {
      const answersBlob = stagingContext.containers.find((x) => x.key === ContainerNames.Answers);
      if (answersBlob === undefined) return;

      // Set the answers.
      setAnswers(answersBlob.blob.value);

      // Filter the answers based on the component type.
      const filteredAnswers: Array<Answer> = answersBlob.blob.value.filter(
        (value: Answer) => value.type === componentType,
      );
      setFilteredAnswers(filteredAnswers);

      // Set the answers blob data.
      setBlobData(answersBlob);

      // Cleanup if component closes
      return () => {
        errorContext?.clearError!();
      };
    }
  }, [selectedNodeOption, stagingContext?.containers]);

  /**
   * This effect will set the options and selected option, based on the options of the selected node.
   */
  useEffect(() => {
    if (filteredAnswers === undefined) return;
    const currentOptions: Array<Answer> = [];

    filteredAnswers.forEach((answer) => {
      answer.languages.forEach((language) => {
        const index = currentOptions.findIndex((x) => x.key === answer.key);

        if (index === -1) {
          currentOptions.push({
            key: answer.key,
            languages: [{ key: language.key, value: language.value }],
            type: componentType,
          });
        } else if (index !== -1 && !currentOptions[index].languages.some((x) => x.key === language.key)) {
          currentOptions[index].languages.push({ key: language.key, value: language.value });
        }
      });
    });

    currentOptions.forEach((option) => {
      if (option.languages.length === 1) {
        if (option.languages[0].key === "NL") {
          option.languages.push({ key: "EN", value: "" });
        } else if (option.languages[0].key === "EN") {
          option.languages.push({ key: "NL", value: "" });
        }
      }
    });

    setOptions(
      currentOptions.sort((a, b) =>
        a.key.toUpperCase() > b.key.toUpperCase() ? 1 : b.key.toUpperCase() > a.key.toUpperCase() ? -1 : 0,
      ),
    );
    setSelectedOption(currentOptions.find((x) => x.key === selectedNodeOption));
  }, [filteredAnswers]);

  /**
   * Set the selected option when the selected node option changes.
   */
  useEffect(() => {
    if (options === undefined) return;

    setSelectedOption(options.find((x) => x.key === selectedNodeOption));
  }, [selectedNodeOption]);

  /**
   * Set the component label based on the received component label on mount
   */
  useEffect(() => {
    switch (componentType) {
      case ShowOptionsComponentType.Button:
        setComponentLabel("Knop");
        break;
      case ShowOptionsComponentType.DescQuestion:
      case ShowOptionsComponentType.Question:
        setComponentLabel("Vraag");
        break;
      case ShowOptionsComponentType.Message:
        setComponentLabel("Bericht");
        break;
      default:
        setComponentLabel("");
        break;
    }
  }, [componentType]);

  /**
   * Set the currently unused languages for the selected option.
   */
  useEffect(() => {
    const languageOptions: Array<Language> = [
      { key: "NL", name: "Nederlands" },
      { key: "EN", name: "Engels" },
      { key: "DE", name: "Duits" },
      { key: "FR", name: "Frans" },
    ];

    const languages: Array<Language> = [];

    languageOptions.forEach((option) => {
      if (selectedOption !== undefined) {
        if (selectedOption.languages.find((x) => x.key === option.key) === undefined) {
          languages.push(option);
        }
      }
    });

    setLanguagesNotUsed(languages);
  }, [selectedOption]);

  /**
   * Function to return the correct dialog step options based on the input type
   * @param type - The type of component question|response|descQuestions
   * @returns Options of current dialog step
   */
  const getOptionsBasedOnComponentType = (type: string): string => {
    switch (type) {
      case ShowOptionsComponentType.Question:
        return selectedNodeData.newNodeData.options.selectedQuestion;
      case ShowOptionsComponentType.Message:
        return selectedNodeData.newNodeData.options.selectedResponse;
      case ShowOptionsComponentType.DescQuestion:
        return selectedNodeData.newNodeData.options.DescQuestions.find(
          (question: ITSMDescriptionQuesion) => question.id === optionsId,
        )!.question;
      case ShowOptionsComponentType.Button:
        return selectedNodeData.newNodeData.options.buttons.find((button: ButtonOption) => button.id === optionsId)!
          .key;
      default:
        return "";
    }
  };

  /**
   * Adds a new item to the data list and updates the component state and blob data with the new data.
   * @param update - The new item data to be added.
   */
  const onAddItem = (update: Answer): void => {
    if (blobData === undefined || answers === undefined) return;

    // Create a copy of the current answers list and add the newly created answer
    const updatedAnswers: Array<Answer> = [...answers];
    updatedAnswers?.push(update);

    const newBlob: ApiBlobResponse = {
      key: blobData.key,
      blob: { value: updatedAnswers },
    };

    // Create a new tracked change
    if (localStorageContext !== null) {
      // Add a sort order based on the item's keys.
      const keys: Array<string> = [];
      for (const key of Object.keys(update)) {
        keys.push(key);
      }

      const newTrackedChange: TrackedChange = {
        key: `change-${ContainerNames.Answers}-${update.key}`,
        origin: {
          containerName: ContainerNames.Answers,
          fileName: `${newBlob.key}.json`,
        },
        newValue: update,
        lastModified: moment().toDate(),
        changeType: ChangeType.New,
        live: false,
        displayOrder: keys,
      };

      localStorageContext.trackChange!(newTrackedChange);
    }

    // Update the context
    stagingContext?.updateContextWithContainer!(newBlob);

    const updateNodeData: DialogStep = {
      ...selectedNodeData.newNodeData,
    };

    switch (componentType) {
      case ShowOptionsComponentType.Question:
        updateNodeData.options.selectedQuestion = update.key;
        break;
      case ShowOptionsComponentType.Message:
        updateNodeData.options.selectedResponse = update.key;
        break;
      case ShowOptionsComponentType.DescQuestion: {
        const updatedDescQuestions = [...updateNodeData.options.DescQuestions];
        const questionIndex = updatedDescQuestions.findIndex(
          (question: ITSMDescriptionQuesion) => question.id === optionsId,
        );
        if (questionIndex !== -1) {
          updatedDescQuestions[questionIndex].question = update.key;
        }
        updateNodeData.options.DescQuestions = updatedDescQuestions;
        break;
      }
      case ShowOptionsComponentType.Button: {
        const updatedButtons = [...updateNodeData.options.buttons];
        const buttonIndex = updatedButtons.findIndex((button: ButtonOption) => button.id === optionsId);
        if (buttonIndex !== -1) {
          updatedButtons[buttonIndex].key = update.key;
        }
        updateNodeData.options.buttons = updatedButtons;
        break;
      }
      default:
        break;
    }

    const newNodeSelection: NodeSelection = {
      oldNodeData: selectedNodeData.oldNodeData,
      newNodeData: updateNodeData,
      changed: true,
    };

    setSelectedNodeData(newNodeSelection);
  };

  /**
   * On change function that changes the selected option
   * @param event - The event generated by changing the value of a select field.
   */
  const onChangeSelection = (event: SelectChangeEvent): void => {
    const message = options?.find((x) => x.key === event.target.value);

    if (message === undefined) return;

    const updateNodeData: DialogStep = {
      ...selectedNodeData.newNodeData,
    };

    switch (componentType) {
      case ShowOptionsComponentType.Question:
        updateNodeData.options.selectedQuestion = event.target.value;
        break;
      case ShowOptionsComponentType.Message:
        updateNodeData.options.selectedResponse = event.target.value;
        break;
      case ShowOptionsComponentType.DescQuestion: {
        const updatedDescQuestions = [...updateNodeData.options.DescQuestions];
        const questionIndex = updatedDescQuestions.findIndex(
          (question: ITSMDescriptionQuesion) => question.id === optionsId,
        );
        if (questionIndex !== -1) {
          updatedDescQuestions[questionIndex].question = event.target.value;
        }
        updateNodeData.options.DescQuestions = updatedDescQuestions;
        break;
      }
      case ShowOptionsComponentType.Button: {
        const updatedButtons = [...updateNodeData.options.buttons];
        const buttonIndex = updatedButtons.findIndex((button: ButtonOption) => button.id === optionsId);
        if (buttonIndex !== -1) {
          updatedButtons[buttonIndex].key = event.target.value;
        }
        updateNodeData.options.buttons = updatedButtons;
        break;
      }
      default:
        break;
    }

    const newNodeSelection: NodeSelection = {
      oldNodeData: selectedNodeData.oldNodeData,
      newNodeData: updateNodeData,
      changed: true,
    };

    setLanguageUsed(process.env.REACT_APP_GLOBAL_LANGUAGE!);
    setSelectedNodeData(newNodeSelection);
  };

  /**
   * Update the value of the currently selected answer
   * @param event - The event generated by changing the value of a text field.
   */
  const onChangeBlob = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    if (blobData === undefined || selectedOption === undefined || answers === undefined) return;

    // Create a copy of the new item data
    const index = answers?.findIndex((x) => x.key === selectedOption.key);
    const updatedAnswers = [...answers];
    const answerCopy = updatedAnswers[index];

    // Get each language in the new item data
    if (index !== undefined && index !== -1) {
      const languageIndex = updatedAnswers[index].languages.findIndex((x) => x.key === languageUsed);

      if (languageIndex !== undefined && languageIndex !== -1) {
        updatedAnswers[index].languages[languageIndex].value = value;
      } else {
        updatedAnswers[index].languages.push({ key: languageUsed, value });
      }
    }

    const newBlob: ApiBlobResponse = {
      key: blobData.key,
      blob: { value: updatedAnswers },
    };

    // Track this new change
    if (localStorageContext !== null) {
      // Add a sort order based on the item's keys.
      const keys: Array<string> = [];
      for (const key of Object.keys(updatedAnswers[index])) {
        keys.push(key);
      }

      // Since we are updating, retrieve existing change.
      const changeId: string = `change-${ContainerNames.Answers}-${updatedAnswers[index].key}`;
      const changeOrigin: ChangeOrigin = {
        containerName: ContainerNames.Answers,
        fileName: `${newBlob.key}.json`,
      };

      // retrieve a previous update for this item and modify it, or create a new change if did not exist.
      const trackedChangeResult = localStorageContext.getChangeByIdInOrigin!(changeId, changeOrigin);
      if (trackedChangeResult === undefined) {
        // Build a new change.
        const newTrackedChange: TrackedChange = {
          key: changeId,
          origin: changeOrigin,
          oldValue: answerCopy,
          newValue: updatedAnswers[index],
          lastModified: moment().toDate(),
          changeType: ChangeType.Update,
          live: false,
          displayOrder: keys,
        };

        localStorageContext.trackChange!(newTrackedChange);
      } else {
        // Modify the change parameters.
        const updateTrackedChange = {
          ...trackedChangeResult,
          newValue: updatedAnswers[index],
          lastModified: moment().toDate(),
          displayOrder: keys,
        };

        localStorageContext.updateChange!(updateTrackedChange);
      }
    }

    stagingContext?.updateContextWithContainer!(newBlob);

    const newSelectedData: Answer = { ...selectedOption };

    newSelectedData.languages.forEach((member) => {
      if (member.key.toUpperCase() === languageUsed) {
        member.value = value;
      }
    });

    setSelectedOption(newSelectedData);
  };

  /**
   * Adds a language to the entry
   * @param languageText - The language text to add to the entry
   */
  const AddLanguage = (languageText: LanguageText): void => {
    if (blobData !== undefined) {
      // Find the key in the list of entries, add the language and update the context
      const updatedBlob: ApiBlobResponse = { ...blobData };
      const entryList = updatedBlob?.blob.value;
      const currentEntry = entryList.find((entry: { key: string | undefined }) => entry.key === selectedOption?.key);
      const entryCopy = currentEntry;
      const languages = currentEntry?.languages ?? [];

      if (languages.some((language: LanguageText) => language.key === languageText.key) === true) {
        // If the language already exists update the value
        languages.forEach((language: LanguageText) => {
          if (language.key === languageText.key) {
            language.value = languageText.value;
          }
        });
      } else {
        // If the language does not exist, add it
        languages.push(languageText);
      }

      if (currentEntry !== undefined) {
        currentEntry.languages = languages;
      }
      updatedBlob.blob.value = entryList;

      // Track this new change
      if (localStorageContext !== null) {
        // Add a sort order based on the item's keys.
        const keys: Array<string> = [];
        for (const key of Object.keys(currentEntry)) {
          keys.push(key);
        }

        // Since we are updating, retrieve existing change.
        const changeId: string = `change-${ContainerNames.Answers}-${currentEntry.key}`;
        const changeOrigin: ChangeOrigin = {
          containerName: ContainerNames.Answers,
          fileName: `${blobData.key}.json`,
        };

        // retrieve a previous update for this item and modify it, or create a new change if did not exist.
        const trackedChangeResult = localStorageContext.getChangeByIdInOrigin!(changeId, changeOrigin);
        if (trackedChangeResult === undefined) {
          // Build a new Change
          const newTrackedChange: TrackedChange = {
            key: changeId,
            origin: changeOrigin,
            oldValue: entryCopy,
            newValue: currentEntry,
            lastModified: moment().toDate(),
            changeType: ChangeType.Update,
            live: false,
            displayOrder: keys,
          };

          localStorageContext.trackChange!(newTrackedChange);
        } else {
          // Modify the change parameters.
          const updateTrackedChange = {
            ...trackedChangeResult,
            newValue: currentEntry,
            lastModified: moment().toDate(),
            displayOrder: keys,
          };

          localStorageContext.updateChange!(updateTrackedChange);
        }

        stagingContext?.updateContextWithContainer!(updatedBlob);
      }
    }
  };

  /**
   * Removes a language from the entry
   * @param languageKey - The language key to remove from the entry
   */
  const removeLanguage = (languageKey: string): void => {
    if (blobData !== undefined) {
      // Find the key in the list of texts, remove the language and update the context
      const updatedBlob: ApiBlobResponse = { ...blobData };
      const entryList: Array<GroupIncident> | Array<Answer> = updatedBlob?.blob.value;

      const entryIndex = entryList.findIndex((incident) => incident.key === selectedOption?.key);
      const entry = entryList[entryIndex];
      const entryCopy = entry;
      const languages = entry?.languages ?? [];

      entry.languages = languages.filter((lang) => lang.key !== languageKey);
      entryList[entryIndex] = entry;
      updatedBlob.blob.value = entryList;

      // Track this new change
      if (localStorageContext !== null) {
        // Add a sort order based on the item's keys.
        const keys: Array<string> = [];
        for (const key of Object.keys(entry)) {
          keys.push(key);
        }

        // Since we are updating, retrieve existing change.
        const changeId: string = `change-${ContainerNames.Answers}-${entry.key}`;
        const changeOrigin: ChangeOrigin = {
          containerName: ContainerNames.Answers,
          fileName: blobData.key,
        };

        // retrieve a previous update for this item and modify it, or create a new change if did not exist.
        const trackedChangeResult = localStorageContext.getChangeByIdInOrigin!(changeId, changeOrigin);
        if (trackedChangeResult === undefined) {
          // Build a new Change
          const newTrackedChange: TrackedChange = {
            key: changeId,
            origin: changeOrigin,
            oldValue: entryCopy,
            newValue: entry,
            lastModified: moment().toDate(),
            changeType: ChangeType.Update,
            live: false,
            displayOrder: keys,
          };

          localStorageContext.trackChange!(newTrackedChange);
        } else {
          // Modify the change parameters.
          const updateTrackedChange = {
            ...trackedChangeResult,
            newValue: entry,
            lastModified: moment().toDate(),
            displayOrder: keys,
          };

          localStorageContext.updateChange!(updateTrackedChange);
        }

        stagingContext?.updateContextWithContainer!(updatedBlob);
      }
    }
  };

  /**
   * On update of a desctiption question answer
   * @param event - The event generated by changing the value of a text field.
   */
  const onUpdateDescQuestion = (
    event: ChangeEvent<HTMLInputElement>,
    fieldToUpdate: "questionHeader" | "answer",
  ): void => {
    const updateNodeData: DialogStep = {
      ...selectedNodeData.newNodeData,
    };

    updateNodeData.options.DescQuestions.find((question: ITSMDescriptionQuesion) => question.id === optionsId)![
      fieldToUpdate
    ] = event.target.value;

    const newNodeSelection: NodeSelection = {
      oldNodeData: selectedNodeData.oldNodeData,
      newNodeData: updateNodeData,
      changed: true,
    };

    setSelectedNodeData(newNodeSelection);
  };

  if (errorContext?.applicationError !== null) throw new Error(errorContext?.applicationError?.message);

  return (
    <Stack gap={1}>
      {componentType === ShowOptionsComponentType.DescQuestion ? (
        <TextField
          label="Sectie naam"
          placeholder="Voer een naam voor deze sectie in"
          fullWidth
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onUpdateDescQuestion(event, "questionHeader");
          }}
          value={
            selectedNodeData.newNodeData.options.DescQuestions.find(
              (question: ITSMDescriptionQuesion) => question.id === optionsId,
            )!.questionHeader
          }
        />
      ) : null}
      {selectedOption !== undefined ? (
        <>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"} alignItems={"centre"}>
              {languagesNotUsed.length > 0 ? (
                <CustomToolTip
                  title="Voeg een nieuwe taal toe"
                  child={
                    <Button
                      onClick={() => {
                        setIsNewLanguageDialogOpen(true);
                      }}
                    >
                      <AddIcon />
                    </Button>
                  }
                />
              ) : null}
              {selectedOption.languages.map((language) => (
                <Button
                  key={language.key}
                  disabled={language.key === languageUsed}
                  onClick={() => {
                    setLanguageUsed(language.key);
                  }}
                >
                  {(() => {
                    switch (language.key) {
                      case "NL":
                        return <NLFlagIcon />;
                      case "EN":
                        return <GBFlagIcon />;
                      case "DE":
                        return <DEFlagIcon />;
                      case "FR":
                        return <FRFlagIcon />;
                      default:
                        return <></>;
                    }
                  })()}
                </Button>
              ))}
            </Stack>

            {/* <ShowNewAnswerButton executable={onAddItem} componentLabel={componentLabel} componentType={componentType} blobData={blobData} /> */}
            {blobData !== undefined && (
              <CreateAnswerDialog
                executable={onAddItem}
                language={process.env.REACT_APP_GLOBAL_LANGUAGE!}
                typePredetermined={componentType}
              />
            )}
          </Stack>

          <FormControl fullWidth={true} className={`${styles.FormControl} ${styles.TextField}`}>
            <InputLabel id="question-select-label">{`${componentLabel} selectie`}</InputLabel>
            <Select
              labelId="questionSelectionLabel"
              id="questionSelection"
              label={`${componentLabel} selectie`}
              onChange={onChangeSelection}
              value={selectedOption?.key}
            >
              {options?.map((option: Answer) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            className={styles.TextField}
            label={
              <Fragment>
                {`${componentLabel}:`}
                {(() => {
                  switch (languageUsed) {
                    case "NL":
                      return <NLFlagIcon />;
                    case "EN":
                      return <GBFlagIcon />;
                    case "DE":
                      return <DEFlagIcon />;
                    case "FR":
                      return <FRFlagIcon />;
                    default:
                      return <NLFlagIcon />;
                  }
                })()}
              </Fragment>
            }
            value={selectedOption.languages.find((x) => x.key === languageUsed)?.value}
            inputProps={{ name: "response" }}
            fullWidth={true}
            multiline
            rows={4}
            onChange={onChangeBlob}
          />
          {componentType === ShowOptionsComponentType.DescQuestion ? (
            <Grid item xs={12}>
              <TextField
                label="Vooraf ingevuld Antwoord"
                placeholder="Voer uw vooraf ingevulde antwoord in"
                fullWidth
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  onUpdateDescQuestion(event, "answer");
                }}
                value={
                  selectedNodeData.newNodeData.options.DescQuestions.find(
                    (question: ITSMDescriptionQuesion) => question.id === optionsId,
                  )!.answer
                }
              />
            </Grid>
          ) : null}
        </>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              {/* <ShowNewAnswerButton executable={onAddItem} componentLabel={componentLabel} componentType={componentType} blobData={blobData} /> */}
              {blobData !== undefined && (
                <CreateAnswerDialog
                  executable={onAddItem}
                  language={process.env.REACT_APP_GLOBAL_LANGUAGE!}
                  typePredetermined={componentType}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth={true} className={`${styles.FormControl} ${styles.TextField}`}>
              <InputLabel id="question-select-label">{componentLabel} selectie</InputLabel>
              <Select
                labelId="questionSelectionLabel"
                id="questionSelection"
                label={`${componentLabel} selectie`}
                onChange={onChangeSelection}
                value={""}
              >
                {options?.map((option: Answer) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      <AddNewLanguageDialog
        isOpen={isNewLanguageDialogOpen}
        handleClose={() => {
          setIsNewLanguageDialogOpen(false);
        }}
        languages={languagesNotUsed}
        addLanguage={(languageText: LanguageText) => {
          AddLanguage(languageText);
        }}
        removeLanguage={(languageKey: string) => {
          removeLanguage(languageKey);
        }}
        dialogLanguage={""}
        dialogValue=""
        title=""
        isEdit={false}
      />
    </Stack>
  );
};

export default ShowOptions;

import React, { FC } from "react";

// MSAL Imports
import { IPublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";

// Page Imports
import Home from "./home/Home";
import Authenticator from "./home/Authenticator";

// Material Imports
import { CssBaseline } from "@mui/material";

// Context Imports
import { StagingContextProvider } from "../contexts/StagingContext";
import { ProductionContextProvider } from "../contexts/ProductionContext";
import { ExternalStorageContextProvider } from "../contexts/ChangeTracking/ExternalStorageContext";
import { NLUContextProvider } from "../contexts/NLU/NLUContext";
import { LocalStorageContextProvider } from "../contexts/ChangeTracking/LocalStorageContext";
import { ErrorContextProvider } from "../contexts/ErrorContext";
import { IntentGroupContextProvider } from "../contexts/NLU/IntentGroupContext";
import Webchat from "../components/Webchat/Webchat";

interface AppProps {
  pca: IPublicClientApplication;
}

/**
 * Renders the application
 * @param pca - The public client application
 * @returns A React component representing the application
 */
const App: FC<AppProps> = ({ pca }) => (
  <MsalProvider instance={pca}>
    <CssBaseline />
    <AuthenticatedTemplate>
      <ErrorContextProvider>
        <StagingContextProvider>
          <ProductionContextProvider>
            <NLUContextProvider>
              <IntentGroupContextProvider>
                <ExternalStorageContextProvider>
                  <LocalStorageContextProvider>
                    <Home />
                    <Webchat />
                  </LocalStorageContextProvider>
                </ExternalStorageContextProvider>
              </IntentGroupContextProvider>
            </NLUContextProvider>
          </ProductionContextProvider>
        </StagingContextProvider>
      </ErrorContextProvider>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Authenticator />
    </UnauthenticatedTemplate>
  </MsalProvider>
);

export default App;

import React, { FC, ChangeEvent, useEffect, useState } from "react";

// MUI Imports
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

// Component imports
import { FaqItem, faqItem } from "../../models/Faqs";

// Helper functions imports
import { deepCopy } from "../../helpers/deepCopy";

interface CreateFaqDialogProps {
  handleFaqUpload: (newFaq: FaqItem) => void;
  allFaq: Array<FaqItem>;
  disabled: boolean;
}

/**
 * A functional component representing the 'CreateFaqDialog'.
 * @param handleFaqUpload - Function to handle uploading the new faq when content is submitted.
 * @param allFAQs - All Faq items that currently exist
 * @returns JSX element representing the 'CreateFaqDialog'.
 */
const CreateFaqDialog: FC<CreateFaqDialogProps> = ({ handleFaqUpload, allFaq, disabled }) => {
  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");

  const [faq, setFaq] = useState<FaqItem>(deepCopy(faqItem));
  const [isOpen, setOpen] = useState<boolean>(false);

  const [questionError, setQuestionError] = useState<string>("");

  /** Open with a new default faq */
  useEffect(() => {
    if (isOpen) {
      setFaq(deepCopy(faqItem));
    }
  }, [isOpen]);

  /** Check if name is not duplicate */
  useEffect(() => {
    const duplicate: boolean = allFaq.some((someFaq) => someFaq.question === faq.question);
    setQuestionError(duplicate ? "Faq vraag komt al eerder voor" : "");
    setQuestionError(validateQuestion(faq.question) ? "" : "Dit is geen geldige vraag");
  }, [faq.question]);

  /** Handles validating Url safe question input */
  const validateQuestion = (question: string): boolean => {
    if (question === "") {
      return true;
    }

    // Allow only letters, numbers, comma and question mark
    const regex = /^[a-zA-Z0-9\s,'?]+$/;

    return regex.test(question);
  };

  /** Handles opening the dialog with the specified scroll type. */
  const handleOpen = (scrollType: DialogProps["scroll"]) => (): void => {
    setScroll(scrollType);
    setOpen(true);
  };

  /** Handles setting several input values */
  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, property: string): void => {
    const updatedFaq = { ...faq };

    event.preventDefault();
    switch (property) {
      case "question":
        updatedFaq.question = event.target.value;
        break;
      case "answer":
        updatedFaq.answer = event.target.value;
        break;
    }
    setFaq(updatedFaq);
  };

  // The variable that handles live updating form validation
  const validSubmission: boolean = [faq.question !== "", faq.answer !== "", questionError === ""].every(Boolean);

  /** Handles closing the dialog. */
  const handleClose = (): void => {
    setOpen(false);
  };

  /** Handle submitting a new Faq */
  const handleSubmit = (): void => {
    handleFaqUpload(faq);
    handleClose();
  };

  return (
    <div data-testid="create-faq-dialog">
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOpen("paper")}
        startIcon={<LibraryBooksIcon />}
        sx={{ height: 50 }}
        data-testid="button-add-faq"
        disabled={disabled}
      >
        FAQ toevoegen
      </Button>
      {isOpen ? (
        <Dialog data-testid="dialog" open={isOpen} onClose={handleClose} scroll={scroll}>
          <DialogTitle>Nieuwe FAQ</DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText>Vul de benodigde informatie in om een nieuwe FAQ aan te maken.</DialogContentText>
            <span
              style={{
                right: "5px",
                top: "5px",
                position: "absolute",
                color: "#8b8b8b",
                fontSize: "17px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              X
            </span>
            <Grid container gap={2} paddingTop={2}>
              <Grid item xs={12}>
                <TextField
                  inputProps={{ "data-testid": "question-textfield" }}
                  id="input-question"
                  size="small"
                  label="Vraag"
                  fullWidth
                  helperText={questionError}
                  error={questionError !== ""}
                  value={faq.question}
                  onChange={(event) => {
                    handleInputChange(event, "question");
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputProps={{ "data-testid": "answer-textfield" }}
                  id="input-answer"
                  size="small"
                  label="Antwoord"
                  fullWidth
                  value={faq.answer}
                  onChange={(event) => {
                    handleInputChange(event, "answer");
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button data-testid="confirm-button" color="primary" disabled={!validSubmission} onClick={handleSubmit}>
              Opslaan
            </Button>
            <Button data-testid="cancel-button" color="error" onClick={handleClose}>
              Annuleren
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
};

export default CreateFaqDialog;

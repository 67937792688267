import React, { FC, useReducer, ReactNode, useContext, createContext, Dispatch, useEffect } from "react";

// Data Imports
import { ApiBlobResponse, StorageEnvironment } from "../../API/StorageInteraction";

// Component Imports
import {
  ChangeBucket,
  ChangeOrigin,
  DrawerItem,
  TrackedChange,
  drawerItems,
  changeBucketTemplateArray,
} from "../../models/ChangeTracking";
import { deepCopy } from "../../helpers/deepCopy";
import {
  CreateChangeInBucket,
  DeleteChangeFromBucket,
  getBuckets,
  LiveChanges,
  UpdateBucketWithChange,
  UpdateBucketWithChanges,
} from "../../API/ChangeManagementInteraction";

// Region container context

// Builds the context that provides the producer context
const ExternalStorageContext = createContext<externalStorageContextAttributes | null>(null);

// Builds the context that provides the function that lets components dispatch actions
const ExternalStorageDispatchContext = createContext<Dispatch<any> | null>(null);

// Initial producer state provided to the reducer in the provider
const initialExternalStorageContext: externalStorageContextAttributes = {
  changeBuckets: deepCopy(changeBucketTemplateArray),
  drawerItems: [...drawerItems.sort((a, b) => a.index - b.index)],
};

export enum ExternalStorageActionType {
  "set",
  // "persistChanges",
  // "clearPersistChanges",
  "trackChange",
  "updateChange",
  "updateChanges",
  "untrackChange",
  "saveChanges",
}

interface externalStorageContextProviderProps {
  children: ReactNode | Array<ReactNode> | null;
}

export type externalStorageContextAttributes = {
  changeBuckets: Array<ChangeBucket>;
  drawerItems: Array<DrawerItem>;
  setChanges?: (buckets: Array<ChangeBucket>) => void;
  getChanges?: (callback?: (changesForCallback: Array<ChangeBucket>) => Promise<void>) => Promise<void>;
  trackChange?: (update: TrackedChange) => void;
  updateChange?: (update: TrackedChange) => void;
  updateChanges?: (update: ChangeBucket) => void;
  untrackChange?: (update: TrackedChange) => void;
  trackedChangeExists?: (update: TrackedChange) => boolean;
  getChangeByIdInOrigin?: (changeKey: string, changeOrigin: ChangeOrigin) => TrackedChange | undefined;
  saveChanges?: () => Promise<void>;
};

/**
 * Provides the producer context to the application
 * @param children - The children of the container
 * @returns  A provider element
 */
export const ExternalStorageContextProvider: FC<externalStorageContextProviderProps> = ({ children }) => {
  // const stagingContext = useStagingContext();
  const [context, dispatch] = useReducer(externalStorageReducer, initialExternalStorageContext);

  useEffect(() => {
    // Retrieve the existing saved changes
    getChanges().catch((error) => {
      throw new Error(`Failed to retrieve persistent change data: ${error}`);
    });
  }, []);

  /**
   * Context function that allows the changeBuckets to be replaced with a fresh copy.
   */
  const setChanges = (buckets: Array<ChangeBucket>): void => {
    const action: externalStorageReducerAction = {
      type: ExternalStorageActionType.set,
      payload: deepCopy(buckets),
    };

    dispatch(action);
  };

  /**
   * Get the persistent changes for staging to production from the blob storage
   */
  const getChanges = async (callback?: (changesForCallback: Array<ChangeBucket>) => Promise<void>): Promise<void> => {
    try {
      // Get the container and find the change bucket array
      const changes = await getBuckets(StorageEnvironment.Staging);
      if (changes === undefined) return;

      // Build a dispatch action
      const action: externalStorageReducerAction = {
        type: ExternalStorageActionType.set,
        payload: changes,
      };

      dispatch(action);
      if (callback != null) await callback(changes);
    } catch (error) {
      throw new Error(`Error fetching changes: ${error}`);
    }
  };

  /**
   * Context function that adds a tracked change to it's corresponding bucket
   * @param update - Generated by a component to be put into context
   */
  const trackChange = (update: TrackedChange): void => {
    // Get the change bucket
    const updatedBucket: ChangeBucket = context.changeBuckets.find(
      (bucket: ChangeBucket) => bucket.key === update.origin.containerName,
    )!;

    // Add the change to the bucket
    updatedBucket.changes.push(update);

    // Build a dispatch action
    const action: externalStorageReducerAction = {
      type: ExternalStorageActionType.trackChange,
      payload: updatedBucket,
    };

    // Update the change tracking
    void CreateChangeInBucket(StorageEnvironment.Staging, update);

    dispatch(action);
  };

  /**
   * Context function that updates a tracked change inside it's corresponding bucket
   * (may not be intended behavior in it's current state)
   * @param update - Generated by a component to be put into context
   */
  const updateChange = (update: TrackedChange): void => {
    // Get the change bucket
    const updatedBucket: ChangeBucket = context.changeBuckets.find(
      (bucket: ChangeBucket) => bucket.key === update.origin.containerName,
    )!;

    // find the change and apply the updated version
    const updatedChange = updatedBucket.changes.find((change) => change.key === update.key)!;
    const index = updatedBucket.changes.indexOf(updatedChange);
    updatedBucket.changes[index] = update;

    // Update the change tracking
    void UpdateBucketWithChange(StorageEnvironment.Staging, update);

    // Build a dispatch action
    const action: externalStorageReducerAction = {
      type: ExternalStorageActionType.updateChange,
      payload: updatedBucket,
    };

    dispatch(action);
  };

  /**
   * Context function that updates a corresponding bucket
   * (may not be intended behavior in it's current state)
   * @param update - Generated by a component to be put into context
   */
  const updateChanges = (update: ChangeBucket): void => {
    // Get the change bucket
    const updatedBucket: ChangeBucket = context.changeBuckets.find(
      (bucket: ChangeBucket) => bucket.key === update.key,
    )!;

    // find the change and apply the updated version
    updatedBucket.changes = update.changes.map((change) => ({
      ...change,
    }));

    // Update the change tracking
    void UpdateBucketWithChanges(StorageEnvironment.Staging, update.changes);

    // Build a dispatch action
    const action: externalStorageReducerAction = {
      type: ExternalStorageActionType.updateChange,
      payload: updatedBucket,
    };

    dispatch(action);
  };

  /**
   * Context function that removes a tracked change from it's corresponding bucket
   * @param update - Generated by a component to be put into context
   */
  const untrackChange = (update: TrackedChange): void => {
    // Get the change bucket
    const updatedBucket: ChangeBucket = context.changeBuckets.find(
      (bucket: ChangeBucket) => bucket.key === update.origin.containerName,
    )!;

    // Remove the change from the bucket
    const index = updatedBucket.changes.indexOf(update);
    if (index > -1) {
      updatedBucket.changes.splice(index, 1);
    }

    // Delete the untracked change from change tracking
    void DeleteChangeFromBucket(StorageEnvironment.Staging, update);

    // Build a dispatch action
    const action: externalStorageReducerAction = {
      type: ExternalStorageActionType.untrackChange,
      payload: updatedBucket,
    };

    dispatch(action);
  };

  /**
   * Context function that allows for checking whether the change to track already exists.
   * Keeping in mind that every update has a unique id, we check by update's key.
   * @param key - the key of the item related to the change.
   * @param containerName - the related bucket to find by it's container name.
   */
  const trackedChangeExists = (update: TrackedChange): boolean => {
    // Get the change bucket
    const bucket: ChangeBucket = context.changeBuckets.find(
      (bucket: ChangeBucket) => bucket.key === update.origin.containerName,
    )!;

    const changeExists = bucket.changes.find((change) => change.key === update.key);

    if (changeExists !== undefined) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Context function that allows an item (if it exists) to be retrieved from a change bucket
   */
  const getChangeByIdInOrigin = (changeKey: string, changeOrigin: ChangeOrigin): TrackedChange | undefined => {
    // Get the change bucket
    const bucket: ChangeBucket = context.changeBuckets.find(
      (bucket: ChangeBucket) => bucket.key === changeOrigin.containerName,
    )!;

    const change: TrackedChange = bucket.changes.find((change) => change.key === changeKey)!;

    if (change !== undefined) {
      return change;
    } else {
      return undefined;
    }
  };

  /**
   * Initiate the process of saving changes to the staging in the backend.
   */
  const saveChanges = async (): Promise<void> => {
    // Save the changes with the buckets of the local context
    const response = await LiveChanges();

    // Build a dispatch action
    const action: externalStorageReducerAction = {
      type: ExternalStorageActionType.saveChanges,
      payload: response,
    };

    dispatch(action);
  };

  // Build the context
  const _context: externalStorageContextAttributes = {
    changeBuckets: context.changeBuckets,
    drawerItems: context.drawerItems,
    setChanges,
    getChanges,
    trackChange,
    updateChange,
    updateChanges,
    untrackChange,
    trackedChangeExists,
    getChangeByIdInOrigin,
    saveChanges,
  };

  return (
    <ExternalStorageContext.Provider value={_context}>
      <ExternalStorageDispatchContext.Provider value={dispatch}>{children}</ExternalStorageDispatchContext.Provider>
    </ExternalStorageContext.Provider>
  );
};

/**
 * Provides the container context
 * @returns A context
 */
export const useExternalStorageContext = (): externalStorageContextAttributes | null =>
  useContext(ExternalStorageContext);

/**
 * Provides the function that lets components dispatch actions
 * @returns A dispatch context
 */
export const useExternalStorageDispatch = (): Dispatch<any> | null => useContext(ExternalStorageDispatchContext);

// #endregion

// #region external storage reducer
export type externalStorageReducerAction = {
  type: ExternalStorageActionType;
  payload?: Array<ApiBlobResponse> | ApiBlobResponse | ChangeBucket | Array<ChangeBucket>;
};

/**
 * SetChanges is used to set the changed containers, setLivables is used to set the containers
 * that will be pushed to the production containers.
 * @param context - The state to be updated
 * @param action - The action to be executed by the reducer
 * @returns Updated state
 */
export const externalStorageReducer = (
  context: externalStorageContextAttributes,
  action: externalStorageReducerAction,
): externalStorageContextAttributes => {
  switch (action.type) {
    case ExternalStorageActionType.set:
    case ExternalStorageActionType.saveChanges: {
      // case ExternalStorageActionType.persistChanges:
      // case ExternalStorageActionType.clearPersistChanges: {
      const changes = action.payload as Array<ChangeBucket>;

      return { ...context, changeBuckets: changes } satisfies externalStorageContextAttributes;
    }
    case ExternalStorageActionType.trackChange:
    case ExternalStorageActionType.untrackChange:
    case ExternalStorageActionType.updateChange:
    case ExternalStorageActionType.updateChanges: {
      const updatedBucket = action.payload as ChangeBucket;
      const updatedBuckets: Array<ChangeBucket> = context.changeBuckets.map((bucket: ChangeBucket) => {
        if (bucket.key === updatedBucket.key) {
          return updatedBucket;
        } else {
          return bucket;
        }
      });

      return { ...context, changeBuckets: updatedBuckets } satisfies externalStorageContextAttributes;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

// #endregion

// #region producer types

export type producerContainerPayload = {
  stagingContainers: Array<ApiBlobResponse>;
  productionContainers: Array<ApiBlobResponse>;
};

// #endregion

import React, { FC } from "react";

// Component Imports
import { Box, LinearProgress, Stack, Typography } from "@mui/material";

// Asset Imports
import { ReactComponent as UtteranceLoadingIcon } from "../../../../assets/utterance_loading_icon.svg";

/**
 * A skeleton in the form of a displayed utterance list.
 */
const UtteranceLoadingIndicator: FC = () => (
  <>
    <Box width={"100%"} height={"100%"} justifyContent={"center"} alignItems={"center"}>
      <Stack
        direction="column"
        spacing={1}
        width={"100%"}
        height={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <UtteranceLoadingIcon />
        <Typography variant={"h5"} gutterBottom fontFamily={"sans-serif"}>
          Voorbeeldzinnen Laden...
        </Typography>
        <Box width={"50%"}>
          <LinearProgress />
        </Box>
      </Stack>
    </Box>
  </>
);

export default UtteranceLoadingIndicator;

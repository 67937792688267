import { MessageType } from "./enums";

export const containerNames = [
  "answers",
  "dialogs",
  "faqs",
  "groupincidents",
  "highlights",
  "intents",
  "intentgroups",
  "knowledgesources",
];

export enum ContainerNames {
  Answers = "answers",
  Dialogs = "dialogs",
  Faqs = "faqs",
  GroupIncidents = "groupincidents",
  Highlights = "highlights",
  Intents = "intents",
  IntentGroups = "intentgroups",
  KnowledgeSources = "knowledgesources",
}

export const ApiCompatibleContainerNames = [
  "Answers",
  "Dialogs",
  "GroupIncidents",
  "Highlights",
  "Intents",
  "IntentGroups",
  "KnowledgeSources",
];

export const messageTextType = [
  { key: MessageType.MESSAGE, value: "Message" },
  { key: MessageType.BUTTONTEXT, value: "Button Text" },
  { key: MessageType.QUESTION, value: "Question" },
];

export enum GridRowSaveOption {
  StagingSave,
  LocalSave,
}

import React, { FC, useState } from "react";

// Component Imports
import {
  Box,
  Chip,
  Collapse,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Tab,
  Tabs,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

// Data Imports
import { findDifference, findDifferenceInChildren } from "../../../helpers/ObjectDifferences";
import { ContainerNames } from "../../../models/enums";
import { DialogVersion, DialogStep } from "../../../models/Dialogs";

interface AccordionContentDialogProps {
  record: Record<string, any>;
  differences?: Record<string, boolean | Record<string, boolean>>;
}

/**
 * An accordion content item for highlights.
 * @param record - The change whose information must be displayed.
 * @param order - the order in which the changes must be displayed, gained from a change's displayOrder property.
 */
const AccordionContentDialog: FC<AccordionContentDialogProps> = ({ record, differences }) => {
  // state
  const [stepsOpen, setStepsOpen] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);

  /**
   * function to reduce code required in setting bg colors on differences.
   * @param set - whether it should be set or not.
   */
  const setBackgroundColor = (set: boolean): string => {
    if (set === undefined) return "white";
    else return set ? "rgba(0, 100, 180, 0.20)" : "white";
  };

  /**
   * Handle the opening of the languages section.
   */
  const handleStepsOpen = (): void => {
    setStepsOpen(!stepsOpen);
  };

  /**
   * Handle the changing of a tab.
   * @param event - The event that triggered the handler.
   * @param newTabIndex - the new tab index.
   */
  const onTabChange = (event: React.SyntheticEvent, newTabIndex: number): void => {
    setTabIndex(newTabIndex);
  };

  return (
    <List key={`dialog-change-preview`} disablePadding sx={{ width: "100%" }}>
      <ListItem
        key={`dialog-change-preview-name`}
        sx={{
          backgroundColor: setBackgroundColor(findDifference("name", differences)),
        }}
      >
        <ListItemAvatar>
          <Chip sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }} label={`Naam`} />
        </ListItemAvatar>
        <ListItemText>{record.name}</ListItemText>
      </ListItem>
      <ListItem
        key={`dialog-change-preview-intent`}
        sx={{
          backgroundColor: setBackgroundColor(findDifference("intent", differences)),
        }}
      >
        <ListItemAvatar>
          <Chip sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }} label={`Intent`} />
        </ListItemAvatar>
        <ListItemText>{record.intent}</ListItemText>
      </ListItem>
      <ListItemButton
        key={`dialog-change-preview-versions`}
        sx={{
          backgroundColor: setBackgroundColor(findDifference("versions", differences)),
        }}
        onClick={handleStepsOpen}
      >
        <ListItemAvatar>
          <Chip sx={{ backgroundColor: "rgba(0, 100, 180, 0.25)", marginRight: "5px" }} label={`Versies`} />
        </ListItemAvatar>
        <ListItemSecondaryAction>{stepsOpen ? <ExpandLess /> : <ExpandMore />}</ListItemSecondaryAction>
      </ListItemButton>
      <Collapse in={stepsOpen} timeout={"auto"} unmountOnExit>
        <Tabs
          value={tabIndex}
          onChange={onTabChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ width: "100%", backgroundColor: setBackgroundColor(findDifference(ContainerNames.Dialogs)) }}
        >
          {record.versions.map((version: DialogVersion, index: number) => (
            <Tab
              key={`dialog-change-preview-tab-${index}`}
              label={`Versie ${version.version}`}
              sx={{ backgroundColor: setBackgroundColor(findDifferenceInChildren(version.version, differences)) }}
            />
          ))}
        </Tabs>
        <Box sx={{ width: "100%" }}>
          <List sx={{ width: "inherit" }}>
            {record.versions[tabIndex].steps.map((step: DialogStep, index: number) => (
              <AccordionContentDialogStep
                key={`dialog-change-preview-version-step-${index}`}
                step={step}
                index={index}
              />
            ))}
          </List>
        </Box>
      </Collapse>
    </List>
  );
};

interface AccordionContentDialogStepProps {
  step: DialogStep;
  index: number;
}

/**
 * a component that displays information of a single step of a dialog.
 * @param step - The step that will be displayed in this component
 * @returns AccordionContentDialogStep
 */
const AccordionContentDialogStep: FC<AccordionContentDialogStepProps> = ({ step, index }) => {
  // State
  const [open, setOpen] = useState<boolean>(false);

  /**
   * handle the opening of this step.
   */
  const handleOpen = (): void => {
    setOpen(!open);
  };

  /**
   * function to reduce code required in setting bg colors on differences.
   * @param set - whether it should be set or not.
   */
  // const setBackgroundColor = (set: boolean): string => {
  //   if (set === undefined) return "white";
  //   else return set ? "rgba(0, 100, 180, 0.20)" : "white";
  // };

  return (
    <>
      {
        // Object.entries(step.options).length > 1 ?
        //   <>
        //     <ListItemButton
        //       key={`dialog-change-preview-version-step-${index}`}
        //       onClick={handleOpen}
        //     >
        //       <ListItemText>
        //         {step.name}
        //       </ListItemText>
        //       <ListItemSecondaryAction>
        //         {open ? <ExpandLess /> : <ExpandMore />}
        //       </ListItemSecondaryAction>
        //     </ListItemButton>
        //     <Collapse in={open} timeout="auto" unmountOnExit>
        //       <List>
        //         <ListItem key={`dialog-change-preview-version-step-${index}-type`}>
        //           <ListItemText>
        //             {step.type}
        //           </ListItemText>
        //         </ListItem>
        //         <AccordionContentDialogStepOptions options={step.options} pIndex={index} />
        //       </List>
        //     </Collapse>
        //   </>
        //   :
        <>
          <ListItem key={`dialog-change-preview-version-step-${index}`} onClick={handleOpen}>
            <ListItemText>{step.name}</ListItemText>
          </ListItem>
        </>
      }
    </>
  );
};

// interface AccordionContentDialogStepOptionsProps {
//   options: any
//   pIndex: number
// }

/**
 * a component that displays information of a single step of a dialog.
 * @param step - The step that will be displayed in this component
 * @returns AccordionContentDialogStep
 */
// const AccordionContentDialogStepOptions: FC<AccordionContentDialogStepOptionsProps> = ({ options, pIndex }) => {
//   // State
//   const [open, setOpen] = useState<boolean>(false);
//   const [buttonsOpen, setButtonsOpen] = useState<boolean>(false);

//   /**
//    * handle the opening of this step.
//    */
//   const handleOpen = (): void => {
//     setOpen(!open);
//   }

//   /**
//    * handle the opening of this step.
//    */
//   const handleButtonsOpen = (): void => {
//     setButtonsOpen(!buttonsOpen);
//   }

//   return (
//     <>
//       <ListItemButton
//         key={`dialog-change-preview-version-step-${pIndex}-options`}
//         onClick={handleOpen}
//       >
//         <ListItemText>
//           {`Opties`}
//         </ListItemText>
//         <ListItemSecondaryAction>
//           {open ? <ExpandLess /> : <ExpandMore />}
//         </ListItemSecondaryAction>
//       </ListItemButton>
//       <Collapse in={open} timeout="auto" unmountOnExit>
//         <List>
//           <ListItem key={`dialog-change-preview-version-step-${pIndex}-isanswerorquestion`}>
//             <ListItemText>
//               {
//                 options.hasOwnProperty("isAdminAnswer") === true ? options.isAdminAnswer :
//                   options.hasOwnProperty("isAdminQuestion") === true ? options.isAdminQuestion :
//                     undefined
//               }
//             </ListItemText>
//           </ListItem>
//           <ListItem key={`dialog-change-preview-version-step-${pIndex}-responseorquestion`}>
//             <ListItemText>
//               {
//                 options.hasOwnProperty("adminResponse") === true ? options.adminResponse :
//                   options.hasOwnProperty("adminQuestion") === true ? options.adminQuestion :
//                     undefined
//               }
//             </ListItemText>
//           </ListItem>
//           <ListItem key={`dialog-change-preview-version-step-${pIndex}-selectedresponseorquestion`}>
//             <ListItemText>
//               {
//                 options.hasOwnProperty("selectedResponse") === true ? options.selectedResponse :
//                   options.hasOwnProperty("selectedQuestion") === true ? options.selectedQuestion :
//                     undefined
//               }
//             </ListItemText>
//           </ListItem>
//           {options.hasOwnProperty("buttons") === true &&
//             <>
//               <ListItemButton
//                 key={`dialog-change-preview-version-step-${pIndex}-options-buttons`}
//                 onClick={handleButtonsOpen}
//               >
//                 <ListItemText>
//                   {`Knoppen`}
//                 </ListItemText>
//                 <ListItemSecondaryAction>
//                   {open ? <ExpandLess /> : <ExpandMore />}
//                 </ListItemSecondaryAction>
//               </ListItemButton>
//               <Collapse in={buttonsOpen} timeout="auto" unmountOnExit>
//                 <List>
//                   {
//                     options.buttons?.map((button: any, index: number) =>
//                       <ListItem key={`dialog-change-preview-version-step-${pIndex}-options-buttons-${index}`}>
//                         <ListItemText>
//                           {button.value}
//                         </ListItemText>
//                       </ListItem>)
//                   }
//                 </List>
//               </Collapse>
//             </>
//           }
//         </List >
//       </Collapse >
//     </>
//   )
// }

export default AccordionContentDialog;

export const dialog: Dialog = {
  key: "",
  name: "",
  intent: "",
  versions: [],
};

export type Dialog = {
  key: string;
  name: string;
  intent: string;
  versions: Array<DialogVersion>;
};

export type DialogVersion = {
  version: string;
  active: boolean;
  steps: Array<DialogStep>;
};

export interface DialogStep {
  id: string;
  name: string;
  nextStep?: string;
  options: any;
  type: string;
}

export type ButtonOption = {
  id: string;
  key: string;
  nextStep: string;
  isHidden: boolean;
};

import React, { FC } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import SignInOutButton from "../../pages/home/SignInOutButton";
import { InteractionStatus } from "@azure/msal-browser";

/**
 * SignInOut Component
 * @returns A React component representing the SignInOut to display and edit the dialogs.
 */
const SignInOut: FC = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    return <SignInOutButton buttonType="logout" />;
  } else if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
    return <SignInOutButton buttonType="login" />;
  } else {
    return null;
  }
};

export default SignInOut;

/**
 * Check whether there are differences between two values.
 * @param oldObj - The old object.
 * @param newObj - The new object.
 */
export const CheckItemDifference = (
  oldObj: Record<string, any>,
  newObj: Record<string, any>,
): Record<string, boolean | Record<string, boolean>> => {
  const difference: Record<string, boolean | Record<string, boolean>> = {};

  for (const [key, value] of Object.entries(oldObj)) {
    // check whether new object has the key
    if (newObj.hasOwnProperty(key)) {
      // if the value is an object,
      if (typeof value === "object" && !Array.isArray(value)) {
        // check the difference between the objects
        difference[key] = CheckItemDifference(oldObj[key], newObj[key]) as Record<string, boolean>;
      }

      // if the value is an array,
      else if (Array.isArray(value)) {
        if (value.length !== newObj[key].length) {
          difference[key] = true;
        } else {
          // check the difference between the arrays
          const arrayDifference: Array<boolean> = value.map(
            (item, index) => JSON.stringify(item) !== JSON.stringify(newObj[key][index]),
          );
          difference[key] = arrayDifference.includes(true);
        }
      }

      // a difference has been identified.
      else {
        difference[key] = oldObj[key] !== newObj[key];
      }
    }

    // if the new object did not include the key, there is a difference.
    else {
      difference[key] = true;
    }
  }

  for (const key of Object.keys(newObj)) {
    // check whether old object is missing a key
    if (!oldObj.hasOwnProperty(key)) {
      difference[key] = true;
    }
  }

  return difference;
};

/**
 * find a difference by it's key in the set of differences.
 * @param key - the key of the record whose difference to find.
 * @param obj - the record to find a difference within.
 */
export const findDifference = (key: string, obj?: Record<string, boolean | Record<string, boolean>>): boolean => {
  if (obj === undefined) return false;

  for (const [k, v] of Object.entries(obj)) {
    if (k === key) {
      if (v instanceof Object) {
        const found = findDifference(k, v);
        if (!found) continue;
        else return found;
      } else return v;
    }
  }

  return false;
};

/**
 * find a difference by it's key in the set of differences, but check whether there is a difference in it's children instead.
 * @param key - the key of the record whose difference to find.
 * @param obj - the record to find a difference within.
 */
export const findDifferenceInChildren = (
  key: string,
  obj?: Record<string, boolean | Record<string, boolean>>,
): boolean => {
  if (obj === undefined) return false;

  let foundInChild = false;

  for (const [k, v] of Object.entries(obj)) {
    if (k === key) {
      if (v instanceof Object) {
        foundInChild = findDifferenceInChildren(k, v);
        if (!foundInChild) continue;
        else return foundInChild;
      } else return foundInChild;
    }
  }

  return false;
};

/**
 * find a difference by it's key in the set of differences, but check whether there is a difference in it's children instead.
 * @param key - the key of the record whose difference to find.
 * @param obj - the record to find a difference within.
 */
export const findDifferenceInChildrenKeyless = (obj?: Record<string, boolean | Record<string, boolean>>): boolean => {
  if (obj === undefined) return false;

  let foundInChild = false;

  for (const [k, v] of Object.entries(obj)) {
    if (v instanceof Object) {
      foundInChild = findDifferenceInChildren(k, v);
      if (!foundInChild) continue;
      else return foundInChild;
    } else return foundInChild;
  }

  return false;
};

import React, { FC, useState } from "react";

// Component Imports
import { Box, Drawer, List, ListItemText, ListItemButton, Skeleton, Badge, ListItem } from "@mui/material";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { DrawerHeader } from "./DrawerHeaderSpacer";

// Data Imports
import { ChangeBucket, DrawerItem } from "../../models/ChangeTracking";

// Context Imports
import { useExternalStorageContext } from "../../contexts/ChangeTracking/ExternalStorageContext";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { useLocalStorageContext } from "../../contexts/ChangeTracking/LocalStorageContext";

interface FlyoutDrawerProps {
  drawerOpen: boolean;
  drawerWidth: number;
  isLoading: boolean;
}

/**
 * FlyoutDrawer Component
 * @param drawerOpen - Whether the drawer is open.
 * @param isLoading - Whether the data is loading.
 * @returns A React component representing the FlyoutDrawer to display and edit the dialogs.
 */
const FlyoutDrawer: FC<FlyoutDrawerProps> = ({ drawerOpen, isLoading, drawerWidth }) => {
  // Initialize navigation hook
  const navigate = useNavigate();

  // #endregion
  return (
    <Drawer
      key="app-drawer"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
        "& .MuiDrawer-docked": {
          height: "100%",
        },
      }}
      open={drawerOpen}
      anchor="left"
      variant="persistent"
    >
      <Box>
        <DrawerHeader />
        <DrawerContents navigate={navigate} isLoading={isLoading} />
      </Box>
    </Drawer>
  );
};

interface DrawerContentsProps {
  navigate: NavigateFunction;
  isLoading: boolean;
}

/**
 * Builds the list of items that will be placed in the drawer
 * @param DrawerItems - properties that these items should have
 * @param navigate - a navigate function that allows these buttons to link through pages with react-router-dom
 * @returns  a MUIList component with ListItems children
 */
const DrawerContents: FC<DrawerContentsProps> = ({ navigate, isLoading }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const externalStorageContext = useExternalStorageContext();
  const localStorageContext = useLocalStorageContext();

  /**
   * Handles the list item click.
   * @param index - The index.
   * @param path - The path.
   * @returns void
   */
  const handleListItemClick = (index: number, path: string): void => {
    setSelectedIndex(index);
    navigate(path);
  };

  /**
   * read the correct bucket during the mapping.
   */
  const handleReadBucketInMap = (item: DrawerItem): ChangeBucket | undefined => {
    if (externalStorageContext === null || !Array.isArray(externalStorageContext.changeBuckets)) return;

    const bucket = externalStorageContext.changeBuckets.find((bucket) => bucket.key === item.key);
    if (bucket !== undefined) return bucket;
  };

  if (externalStorageContext !== null && localStorageContext !== null) {
    return (
      <List key={"page-routes-drawer"}>
        {externalStorageContext?.drawerItems.map((item, index) => (
          <ListItem
            key={item.key}
            dense
            disablePadding
            divider
            secondaryAction={<DrawerBadge changeBucket={handleReadBucketInMap(item)} />}
          >
            <ListItemButton
              disabled={isLoading}
              selected={selectedIndex === index}
              key={item.key}
              onClick={() => {
                handleListItemClick(index, item.path);
              }}
              sx={[
                {
                  "&.Mui-selected": {
                    backgroundColor: "rgba(30, 110, 240, 0.5)",
                    "&.MuiListItemText-root": {
                      backgroundColor: "rgba(0, 0, 0, 0)",
                      color: "white",
                    },
                  },
                },
                {
                  "&.Mui-selected:hover": {
                    backgroundColor: "rgba(5, 90, 240, 0.75)",
                    "&.MuiListItemText-root": {
                      backgroundColor: "rgba(0, 0, 0, 0)",
                      color: "white",
                    },
                  },
                },
                {
                  "&:hover": {
                    backgroundColor: "rgba(75, 150, 240, 0.5)",
                    "& .MuiListItemText-root": {
                      backgroundColor: "rgba(0, 0, 0, 0)",
                      color: "white",
                    },
                  },
                },
                {
                  width: "100%",
                },
              ]}
            >
              {isLoading ? (
                <Skeleton animation="wave" width="100%" />
              ) : localStorageContext.changeBuckets.find((bucket) => bucket.key === item.key) === undefined ? (
                <ListItemText primary={`${item.text}`} />
              ) : (
                <ListItemText
                  primary={`${item.text}${
                    localStorageContext.changeBuckets.find((bucket) => bucket.key === item.key)!.changes.length > 0
                      ? "*"
                      : ""
                  }`}
                />
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  } else {
    return (
      <>
        <SkeletonLoader />
      </>
    );
  }
};

interface DrawerBadgeProps {
  changeBucket: ChangeBucket | undefined;
}

/**
 * The badge that will display the amount of changes in the drawer.
 * @param changeBucket - the change bucket associated with the drawer item
 */
const DrawerBadge: FC<DrawerBadgeProps> = ({ changeBucket }) => {
  if (changeBucket !== undefined) {
    return <Badge badgeContent={changeBucket.changes.length} color="primary" />;
  } else return null;
};

export default FlyoutDrawer;

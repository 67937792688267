import React, { FC } from "react";

// Material Imports
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export interface ConfirmationDialogProps {
  executable: () => void;
  title: string;
  button1Text: string;
  button1Color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined;
  button2Text: string;
  button2Color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined;
  fullWidth?: boolean;
  description?: string;
  bodyContent?: any;
  open: boolean;
  handleClose: () => void;
}

/**
 * A generic confirmation dialog.
 * @param open - The dialog open state.
 * @param handleClose - Function to handle the closing operation of the confirmation dialog.
 * @param title - The dialog title.
 * @param button1Color - Optional: The color of button 1.
 * @param button1Text - The text for button 1.
 * @param button2Color - Optional: The color of button 2.
 * @param button2Text - The text for button 2.
 * @param description - Optional: Additional description for the dialog.
 * @param bodyContent - Optional: The body content to display inside the dialog.
 * @returns The confirmation dialog component.
 */
const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  handleClose,
  title,
  button1Color,
  button1Text,
  button2Color,
  button2Text,
  description,
  bodyContent,
  executable,
  fullWidth,
}) => {
  /**
   * Handles confirmation selection, runs incoming executable, and handles incoming close function for the dialog.
   */
  const handleConfirm = async (): Promise<void> => {
    executable();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} scroll="paper" fullWidth={fullWidth} maxWidth="lg">
      <DialogTitle data-testid="dialog-title">{title}</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText id="dialog-description" data-testid="dialog-description">
          {description}
        </DialogContentText>
        {bodyContent}
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="confirm-button"
          name="confirm"
          onClick={() => {
            handleConfirm().catch((error) => {
              throw new Error(`Confirmation failed: ${error}`);
            });
          }}
          variant="contained"
          color={button1Color ?? "primary"}
        >
          {button1Text}
        </Button>
        <Button
          data-testid="cancel-button"
          name="cancel"
          onClick={() => {
            handleClose();
          }}
          variant="contained"
          color={button2Color ?? "error"}
        >
          {button2Text}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;

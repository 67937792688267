export const StepTypes = {
  Start: "Start",
  Finish: "Finish",
  Message: "Message",
  Conditional: "IfStatement",
  AskWithButtons: "AskWithButtonsDialog",
  YesNoDialog: "YesNoDialog",
  TextPrompt: "TextPrompt",
  CreateTicketDialog: "CreateTicketDialog",
  OpenAI: "OpenAI", // deze zo laten voor nu, voor de chatbot code
  Redirect: "Redirect",
};

export const StepTexts = {
  Message: "Bericht",
  Conditional: "Conditioneel",
  AskWithButtons: "Vraag Met Knoppen",
  YesNoDialog: "Ja/Nee Vraag",
  TextPrompt: "Open Vraag",
  CreateTicket: "Ticket Aanmaken",
  OpenAI: "LLM",
  Redirect: "Doorverwijzen",
};

import React, { FC } from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";
import { loginRequest } from "../../authConfig";

interface SignInOutButtonProps {
  buttonType: string;
}

/**
 * Renders a sign-out button
 * @param buttonType - button types are "login"
 * @returns Sign in or Sign out button based on buttonType
 */
const SignInOutButton: FC<SignInOutButtonProps> = ({ buttonType }) => {
  const { instance } = useMsal();

  /**
   * This handles logout
   * @param logoutType - logout type "popup" or "redirect"
   * @returns void
   */
  const handleLogout = (): void => {
    try {
      void instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    } catch (error) {
      throw new Error(`Failed to complete logout procedure, reason: ${error}`);
    }
  };

  /**
   * This handles login on button click
   * @param loginType - login type "popup" or "redirect"
   * @returns void
   */
  const handleLogin = (): void => {
    try {
      void instance.loginRedirect(loginRequest);
    } catch (error) {
      throw new Error(`Failed to complete login procedure, reason: ${error}`);
    }
  };

  return (
    <Button
      variant="contained"
      onClick={() => {
        buttonType === "login" ? handleLogin() : handleLogout();
      }}
    >
      {buttonType === "login" ? "Inloggen" : "Uitloggen"}
    </Button>
  );
};

export default SignInOutButton;

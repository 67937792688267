import axios, { AxiosRequestConfig } from "axios";
import { StorageEnvironment } from "./StorageInteraction";

// Auth Imports
import { webApiConfig } from "./ApiConfiguration";
import { getToken } from "./Auth/Auth";

// Data Imports
import { ChangeBucket, TrackedChange } from "../models/ChangeTracking";

/**
 * Get the change buckets from the backend.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @returns An array with the containers from the blob storage that can be put directly into the context.
 */
export const getBuckets = async (storageEnvironment: StorageEnvironment): Promise<Array<ChangeBucket>> => {
  try {
    const url: string = `${webApiConfig.endpointUrl}/ChangeTracking`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the GET request.
    const response = await axios.get<Array<ChangeBucket>>(url, requestConfig);

    return response.data;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Get the change buckets from the backend.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @param name - The name of the bucket retrieved.
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @returns An array with the containers from the blob storage that can be put directly into the context.
 */
export const getBucket = async (storageEnvironment: StorageEnvironment, name: string): Promise<ChangeBucket> => {
  try {
    const url: string = `${webApiConfig.endpointUrl}/ChangeTracking/${name}`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the GET request.
    const response = await axios.get<ChangeBucket>(url, requestConfig);

    return response.data;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Update a change bucket in the backend.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @param change - The change that will be reflected into it's parent bucket
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @returns An array with the containers from the blob storage that can be put directly into the context.
 */
export const CreateChangeInBucket = async (
  storageEnvironment: StorageEnvironment,
  change: TrackedChange,
): Promise<void> => {
  try {
    const url: string = `${webApiConfig.endpointUrl}/ChangeTracking`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the POST request.
    await axios.put(url, change, requestConfig);
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Update a change bucket in the backend.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @param change - The change that will be reflected into it's parent bucket
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @returns An array with the containers from the blob storage that can be put directly into the context.
 */
export const UpdateBucketWithChange = async (
  storageEnvironment: StorageEnvironment,
  change: TrackedChange,
): Promise<void> => {
  try {
    const url: string = `${webApiConfig.endpointUrl}/ChangeTracking/Update/One`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the POST request.
    await axios.post(url, change, requestConfig);
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Update a change bucket in the backend.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @param change - The change that will be reflected into it's parent bucket
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @returns An array with the containers from the blob storage that can be put directly into the context.
 */
export const UpdateBucketWithChanges = async (
  storageEnvironment: StorageEnvironment,
  changes: Array<TrackedChange>,
): Promise<void> => {
  try {
    const url: string = `${webApiConfig.endpointUrl}/ChangeTracking/Update/List`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the POST request.
    await axios.post(url, changes, requestConfig);
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Get the change buckets from the backend.
 * @param storageEnvironment - The storage environment we are interacting with.
 * @param change - The change that will be reflected into it's parent bucket
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @returns An array with the containers from the blob storage that can be put directly into the context.
 */
export const DeleteChangeFromBucket = async (
  storageEnvironment: StorageEnvironment,
  change: TrackedChange,
): Promise<void> => {
  try {
    const url: string = `${webApiConfig.endpointUrl}/ChangeTracking`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      params: {
        environment: storageEnvironment,
      },
      data: {
        change,
      },
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the POST request.
    await axios.delete(url, requestConfig);
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Trigger a request to start saving changes to staging or production.
 * @param storageEnvironment - The storage environment we are saving changes to.
 * @param buckets - Optionally send buckets if they are changes made locally in the frontend
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @returns An array with the containers from the blob storage that can be put directly into the context.
 */
export const StageChanges = async (buckets: Array<ChangeBucket>): Promise<Array<ChangeBucket>> => {
  try {
    const url: string = `${webApiConfig.endpointUrl}/ChangeManager/Stage`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the POST request.
    const response = await axios.post<Array<ChangeBucket>>(url, buckets, requestConfig);

    return response.data;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

/**
 * Trigger a request to start saving changes to staging or production.
 * @param storageEnvironment - The storage environment we are saving changes to.
 * @param buckets - Optionally send buckets if they are changes made locally in the frontend
 * @example
 * Staging
 * StorageEnvironment.Staging
 * @example
 * Live
 * StorageEnvironment.Live
 * @returns An array with the containers from the blob storage that can be put directly into the context.
 */
export const LiveChanges = async (): Promise<Array<ChangeBucket>> => {
  try {
    const url: string = `${webApiConfig.endpointUrl}/ChangeManager/Live`;
    const token = await getToken();
    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": webApiConfig.subscriptionKey,
      },
      signal: new AbortController().signal,
    };

    // Perform the POST request.
    const response = await axios.post<Array<ChangeBucket>>(url, {}, requestConfig);

    return response.data;
  } catch (error) {
    // check whether the error is an axios error or a stock error.
    if (axios.isAxiosError(error)) {
      throw new Error(`API call axios malfunction code: ${error.code} ${JSON.stringify(error.response)}`);
    } else {
      throw new Error(`API call stock malfunction, reason: ${error}`);
    }
  }
};

import React, { FC, useReducer, ReactNode, useContext, createContext, Dispatch, useEffect } from "react";
import moment from "moment";

// Data Imports
import { ApiCompatibleContainerNames } from "../models/models";
import { GroupIncident } from "../models/GroupIncidents";
import { getTopdeskGroupIncidents } from "../API/TopDeskInteraction";
import { StorageEnvironment, getContainers, ApiBlobResponse, postContainer } from "../API/StorageInteraction";

// Helpers
import { deepCopy } from "../helpers/deepCopy";
import delay from "../helpers/delay";

// #region staging context

// Builds the context that provides the list of containers
const StagingContext = createContext<stagingContextAttributes | null>(null);

// Builds the context that provides the function that lets components dispatch actions
const StagingDispatchContext = createContext<Dispatch<any> | null>(null);

// Initial staging state provided to the reducer in the provider
const initialStagingContext: stagingContextAttributes = {
  containers: [],
};

/**
 * Enum that contains all possible actions
 */
export enum ActionType {
  "set",
  "updateSingle",
  "updateAll",
}

/**
 * Interfaces the children property to the context provider
 */
interface stagingContextProviderProps {
  children: ReactNode | Array<ReactNode> | null;
}

/**
 * Interfaces the context values and functions with the provider component
 */
export type stagingContextAttributes = {
  containers: Array<ApiBlobResponse>;
  setContainers?: () => Promise<void>;
  updateContextWithContainer?: (update: ApiBlobResponse) => void;
};

/**
 * Provides the containers context to the application
 * @param children - The children of the provider
 * @returns A provider element
 */
export const StagingContextProvider: FC<stagingContextProviderProps> = ({ children }) => {
  const [context, dispatch] = useReducer(stagingReducer, initialStagingContext);

  const GroupIncidentsFeatureFlag: boolean = process.env.REACT_APP_ENABLE_GROUP_INCIDENTS === "true";

  useEffect(() => {
    setContainers().catch((error) => {
      throw new Error(`Fetching staging data failed: ${error}`);
    });

    updateRepeatableHighlights().catch((error) => {
      throw new Error(`Failed to validate repeatable highlights: ${error}`);
    });

    if (GroupIncidentsFeatureFlag) {
      updateGroupIncidents().catch((error) => {
        throw new Error(`Updating group incidents failed: ${error}`);
      });
    }
  }, []);

  // TODO:  Find a way to persist the data when the page closes, so that unsaved changes aren't immediately lost.
  //        Apply the same to locally tracked changes in localStorageContext that aren't saved to the blob yet.
  // useEffect(() => {
  //   // Convert container data to json string and create cookie
  //   const localData: string = JSON.stringify(context.containers);
  //   sessionStorage.setItem('local-data', localData)
  // }, [context.containers])

  /**
   * Set the staging containers
   * @param containers - The containers to be set for the staging context
   */
  const setContainers = async (): Promise<void> => {
    try {
      const containers = await getContainers(StorageEnvironment.Staging, ApiCompatibleContainerNames);

      const action: stagingReducerAction = {
        type: ActionType.set,
        payload: containers,
      };

      dispatch(action);
    } catch (error) {
      throw new Error(`Error fetching data: ${error}`);
    }
  };

  /**
   * update the group incidents recursively.
   */
  const updateGroupIncidents = async (): Promise<void> => {
    try {
      // Get a deep copy of the container
      const container = deepCopy(await getContainers(StorageEnvironment.Staging, ["GroupIncidents"])).pop();

      if (container !== undefined) {
        const backgroundValidatingActive = true;

        // Fetch topdesk information
        const topdeskMajorIncidentJson = await getTopdeskGroupIncidents();
        if (topdeskMajorIncidentJson !== undefined) {
          const majorIncidentIDs = topdeskMajorIncidentJson.map((item: any) => item.number);

          // Filter the staging blob to only keep information about open major incidents
          const incidentsAsArray: Array<GroupIncident> = container.blob.value as Array<GroupIncident>;
          const existingIncidents = incidentsAsArray.map((blobItem: GroupIncident) => blobItem);

          const storedIds: Array<string> = existingIncidents.map((incident: GroupIncident) => incident.key);
          const missingIds = majorIncidentIDs.filter((incident: string) => !storedIds.includes(incident));

          // Add the new major incidents to the json array
          const consolidation = [...existingIncidents];

          for (const newId of missingIds) {
            consolidation.push({
              key: newId,
              languages: [],
              enabled: false,
            });
          }

          // Find the difference between staging blob and current major incidents to remove deprecated incidents
          const deprecated: Array<string> = storedIds.filter((incident) => !majorIncidentIDs.includes(incident));

          // Remove the deprecated incidents (they are resolved or no longer active in topdesk)
          const result = consolidation.filter((incident) => !deprecated.includes(incident.key));

          const updatedContainer: ApiBlobResponse = {
            key: container.key,
            blob: { value: result },
          };

          if (context !== null) {
            // Update the blob container value
            const action: stagingReducerAction = {
              type: ActionType.updateSingle,
              payload: updatedContainer,
            };

            dispatch(action);
          }

          if (backgroundValidatingActive) {
            await delay(1000 * 60 * 15); // This is equal to 15 minutes
            updateGroupIncidents().catch((error) => {
              throw new Error(`failed to validate repeatables ${error}`);
            });
          }
        }
      }
    } catch (error) {
      throw new Error(`Error updating group incidents ${error}`);
    }
  };

  /**
   * Here we validate the repeatable highlights based on the timestamps and repeat interval against the current date
   * @returns void
   */
  const updateRepeatableHighlights = async (): Promise<void> => {
    const backgroundValidatingActive = true;

    try {
      // Fetch the highlight data and trigger recursion with boolean
      const container: ApiBlobResponse | undefined = (
        await getContainers(StorageEnvironment.Staging, ["Highlights"])
      ).pop();

      if (container !== undefined && Array.isArray(container.blob.value)) {
        const updatedBlob: Array<any> = [];

        // Iterate the highlight data
        for (const highlight of container.blob.value) {
          if (highlight.repeat === true) {
            const windowValid = moment(moment()).isAfter(highlight.timeStampTo);

            if (windowValid) {
              const before = moment(highlight.timeStampFrom).add(highlight.timeSpan, "days").format("YYYY-MM-DDTHH:mm");
              const after = moment(highlight.timeStampTo).add(highlight.timeSpan, "days").format("YYYY-MM-DDTHH:mm");

              highlight.timeStampFrom = before;
              highlight.timeStampTo = after;
            }
          }

          updatedBlob.push(highlight);
        }

        if (updatedBlob.length > 0) {
          updateContextWithContainer({
            key: container.key,
            blob: { value: updatedBlob },
          });

          await postContainer(
            StorageEnvironment.Staging,
            updatedBlob,
            ApiCompatibleContainerNames.find((name) => name === "Highlights")!,
          );
        }
      }

      if (backgroundValidatingActive) {
        await delay(1000 * 60 * 15); // This is equal to 15 minutes
        updateRepeatableHighlights().catch((error) => {
          throw new Error(`failed to validate repeatables ${error}`);
        });
      }
    } catch (error) {
      throw new Error(`Failed to validate the repeatable highlights because of: ${error}`);
    }
  };

  /**
   * Context update function that creates a dispatch action with the correct information
   * @param update - Generated by a component to be put into context
   * @returns void
   */
  const updateContextWithContainer = (update: ApiBlobResponse): void => {
    // Build a dispatch action
    const action: stagingReducerAction = {
      type: ActionType.updateSingle,
      payload: update,
    };

    dispatch(action);
  };

  const _context: stagingContextAttributes = {
    containers: context.containers,
    setContainers,
    updateContextWithContainer,
  };

  return (
    <StagingContext.Provider value={_context}>
      <StagingDispatchContext.Provider value={dispatch}>{children}</StagingDispatchContext.Provider>
    </StagingContext.Provider>
  );
};

/**
 * Provides the container context
 * @returns A context
 */
export const useStagingContext = (): stagingContextAttributes | null => useContext(StagingContext);

/**
 * Provides the function that lets components dispatch actions
 * @returns  A dispatch context
 */
export const useStagingDispatch = (): Dispatch<any> | null => useContext(StagingDispatchContext);

// #endregion

// #region staging reducer

/**
 * Custom type for the reducer action that works with staging context
 */
export type stagingReducerAction = {
  type: ActionType;
  payload?: Array<ApiBlobResponse> | ApiBlobResponse;
};

/**
 * Set is used to set the containers, updateSingle is used to update a single container, updateAll
 * is used to update all the containers.
 * @param context - The state to be updated
 * @param action - The action to be executed by the reducer
 * @returns Updated state
 */
export const stagingReducer = (
  context: stagingContextAttributes,
  action: stagingReducerAction,
): stagingContextAttributes => {
  switch (action.type) {
    case ActionType.set: {
      const newContainers = action.payload as Array<ApiBlobResponse>;

      return { ...context, containers: newContainers } satisfies stagingContextAttributes;
    }
    case ActionType.updateSingle: {
      const newContainer = action.payload as ApiBlobResponse;
      const updatedContainers: Array<ApiBlobResponse> = context.containers.map((container: ApiBlobResponse) => {
        if (container.key === newContainer.key) {
          return newContainer;
        } else {
          return container;
        }
      });

      return { ...context, containers: updatedContainers } satisfies stagingContextAttributes;
    }
    case ActionType.updateAll: {
      const updateContainers = action.payload as Array<ApiBlobResponse>;

      return { ...context, containers: updateContainers } satisfies stagingContextAttributes;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

// #endregion

import React, { FC } from "react";

import { Box, Grid, Link } from "@mui/material";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";

/**
 * Branding Component
 * @returns A React component representing the Branding to display and edit the dialogs.
 */
const Branding: FC = () => (
  <Grid container gap={1} justifyContent="flex-start" alignItems="center">
    <Grid item>
      <Box component="span">Admin Panel</Box>
    </Grid>
    <Grid item>
      <Box component="span"> | </Box>
    </Grid>
    <Grid item>
      <Link href="https://gwenda-acpt.cimsolutions-demo.nl/" target="_blank" rel="noreferrer">
        <ScreenShareIcon />
      </Link>
    </Grid>
  </Grid>
);

export default Branding;

import { LanguageText } from "./Language";

export const highlight: any = {
  key: "",
  value: "",
  language: "",
  timeStampFrom: "", // Yyyy-MM-DDTHH:mm
  timeStampTo: "",
  repeat: false,
  timeSpan: 0,
  enabled: false,
};

export type Highlight = {
  key: string;
  languages: Array<LanguageText>;
  timeStampFrom: string;
  timeStampTo: string;
  repeat: boolean;
  timeSpan: number;
  enabled: boolean;
};
